// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

.#{$ns}-context-menu .#{$ns}-popover-target {
  display: block;
}

.#{$ns}-context-menu-popover-target {
  position: fixed;
}
