// Copyright 2021 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";

.#{$ns}-control-group {
  // Add border radius inheritance to support components wrapped in a popover
  .#{$ns}-popover2-target {
    border-radius: inherit;
  }
}
