// Copyright 2021 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";
@import "~@blueprintjs/core/src/components/menu/common";

.#{$ns}-submenu {
  .#{$ns}-popover2-target {
    display: block;

    // MenuItem doesn't render Popover2, only Popover, so we don't need the styles
    // which render open submenu items as hovered/selected as we do in _submenu.scss
  }

  &.#{$ns}-popover2 {
    box-shadow: none;
    // horizontal padding leaves some space from parent menu item, and extends mouse zone
    padding: 0 $half-grid-size;

    > .#{$ns}-popover2-content {
      box-shadow: $pt-popover-box-shadow;
    }

    .#{$ns}-dark &,
    &.#{$ns}-dark {
      box-shadow: none;

      > .#{$ns}-popover2-content {
        box-shadow: $pt-dark-popover-box-shadow;
      }
    }
  }
}

