// Copyright 2021 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";

.#{$ns}-tree-node-secondary-label {
  .#{$ns}-popover2-target {
    align-items: center;
    display: flex;
  }
}
