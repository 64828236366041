// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "../../common/variables";

/*
Navbars

Markup:
<nav class="#{$ns}-navbar {{.modifier}}">
  <div class="#{$ns}-navbar-group #{$ns}-align-left">
    <div class="#{$ns}-navbar-heading">Blueprint</div>
    <input class="#{$ns}-input" placeholder="Search files..." type="text" />
  </div>
  <div class="#{$ns}-navbar-group #{$ns}-align-right">
    <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-home">Home</button>
    <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-document">Files</button>
    <span class="#{$ns}-navbar-divider"></span>
    <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-user"></button>
    <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-notifications"></button>
    <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-cog"></button>
  </div>
</nav>

.#{$ns}-dark - Dark theme

Styleguide navbar
*/

$navbar-padding: $pt-grid-size * 1.5 !default;

$navbar-background-color: $white !default;
$dark-navbar-background-color: $dark-gray4 !default;

.#{$ns}-navbar {
  background-color: $navbar-background-color;
  box-shadow: $pt-elevation-shadow-1;
  height: $pt-navbar-height;
  padding: 0 $navbar-padding;
  position: relative;
  width: 100%;
  z-index: $pt-z-index-content;

  &.#{$ns}-dark,
  .#{$ns}-dark & {
    background-color: $dark-navbar-background-color;
  }

  // shadow for dark navbar in light theme apps
  &.#{$ns}-dark {
    box-shadow: inset $pt-dark-elevation-shadow-1;
  }

  // shadow for dark navbar in dark theme apps
  .#{$ns}-dark & {
    box-shadow: $pt-dark-elevation-shadow-1;
  }

  &.#{$ns}-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
}

.#{$ns}-navbar-heading {
  font-size: $pt-font-size-large;
  margin-right: $navbar-padding;
}

.#{$ns}-navbar-group {
  align-items: center;
  display: flex;
  height: $pt-navbar-height;

  &.#{$ns}-align-left {
    float: left;
  }

  &.#{$ns}-align-right {
    float: right;
  }
}

.#{$ns}-navbar-divider {
  border-left: 1px solid $pt-divider-black;
  height: $pt-navbar-height - $pt-grid-size * 3;
  margin: 0 $pt-grid-size;

  .#{$ns}-dark & {
    border-left-color: $pt-dark-divider-white;
  }
}

/*
Fixed width

Markup:
<nav class="#{$ns}-navbar #{$ns}-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="#{$ns}-navbar-group #{$ns}-align-left">
      <div class="#{$ns}-navbar-heading">Blueprint</div>
    </div>
    <div class="#{$ns}-navbar-group #{$ns}-align-right">
      <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-home">Home</button>
      <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-document">Files</button>
      <span class="#{$ns}-navbar-divider"></span>
      <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-user"></button>
      <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-notifications"></button>
      <button class="#{$ns}-button #{$ns}-minimal #{$ns}-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide navbar-container
*/
