// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "~@blueprintjs/icons/lib/scss/variables";
@import "../../common/variables";
@import "../../common/variables-extended";
@import "icon-mixins";

// the icon class which will contain an SVG icon
.#{$ns}-icon {
  // ensure icons sit inline with text & isolate svg from surrounding elements
  // (vertical alignment in flow is usually off due to svg - not an issue with flex.)
  display: inline-block;
  // respect dimensions exactly
  flex: 0 0 auto;
  // sit nicely with inline text
  vertical-align: text-bottom;

  &:not(:empty)::before {
    // clear font icon when there's an <svg> image
    /* stylelint-disable */
    content: "" !important; // fallback for IE11
    content: unset !important;
    /* stylelint-enable */
  }

  > svg {
    // prevent extra vertical whitespace
    display: block;

    // inherit text color unless explicit fill is set
    &:not([fill]) {
      fill: currentcolor;
    }
  }
}

// intent colors for both SVG and font icons are set in _typography-colors.scss

//
// Icon font styles
//

span.#{$ns}-icon-standard {
  @include pt-icon($pt-icon-size-standard);
  display: inline-block;
}

span.#{$ns}-icon-large {
  @include pt-icon($pt-icon-size-large);
  display: inline-block;
}

// only apply icon font styles when <svg> image is not present
span.#{$ns}-icon:empty {
  font-family: $blueprint-icons-20-font;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;

  &::before {
    @include pt-icon-font-smoothing();
  }
}

@each $name, $codepoint in $blueprint-icon-codepoints {
  .#{$ns}-icon-#{$name}::before {
    content: $codepoint;
  }
}
