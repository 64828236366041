@import "../../common/variables";
@import "./common";

.#{$ns}-numeric-input {
  // we need a very-specific selector here to override specificicty of selectors defined elsewhere.
  .#{$ns}-button-group.#{$ns}-vertical > .#{$ns}-button {
    // let the buttons shrink to equal heights
    flex: 1 1 ($pt-button-height * 0.5 - 1);
    min-height: 0;
    padding: 0;
    width: $pt-button-height;
  }

  &.#{$ns}-large .#{$ns}-button-group.#{$ns}-vertical > .#{$ns}-button {
    width: $pt-button-height-large;
  }
}
