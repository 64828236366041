// Copyright 2018 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

.#{$ns}-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
}

.#{$ns}-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px;
}
