// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "common/variables";
@import "common/mixins";

// Apply a natural box layout model to all elements, but allow components to change as necessary
html {
  box-sizing: border-box;
}

// adjust box-sizing for every element ever
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Style resets on top of Normalize.css

body {
  @include base-typography();
  color: $pt-text-color;
  font-family: $pt-font-family;
}

p {
  margin-bottom: $pt-grid-size;
  margin-top: 0;
}

small {
  font-size: $pt-font-size-small;
}

strong {
  font-weight: 600;
}

// consistent cross-browser text selection
::selection {
  background: $pt-text-selection-color;
}
