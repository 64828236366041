// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

// NOTE: this partial file is imported once here so form components can just use it.
// multiple @imports of shared/forms from different files causes placeholder @extends to
// output CSS multiple times which breaks textbox padding.
@import "./common";

@import "./control-group";
@import "./controls";
@import "./file-input";
@import "./form-group";
@import "./input-group";
@import "./input";
@import "./label";
@import "./numeric-input";

form {
  display: block;
}
