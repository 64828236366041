@charset "UTF-8";
/*

Copyright 2021-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2858142857;
  text-transform: none;
  color: #1c2127;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "blueprint-icons-16", sans-serif;
}

p {
  margin-bottom: 10px;
  margin-top: 0;
}

small {
  font-size: 12px;
}

strong {
  font-weight: 600;
}

::selection {
  background: rgba(125, 188, 255, 0.6);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-icon {
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom;
}
.bp4-icon:not(:empty)::before {
  /* stylelint-disable */
  content: "" !important;
  content: unset !important;
  /* stylelint-enable */
}
.bp4-icon > svg {
  display: block;
}
.bp4-icon > svg:not([fill]) {
  fill: currentcolor;
}

span.bp4-icon-standard {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

span.bp4-icon-large {
  font-family: "blueprint-icons-20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

span.bp4-icon:empty {
  font-family: "blueprint-icons-20";
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
span.bp4-icon:empty::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bp4-icon-blank::before {
  content: "\f133";
}

.bp4-icon-style::before {
  content: "\f2d4";
}

.bp4-icon-align-left::before {
  content: "\f10d";
}

.bp4-icon-align-center::before {
  content: "\f10b";
}

.bp4-icon-align-right::before {
  content: "\f10e";
}

.bp4-icon-align-justify::before {
  content: "\f10c";
}

.bp4-icon-bold::before {
  content: "\f135";
}

.bp4-icon-italic::before {
  content: "\f20a";
}

.bp4-icon-underline::before {
  content: "\f301";
}

.bp4-icon-search-around::before {
  content: "\f29f";
}

.bp4-icon-graph-remove::before {
  content: "\f1d4";
}

.bp4-icon-group-objects::before {
  content: "\f1da";
}

.bp4-icon-merge-links::before {
  content: "\f242";
}

.bp4-icon-layout::before {
  content: "\f225";
}

.bp4-icon-layout-auto::before {
  content: "\f21c";
}

.bp4-icon-layout-circle::before {
  content: "\f21e";
}

.bp4-icon-layout-hierarchy::before {
  content: "\f221";
}

.bp4-icon-layout-grid::before {
  content: "\f21f";
}

.bp4-icon-layout-group-by::before {
  content: "\f220";
}

.bp4-icon-layout-skew-grid::before {
  content: "\f223";
}

.bp4-icon-geosearch::before {
  content: "\f1c9";
}

.bp4-icon-heatmap::before {
  content: "\f1ea";
}

.bp4-icon-drive-time::before {
  content: "\f192";
}

.bp4-icon-select::before {
  content: "\f2a4";
}

.bp4-icon-predictive-analysis::before {
  content: "\f27c";
}

.bp4-icon-layers::before {
  content: "\f21b";
}

.bp4-icon-locate::before {
  content: "\f231";
}

.bp4-icon-bookmark::before {
  content: "\f137";
}

.bp4-icon-citation::before {
  content: "\f154";
}

.bp4-icon-tag::before {
  content: "\f2df";
}

.bp4-icon-clipboard::before {
  content: "\f157";
}

.bp4-icon-selection::before {
  content: "\f2a5";
}

.bp4-icon-timeline-events::before {
  content: "\f2f3";
}

.bp4-icon-timeline-line-chart::before {
  content: "\f2f4";
}

.bp4-icon-timeline-bar-chart::before {
  content: "\f2f2";
}

.bp4-icon-applications::before {
  content: "\f119";
}

.bp4-icon-projects::before {
  content: "\f280";
}

.bp4-icon-changes::before {
  content: "\f146";
}

.bp4-icon-notifications::before {
  content: "\f25e";
}

.bp4-icon-lock::before {
  content: "\f232";
}

.bp4-icon-unlock::before {
  content: "\f305";
}

.bp4-icon-user::before {
  content: "\f30a";
}

.bp4-icon-search-template::before {
  content: "\f2a0";
}

.bp4-icon-inbox::before {
  content: "\f1ff";
}

.bp4-icon-more::before {
  content: "\f24a";
}

.bp4-icon-help::before {
  content: "\f1ec";
}

.bp4-icon-calendar::before {
  content: "\f13e";
}

.bp4-icon-media::before {
  content: "\f23d";
}

.bp4-icon-link::before {
  content: "\f22d";
}

.bp4-icon-share::before {
  content: "\f2b1";
}

.bp4-icon-download::before {
  content: "\f18a";
}

.bp4-icon-document::before {
  content: "\f180";
}

.bp4-icon-properties::before {
  content: "\f281";
}

.bp4-icon-import::before {
  content: "\f1fa";
}

.bp4-icon-export::before {
  content: "\f1a0";
}

.bp4-icon-minimize::before {
  content: "\f243";
}

.bp4-icon-maximize::before {
  content: "\f23c";
}

.bp4-icon-tick::before {
  content: "\f2ef";
}

.bp4-icon-cross::before {
  content: "\f169";
}

.bp4-icon-plus::before {
  content: "\f279";
}

.bp4-icon-minus::before {
  content: "\f244";
}

.bp4-icon-arrow-left::before {
  content: "\f125";
}

.bp4-icon-arrow-right::before {
  content: "\f126";
}

.bp4-icon-exchange::before {
  content: "\f19d";
}

.bp4-icon-comparison::before {
  content: "\f161";
}

.bp4-icon-list::before {
  content: "\f230";
}

.bp4-icon-filter::before {
  content: "\f1ad";
}

.bp4-icon-confirm::before {
  content: "\f164";
}

.bp4-icon-fork::before {
  content: "\f1c0";
}

.bp4-icon-trash::before {
  content: "\f2fa";
}

.bp4-icon-person::before {
  content: "\f272";
}

.bp4-icon-people::before {
  content: "\f270";
}

.bp4-icon-add::before {
  content: "\f109";
}

.bp4-icon-remove::before {
  content: "\f290";
}

.bp4-icon-geolocation::before {
  content: "\f1c8";
}

.bp4-icon-zoom-in::before {
  content: "\f31e";
}

.bp4-icon-zoom-out::before {
  content: "\f31f";
}

.bp4-icon-refresh::before {
  content: "\f289";
}

.bp4-icon-delete::before {
  content: "\f175";
}

.bp4-icon-cog::before {
  content: "\f15d";
}

.bp4-icon-flag::before {
  content: "\f1ae";
}

.bp4-icon-pin::before {
  content: "\f275";
}

.bp4-icon-warning-sign::before {
  content: "\f315";
}

.bp4-icon-error::before {
  content: "\f19b";
}

.bp4-icon-info-sign::before {
  content: "\f200";
}

.bp4-icon-credit-card::before {
  content: "\f168";
}

.bp4-icon-edit::before {
  content: "\f194";
}

.bp4-icon-history::before {
  content: "\f1f0";
}

.bp4-icon-search::before {
  content: "\f2a2";
}

.bp4-icon-log-out::before {
  content: "\f234";
}

.bp4-icon-star::before {
  content: "\f2cd";
}

.bp4-icon-star-empty::before {
  content: "\f2cc";
}

.bp4-icon-sort-alphabetical::before {
  content: "\f2c2";
}

.bp4-icon-sort-numerical::before {
  content: "\f2c6";
}

.bp4-icon-sort::before {
  content: "\f2c7";
}

.bp4-icon-folder-open::before {
  content: "\f1ba";
}

.bp4-icon-folder-close::before {
  content: "\f1b8";
}

.bp4-icon-folder-shared::before {
  content: "\f1bc";
}

.bp4-icon-caret-up::before {
  content: "\f143";
}

.bp4-icon-caret-right::before {
  content: "\f142";
}

.bp4-icon-caret-down::before {
  content: "\f140";
}

.bp4-icon-caret-left::before {
  content: "\f141";
}

.bp4-icon-menu-open::before {
  content: "\f23f";
}

.bp4-icon-menu-closed::before {
  content: "\f23e";
}

.bp4-icon-feed::before {
  content: "\f1a7";
}

.bp4-icon-two-columns::before {
  content: "\f2ff";
}

.bp4-icon-one-column::before {
  content: "\f264";
}

.bp4-icon-dot::before {
  content: "\f182";
}

.bp4-icon-property::before {
  content: "\f282";
}

.bp4-icon-time::before {
  content: "\f2f0";
}

.bp4-icon-disable::before {
  content: "\f17d";
}

.bp4-icon-unpin::before {
  content: "\f306";
}

.bp4-icon-flows::before {
  content: "\f1b7";
}

.bp4-icon-new-text-box::before {
  content: "\f259";
}

.bp4-icon-new-link::before {
  content: "\f255";
}

.bp4-icon-new-object::before {
  content: "\f256";
}

.bp4-icon-path-search::before {
  content: "\f26d";
}

.bp4-icon-automatic-updates::before {
  content: "\f12d";
}

.bp4-icon-page-layout::before {
  content: "\f268";
}

.bp4-icon-code::before {
  content: "\f15c";
}

.bp4-icon-map::before {
  content: "\f23b";
}

.bp4-icon-search-text::before {
  content: "\f2a1";
}

.bp4-icon-envelope::before {
  content: "\f198";
}

.bp4-icon-paperclip::before {
  content: "\f26b";
}

.bp4-icon-label::before {
  content: "\f218";
}

.bp4-icon-globe::before {
  content: "\f1d3";
}

.bp4-icon-home::before {
  content: "\f1f1";
}

.bp4-icon-th::before {
  content: "\f2ea";
}

.bp4-icon-th-list::before {
  content: "\f2e9";
}

.bp4-icon-th-derived::before {
  content: "\f2e6";
}

.bp4-icon-circle::before {
  content: "\f153";
}

.bp4-icon-draw::before {
  content: "\f18d";
}

.bp4-icon-insert::before {
  content: "\f204";
}

.bp4-icon-helper-management::before {
  content: "\f1ed";
}

.bp4-icon-send-to::before {
  content: "\f2a9";
}

.bp4-icon-eye-open::before {
  content: "\f1a3";
}

.bp4-icon-folder-shared-open::before {
  content: "\f1bb";
}

.bp4-icon-social-media::before {
  content: "\f2c0";
}

.bp4-icon-arrow-up::before {
  content: "\f129";
}

.bp4-icon-arrow-down::before {
  content: "\f124";
}

.bp4-icon-arrows-horizontal::before {
  content: "\f12a";
}

.bp4-icon-arrows-vertical::before {
  content: "\f12b";
}

.bp4-icon-resolve::before {
  content: "\f293";
}

.bp4-icon-graph::before {
  content: "\f1d5";
}

.bp4-icon-briefcase::before {
  content: "\f139";
}

.bp4-icon-dollar::before {
  content: "\f181";
}

.bp4-icon-ninja::before {
  content: "\f25a";
}

.bp4-icon-delta::before {
  content: "\f176";
}

.bp4-icon-barcode::before {
  content: "\f132";
}

.bp4-icon-torch::before {
  content: "\f2f6";
}

.bp4-icon-widget::before {
  content: "\f31b";
}

.bp4-icon-unresolve::before {
  content: "\f307";
}

.bp4-icon-offline::before {
  content: "\f262";
}

.bp4-icon-zoom-to-fit::before {
  content: "\f320";
}

.bp4-icon-add-to-artifact::before {
  content: "\f107";
}

.bp4-icon-map-marker::before {
  content: "\f23a";
}

.bp4-icon-chart::before {
  content: "\f147";
}

.bp4-icon-control::before {
  content: "\f167";
}

.bp4-icon-multi-select::before {
  content: "\f24e";
}

.bp4-icon-direction-left::before {
  content: "\f17b";
}

.bp4-icon-direction-right::before {
  content: "\f17c";
}

.bp4-icon-database::before {
  content: "\f174";
}

.bp4-icon-pie-chart::before {
  content: "\f274";
}

.bp4-icon-full-circle::before {
  content: "\f1c2";
}

.bp4-icon-square::before {
  content: "\f2c9";
}

.bp4-icon-print::before {
  content: "\f27f";
}

.bp4-icon-presentation::before {
  content: "\f27e";
}

.bp4-icon-ungroup-objects::before {
  content: "\f303";
}

.bp4-icon-chat::before {
  content: "\f148";
}

.bp4-icon-comment::before {
  content: "\f160";
}

.bp4-icon-circle-arrow-right::before {
  content: "\f151";
}

.bp4-icon-circle-arrow-left::before {
  content: "\f150";
}

.bp4-icon-circle-arrow-up::before {
  content: "\f152";
}

.bp4-icon-circle-arrow-down::before {
  content: "\f14f";
}

.bp4-icon-upload::before {
  content: "\f309";
}

.bp4-icon-asterisk::before {
  content: "\f12c";
}

.bp4-icon-cloud::before {
  content: "\f15a";
}

.bp4-icon-cloud-download::before {
  content: "\f158";
}

.bp4-icon-cloud-upload::before {
  content: "\f159";
}

.bp4-icon-repeat::before {
  content: "\f291";
}

.bp4-icon-move::before {
  content: "\f24c";
}

.bp4-icon-chevron-left::before {
  content: "\f14c";
}

.bp4-icon-chevron-right::before {
  content: "\f14d";
}

.bp4-icon-chevron-up::before {
  content: "\f14e";
}

.bp4-icon-chevron-down::before {
  content: "\f14a";
}

.bp4-icon-random::before {
  content: "\f286";
}

.bp4-icon-fullscreen::before {
  content: "\f1c4";
}

.bp4-icon-log-in::before {
  content: "\f233";
}

.bp4-icon-heart::before {
  content: "\f1e8";
}

.bp4-icon-office::before {
  content: "\f261";
}

.bp4-icon-duplicate::before {
  content: "\f193";
}

.bp4-icon-ban-circle::before {
  content: "\f130";
}

.bp4-icon-camera::before {
  content: "\f13f";
}

.bp4-icon-mobile-video::before {
  content: "\f246";
}

.bp4-icon-video::before {
  content: "\f30f";
}

.bp4-icon-film::before {
  content: "\f1a8";
}

.bp4-icon-settings::before {
  content: "\f2af";
}

.bp4-icon-volume-off::before {
  content: "\f312";
}

.bp4-icon-volume-down::before {
  content: "\f311";
}

.bp4-icon-volume-up::before {
  content: "\f313";
}

.bp4-icon-music::before {
  content: "\f24f";
}

.bp4-icon-step-backward::before {
  content: "\f2ce";
}

.bp4-icon-fast-backward::before {
  content: "\f1a4";
}

.bp4-icon-pause::before {
  content: "\f26f";
}

.bp4-icon-stop::before {
  content: "\f2d1";
}

.bp4-icon-play::before {
  content: "\f278";
}

.bp4-icon-fast-forward::before {
  content: "\f1a5";
}

.bp4-icon-step-forward::before {
  content: "\f2d0";
}

.bp4-icon-eject::before {
  content: "\f195";
}

.bp4-icon-record::before {
  content: "\f287";
}

.bp4-icon-desktop::before {
  content: "\f178";
}

.bp4-icon-phone::before {
  content: "\f273";
}

.bp4-icon-lightbulb::before {
  content: "\f22b";
}

.bp4-icon-glass::before {
  content: "\f1d1";
}

.bp4-icon-tint::before {
  content: "\f2f5";
}

.bp4-icon-flash::before {
  content: "\f1b0";
}

.bp4-icon-font::before {
  content: "\f1bf";
}

.bp4-icon-header::before {
  content: "\f1e5";
}

.bp4-icon-saved::before {
  content: "\f29d";
}

.bp4-icon-floppy-disk::before {
  content: "\f1b1";
}

.bp4-icon-book::before {
  content: "\f136";
}

.bp4-icon-hand-right::before {
  content: "\f1de";
}

.bp4-icon-hand-up::before {
  content: "\f1df";
}

.bp4-icon-hand-down::before {
  content: "\f1dc";
}

.bp4-icon-hand-left::before {
  content: "\f1dd";
}

.bp4-icon-thumbs-up::before {
  content: "\f2ed";
}

.bp4-icon-thumbs-down::before {
  content: "\f2ec";
}

.bp4-icon-box::before {
  content: "\f138";
}

.bp4-icon-compressed::before {
  content: "\f163";
}

.bp4-icon-shopping-cart::before {
  content: "\f2b6";
}

.bp4-icon-shop::before {
  content: "\f2b5";
}

.bp4-icon-layout-linear::before {
  content: "\f222";
}

.bp4-icon-undo::before {
  content: "\f302";
}

.bp4-icon-redo::before {
  content: "\f288";
}

.bp4-icon-code-block::before {
  content: "\f15b";
}

.bp4-icon-double-caret-vertical::before {
  content: "\f184";
}

.bp4-icon-double-caret-horizontal::before {
  content: "\f183";
}

.bp4-icon-sort-alphabetical-desc::before {
  content: "\f2c1";
}

.bp4-icon-sort-numerical-desc::before {
  content: "\f2c5";
}

.bp4-icon-take-action::before {
  content: "\f2e0";
}

.bp4-icon-contrast::before {
  content: "\f166";
}

.bp4-icon-eye-off::before {
  content: "\f1a1";
}

.bp4-icon-timeline-area-chart::before {
  content: "\f2f1";
}

.bp4-icon-doughnut-chart::before {
  content: "\f189";
}

.bp4-icon-layer::before {
  content: "\f21a";
}

.bp4-icon-grid::before {
  content: "\f1d9";
}

.bp4-icon-polygon-filter::before {
  content: "\f27a";
}

.bp4-icon-add-to-folder::before {
  content: "\f108";
}

.bp4-icon-layout-balloon::before {
  content: "\f21d";
}

.bp4-icon-layout-sorted-clusters::before {
  content: "\f224";
}

.bp4-icon-sort-asc::before {
  content: "\f2c3";
}

.bp4-icon-sort-desc::before {
  content: "\f2c4";
}

.bp4-icon-small-cross::before {
  content: "\f2ba";
}

.bp4-icon-small-tick::before {
  content: "\f2be";
}

.bp4-icon-power::before {
  content: "\f27b";
}

.bp4-icon-column-layout::before {
  content: "\f15f";
}

.bp4-icon-arrow-top-left::before {
  content: "\f127";
}

.bp4-icon-arrow-top-right::before {
  content: "\f128";
}

.bp4-icon-arrow-bottom-right::before {
  content: "\f123";
}

.bp4-icon-arrow-bottom-left::before {
  content: "\f122";
}

.bp4-icon-mugshot::before {
  content: "\f24d";
}

.bp4-icon-headset::before {
  content: "\f1e6";
}

.bp4-icon-text-highlight::before {
  content: "\f2e5";
}

.bp4-icon-hand::before {
  content: "\f1e0";
}

.bp4-icon-chevron-backward::before {
  content: "\f149";
}

.bp4-icon-chevron-forward::before {
  content: "\f14b";
}

.bp4-icon-rotate-document::before {
  content: "\f299";
}

.bp4-icon-rotate-page::before {
  content: "\f29a";
}

.bp4-icon-badge::before {
  content: "\f12f";
}

.bp4-icon-grid-view::before {
  content: "\f1d8";
}

.bp4-icon-function::before {
  content: "\f1c5";
}

.bp4-icon-waterfall-chart::before {
  content: "\f316";
}

.bp4-icon-stacked-chart::before {
  content: "\f2ca";
}

.bp4-icon-pulse::before {
  content: "\f284";
}

.bp4-icon-new-person::before {
  content: "\f257";
}

.bp4-icon-exclude-row::before {
  content: "\f19e";
}

.bp4-icon-pivot-table::before {
  content: "\f276";
}

.bp4-icon-segmented-control::before {
  content: "\f2a3";
}

.bp4-icon-highlight::before {
  content: "\f1ef";
}

.bp4-icon-filter-list::before {
  content: "\f1aa";
}

.bp4-icon-cut::before {
  content: "\f16f";
}

.bp4-icon-annotation::before {
  content: "\f115";
}

.bp4-icon-pivot::before {
  content: "\f277";
}

.bp4-icon-ring::before {
  content: "\f296";
}

.bp4-icon-heat-grid::before {
  content: "\f1e9";
}

.bp4-icon-gantt-chart::before {
  content: "\f1c6";
}

.bp4-icon-variable::before {
  content: "\f30b";
}

.bp4-icon-manual::before {
  content: "\f235";
}

.bp4-icon-add-row-top::before {
  content: "\f106";
}

.bp4-icon-add-row-bottom::before {
  content: "\f105";
}

.bp4-icon-add-column-left::before {
  content: "\f102";
}

.bp4-icon-add-column-right::before {
  content: "\f103";
}

.bp4-icon-remove-row-top::before {
  content: "\f28f";
}

.bp4-icon-remove-row-bottom::before {
  content: "\f28e";
}

.bp4-icon-remove-column-left::before {
  content: "\f28b";
}

.bp4-icon-remove-column-right::before {
  content: "\f28c";
}

.bp4-icon-double-chevron-left::before {
  content: "\f186";
}

.bp4-icon-double-chevron-right::before {
  content: "\f187";
}

.bp4-icon-double-chevron-up::before {
  content: "\f188";
}

.bp4-icon-double-chevron-down::before {
  content: "\f185";
}

.bp4-icon-key-control::before {
  content: "\f20e";
}

.bp4-icon-key-command::before {
  content: "\f20d";
}

.bp4-icon-key-shift::before {
  content: "\f213";
}

.bp4-icon-key-backspace::before {
  content: "\f20c";
}

.bp4-icon-key-delete::before {
  content: "\f20f";
}

.bp4-icon-key-escape::before {
  content: "\f211";
}

.bp4-icon-key-enter::before {
  content: "\f210";
}

.bp4-icon-calculator::before {
  content: "\f13d";
}

.bp4-icon-horizontal-bar-chart::before {
  content: "\f1f4";
}

.bp4-icon-small-plus::before {
  content: "\f2bc";
}

.bp4-icon-small-minus::before {
  content: "\f2bb";
}

.bp4-icon-step-chart::before {
  content: "\f2cf";
}

.bp4-icon-euro::before {
  content: "\f19c";
}

.bp4-icon-drag-handle-vertical::before {
  content: "\f18c";
}

.bp4-icon-drag-handle-horizontal::before {
  content: "\f18b";
}

.bp4-icon-mobile-phone::before {
  content: "\f245";
}

.bp4-icon-sim-card::before {
  content: "\f2b8";
}

.bp4-icon-trending-up::before {
  content: "\f2fd";
}

.bp4-icon-trending-down::before {
  content: "\f2fc";
}

.bp4-icon-curved-range-chart::before {
  content: "\f16e";
}

.bp4-icon-vertical-bar-chart-desc::before {
  content: "\f30d";
}

.bp4-icon-horizontal-bar-chart-desc::before {
  content: "\f1f3";
}

.bp4-icon-document-open::before {
  content: "\f17e";
}

.bp4-icon-document-share::before {
  content: "\f17f";
}

.bp4-icon-horizontal-distribution::before {
  content: "\f1f5";
}

.bp4-icon-vertical-distribution::before {
  content: "\f30e";
}

.bp4-icon-alignment-left::before {
  content: "\f111";
}

.bp4-icon-alignment-vertical-center::before {
  content: "\f114";
}

.bp4-icon-alignment-right::before {
  content: "\f112";
}

.bp4-icon-alignment-top::before {
  content: "\f113";
}

.bp4-icon-alignment-horizontal-center::before {
  content: "\f110";
}

.bp4-icon-alignment-bottom::before {
  content: "\f10f";
}

.bp4-icon-git-pull::before {
  content: "\f1ce";
}

.bp4-icon-git-merge::before {
  content: "\f1cc";
}

.bp4-icon-git-branch::before {
  content: "\f1ca";
}

.bp4-icon-git-commit::before {
  content: "\f1cb";
}

.bp4-icon-git-push::before {
  content: "\f1cf";
}

.bp4-icon-build::before {
  content: "\f13c";
}

.bp4-icon-symbol-circle::before {
  content: "\f2d8";
}

.bp4-icon-symbol-square::before {
  content: "\f2db";
}

.bp4-icon-symbol-diamond::before {
  content: "\f2da";
}

.bp4-icon-symbol-cross::before {
  content: "\f2d9";
}

.bp4-icon-symbol-triangle-up::before {
  content: "\f2dd";
}

.bp4-icon-symbol-triangle-down::before {
  content: "\f2dc";
}

.bp4-icon-wrench::before {
  content: "\f31d";
}

.bp4-icon-application::before {
  content: "\f118";
}

.bp4-icon-send-to-graph::before {
  content: "\f2a7";
}

.bp4-icon-send-to-map::before {
  content: "\f2a8";
}

.bp4-icon-join-table::before {
  content: "\f20b";
}

.bp4-icon-derive-column::before {
  content: "\f177";
}

.bp4-icon-image-rotate-left::before {
  content: "\f1f8";
}

.bp4-icon-image-rotate-right::before {
  content: "\f1f9";
}

.bp4-icon-known-vehicle::before {
  content: "\f216";
}

.bp4-icon-unknown-vehicle::before {
  content: "\f304";
}

.bp4-icon-scatter-plot::before {
  content: "\f29e";
}

.bp4-icon-oil-field::before {
  content: "\f263";
}

.bp4-icon-rig::before {
  content: "\f294";
}

.bp4-icon-map-create::before {
  content: "\f239";
}

.bp4-icon-key-option::before {
  content: "\f212";
}

.bp4-icon-list-detail-view::before {
  content: "\f22f";
}

.bp4-icon-swap-vertical::before {
  content: "\f2d6";
}

.bp4-icon-swap-horizontal::before {
  content: "\f2d5";
}

.bp4-icon-numbered-list::before {
  content: "\f25f";
}

.bp4-icon-new-grid-item::before {
  content: "\f252";
}

.bp4-icon-git-repo::before {
  content: "\f1d0";
}

.bp4-icon-git-new-branch::before {
  content: "\f1cd";
}

.bp4-icon-manually-entered-data::before {
  content: "\f236";
}

.bp4-icon-airplane::before {
  content: "\f10a";
}

.bp4-icon-merge-columns::before {
  content: "\f241";
}

.bp4-icon-split-columns::before {
  content: "\f2c8";
}

.bp4-icon-dashboard::before {
  content: "\f171";
}

.bp4-icon-publish-function::before {
  content: "\f283";
}

.bp4-icon-path::before {
  content: "\f26e";
}

.bp4-icon-moon::before {
  content: "\f249";
}

.bp4-icon-remove-column::before {
  content: "\f28d";
}

.bp4-icon-numerical::before {
  content: "\f260";
}

.bp4-icon-key-tab::before {
  content: "\f214";
}

.bp4-icon-regression-chart::before {
  content: "\f28a";
}

.bp4-icon-translate::before {
  content: "\f2f9";
}

.bp4-icon-eye-on::before {
  content: "\f1a2";
}

.bp4-icon-vertical-bar-chart-asc::before {
  content: "\f30c";
}

.bp4-icon-horizontal-bar-chart-asc::before {
  content: "\f1f2";
}

.bp4-icon-grouped-bar-chart::before {
  content: "\f1db";
}

.bp4-icon-full-stacked-chart::before {
  content: "\f1c3";
}

.bp4-icon-endorsed::before {
  content: "\f197";
}

.bp4-icon-follower::before {
  content: "\f1bd";
}

.bp4-icon-following::before {
  content: "\f1be";
}

.bp4-icon-menu::before {
  content: "\f240";
}

.bp4-icon-collapse-all::before {
  content: "\f15e";
}

.bp4-icon-expand-all::before {
  content: "\f19f";
}

.bp4-icon-intersection::before {
  content: "\f205";
}

.bp4-icon-blocked-person::before {
  content: "\f134";
}

.bp4-icon-slash::before {
  content: "\f2b9";
}

.bp4-icon-percentage::before {
  content: "\f271";
}

.bp4-icon-satellite::before {
  content: "\f29c";
}

.bp4-icon-paragraph::before {
  content: "\f26c";
}

.bp4-icon-bank-account::before {
  content: "\f131";
}

.bp4-icon-cell-tower::before {
  content: "\f145";
}

.bp4-icon-id-number::before {
  content: "\f1f7";
}

.bp4-icon-ip-address::before {
  content: "\f206";
}

.bp4-icon-eraser::before {
  content: "\f19a";
}

.bp4-icon-issue::before {
  content: "\f209";
}

.bp4-icon-issue-new::before {
  content: "\f208";
}

.bp4-icon-issue-closed::before {
  content: "\f207";
}

.bp4-icon-panel-stats::before {
  content: "\f269";
}

.bp4-icon-panel-table::before {
  content: "\f26a";
}

.bp4-icon-tick-circle::before {
  content: "\f2ee";
}

.bp4-icon-prescription::before {
  content: "\f27d";
}

.bp4-icon-new-prescription::before {
  content: "\f258";
}

.bp4-icon-filter-keep::before {
  content: "\f1a9";
}

.bp4-icon-filter-remove::before {
  content: "\f1ac";
}

.bp4-icon-key::before {
  content: "\f215";
}

.bp4-icon-feed-subscribed::before {
  content: "\f1a6";
}

.bp4-icon-widget-button::before {
  content: "\f318";
}

.bp4-icon-widget-header::before {
  content: "\f31a";
}

.bp4-icon-widget-footer::before {
  content: "\f319";
}

.bp4-icon-header-one::before {
  content: "\f1e2";
}

.bp4-icon-header-two::before {
  content: "\f1e4";
}

.bp4-icon-form::before {
  content: "\f1c1";
}

.bp4-icon-series-add::before {
  content: "\f2aa";
}

.bp4-icon-series-search::before {
  content: "\f2ae";
}

.bp4-icon-series-filtered::before {
  content: "\f2ad";
}

.bp4-icon-series-derived::before {
  content: "\f2ac";
}

.bp4-icon-series-configuration::before {
  content: "\f2ab";
}

.bp4-icon-console::before {
  content: "\f165";
}

.bp4-icon-compass::before {
  content: "\f162";
}

.bp4-icon-walk::before {
  content: "\f314";
}

.bp4-icon-taxi::before {
  content: "\f2e3";
}

.bp4-icon-train::before {
  content: "\f2f8";
}

.bp4-icon-heart-broken::before {
  content: "\f1e7";
}

.bp4-icon-inner-join::before {
  content: "\f203";
}

.bp4-icon-left-join::before {
  content: "\f227";
}

.bp4-icon-right-join::before {
  content: "\f295";
}

.bp4-icon-strikethrough::before {
  content: "\f2d3";
}

.bp4-icon-updated::before {
  content: "\f308";
}

.bp4-icon-outdated::before {
  content: "\f267";
}

.bp4-icon-flame::before {
  content: "\f1af";
}

.bp4-icon-folder-new::before {
  content: "\f1b9";
}

.bp4-icon-mountain::before {
  content: "\f24b";
}

.bp4-icon-shield::before {
  content: "\f2b3";
}

.bp4-icon-diagram-tree::before {
  content: "\f17a";
}

.bp4-icon-crown::before {
  content: "\f16a";
}

.bp4-icon-globe-network::before {
  content: "\f1d2";
}

.bp4-icon-snowflake::before {
  content: "\f2bf";
}

.bp4-icon-tree::before {
  content: "\f2fb";
}

.bp4-icon-notifications-updated::before {
  content: "\f25d";
}

.bp4-icon-list-columns::before {
  content: "\f22e";
}

.bp4-icon-flow-linear::before {
  content: "\f1b4";
}

.bp4-icon-flow-branch::before {
  content: "\f1b2";
}

.bp4-icon-flow-review::before {
  content: "\f1b6";
}

.bp4-icon-flow-review-branch::before {
  content: "\f1b5";
}

.bp4-icon-flow-end::before {
  content: "\f1b3";
}

.bp4-icon-clean::before {
  content: "\f155";
}

.bp4-icon-th-filtered::before {
  content: "\f2e8";
}

.bp4-icon-lifesaver::before {
  content: "\f22a";
}

.bp4-icon-cube::before {
  content: "\f16d";
}

.bp4-icon-cube-add::before {
  content: "\f16b";
}

.bp4-icon-cube-remove::before {
  content: "\f16c";
}

.bp4-icon-inbox-filtered::before {
  content: "\f1fb";
}

.bp4-icon-inbox-geo::before {
  content: "\f1fc";
}

.bp4-icon-inbox-search::before {
  content: "\f1fd";
}

.bp4-icon-inbox-update::before {
  content: "\f1fe";
}

.bp4-icon-inheritance::before {
  content: "\f201";
}

.bp4-icon-reset::before {
  content: "\f292";
}

.bp4-icon-filter-open::before {
  content: "\f1ab";
}

.bp4-icon-th-disconnect::before {
  content: "\f2e7";
}

.bp4-icon-equals::before {
  content: "\f199";
}

.bp4-icon-not-equal-to::before {
  content: "\f25b";
}

.bp4-icon-greater-than::before {
  content: "\f1d7";
}

.bp4-icon-greater-than-or-equal-to::before {
  content: "\f1d6";
}

.bp4-icon-less-than::before {
  content: "\f229";
}

.bp4-icon-less-than-or-equal-to::before {
  content: "\f228";
}

.bp4-icon-learning::before {
  content: "\f226";
}

.bp4-icon-new-layer::before {
  content: "\f253";
}

.bp4-icon-new-layers::before {
  content: "\f254";
}

.bp4-icon-stopwatch::before {
  content: "\f2d2";
}

.bp4-icon-archive::before {
  content: "\f11a";
}

.bp4-icon-unarchive::before {
  content: "\f300";
}

.bp4-icon-data-lineage::before {
  content: "\f173";
}

.bp4-icon-new-drawing::before {
  content: "\f251";
}

.bp4-icon-signal-search::before {
  content: "\f2b7";
}

.bp4-icon-bring-data::before {
  content: "\f13a";
}

.bp4-icon-tractor::before {
  content: "\f2f7";
}

.bp4-icon-truck::before {
  content: "\f2fe";
}

.bp4-icon-diagnosis::before {
  content: "\f179";
}

.bp4-icon-lab-test::before {
  content: "\f217";
}

.bp4-icon-virus::before {
  content: "\f310";
}

.bp4-icon-inherited-group::before {
  content: "\f202";
}

.bp4-icon-hat::before {
  content: "\f1e1";
}

.bp4-icon-cycle::before {
  content: "\f170";
}

.bp4-icon-route::before {
  content: "\f29b";
}

.bp4-icon-modal::before {
  content: "\f248";
}

.bp4-icon-modal-filled::before {
  content: "\f247";
}

.bp4-icon-drawer-left::before {
  content: "\f18f";
}

.bp4-icon-drawer-left-filled::before {
  content: "\f18e";
}

.bp4-icon-drawer-right::before {
  content: "\f191";
}

.bp4-icon-drawer-right-filled::before {
  content: "\f190";
}

.bp4-icon-app-header::before {
  content: "\f117";
}

.bp4-icon-send-message::before {
  content: "\f2a6";
}

.bp4-icon-backlink::before {
  content: "\f12e";
}

.bp4-icon-geofence::before {
  content: "\f1c7";
}

.bp4-icon-data-connection::before {
  content: "\f172";
}

.bp4-icon-switch::before {
  content: "\f2d7";
}

.bp4-icon-array::before {
  content: "\f121";
}

.bp4-icon-array-boolean::before {
  content: "\f11c";
}

.bp4-icon-array-date::before {
  content: "\f11d";
}

.bp4-icon-array-numeric::before {
  content: "\f11e";
}

.bp4-icon-array-string::before {
  content: "\f11f";
}

.bp4-icon-array-timestamp::before {
  content: "\f120";
}

.bp4-icon-layer-outline::before {
  content: "\f219";
}

.bp4-icon-notifications-snooze::before {
  content: "\f25c";
}

.bp4-icon-high-priority::before {
  content: "\f1ee";
}

.bp4-icon-emoji::before {
  content: "\f196";
}

.bp4-icon-add-location::before {
  content: "\f104";
}

.bp4-icon-shapes::before {
  content: "\f2b0";
}

.bp4-icon-shared-filter::before {
  content: "\f2b2";
}

.bp4-icon-one-to-one::before {
  content: "\f266";
}

.bp4-icon-one-to-many::before {
  content: "\f265";
}

.bp4-icon-many-to-one::before {
  content: "\f238";
}

.bp4-icon-many-to-many::before {
  content: "\f237";
}

.bp4-icon-stadium-geometry::before {
  content: "\f2cb";
}

.bp4-icon-area-of-interest::before {
  content: "\f11b";
}

.bp4-icon-buggy::before {
  content: "\f13b";
}

.bp4-icon-antenna::before {
  content: "\f116";
}

.bp4-icon-tank::before {
  content: "\f2e1";
}

.bp4-icon-third-party::before {
  content: "\f2eb";
}

.bp4-icon-rocket::before {
  content: "\f298";
}

.bp4-icon-rocket-slant::before {
  content: "\f297";
}

.bp4-icon-header-three::before {
  content: "\f1e3";
}

.bp4-icon-helicopter::before {
  content: "\f1eb";
}

.bp4-icon-syringe::before {
  content: "\f2de";
}

.bp4-icon-temperature::before {
  content: "\f2e4";
}

.bp4-icon-waves::before {
  content: "\f317";
}

.bp4-icon-rain::before {
  content: "\f285";
}

.bp4-icon-lightning::before {
  content: "\f22c";
}

.bp4-icon-wind::before {
  content: "\f31c";
}

.bp4-icon-hurricane::before {
  content: "\f1f6";
}

.bp4-icon-nest::before {
  content: "\f250";
}

.bp4-icon-target::before {
  content: "\f2e2";
}

.bp4-icon-small-square::before {
  content: "\f2bd";
}

.bp4-icon-ship::before {
  content: "\f2b4";
}

.bp4-icon-cargo-ship::before {
  content: "\f144";
}

.bp4-icon-clip::before {
  content: "\f156";
}

.bp4-icon-add-clip::before {
  content: "\f101";
}

.bp4-icon-rectangle::before {
  content: "\f321";
}

.bp4-icon-symbol-rectangle::before {
  content: "\f322";
}

.bp4-icon-fuel::before {
  content: "\f323";
}

.bp4-icon-playbook::before {
  content: "\f324";
}

.bp4-icon-rect-height::before {
  content: "\f325";
}

.bp4-icon-rect-width::before {
  content: "\f326";
}

.bp4-icon-divide::before {
  content: "\f327";
}

.bp4-icon-color-fill::before {
  content: "\f328";
}

.bp4-icon-horizontal-inbetween::before {
  content: "\f329";
}

.bp4-icon-vertical-inbetween::before {
  content: "\f32a";
}

.bp4-icon-open-application::before {
  content: "\f32b";
}

.bp4-icon-floating-point::before {
  content: "\f32c";
}

.bp4-icon-array-floating-point::before {
  content: "\f32d";
}

.bp4-text-muted {
  color: #5f6b7c;
}

.bp4-text-disabled {
  color: rgba(95, 107, 124, 0.6);
}

.bp4-running-text hr {
  border-color: rgba(17, 20, 24, 0.15);
}

a {
  color: #215db0;
}
a:hover {
  color: #215db0;
}
a .bp4-icon, a .bp4-icon-standard, a .bp4-icon-large {
  color: inherit;
}
a code {
  color: inherit;
}

.bp4-code,
.bp4-running-text code {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}
a > .bp4-code,
a > .bp4-running-text code {
  color: #2d72d2;
}

.bp4-code-block,
.bp4-running-text pre {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color: #1c2127;
}
.bp4-code-block > code,
.bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}

.bp4-key,
.bp4-running-text kbd {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}

.bp4-icon.bp4-intent-primary, .bp4-icon-standard.bp4-intent-primary, .bp4-icon-large.bp4-intent-primary {
  color: #215db0;
}
.bp4-icon.bp4-intent-success, .bp4-icon-standard.bp4-intent-success, .bp4-icon-large.bp4-intent-success {
  color: #1c6e42;
}
.bp4-icon.bp4-intent-warning, .bp4-icon-standard.bp4-intent-warning, .bp4-icon-large.bp4-intent-warning {
  color: #935610;
}
.bp4-icon.bp4-intent-danger, .bp4-icon-standard.bp4-intent-danger, .bp4-icon-large.bp4-intent-danger {
  color: #ac2f33;
}

/*
Headings

Markup:
<div>
  <h1 class="@ns-heading">H1 heading</h1>
  <h2 class="@ns-heading">H2 heading</h2>
  <h3 class="@ns-heading">H3 heading</h3>
  <h4 class="@ns-heading">H4 heading</h4>
  <h5 class="@ns-heading">H5 heading</h5>
  <h6 class="@ns-heading">H6 heading</h6>
</div>

Styleguide headings
*/
.bp4-heading {
  color: #1c2127;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
}
.bp4-dark .bp4-heading {
  color: #f6f7f9;
}

.bp4-running-text h1, h1.bp4-heading {
  font-size: 36px;
  line-height: 40px;
}

.bp4-running-text h2, h2.bp4-heading {
  font-size: 28px;
  line-height: 32px;
}

.bp4-running-text h3, h3.bp4-heading {
  font-size: 22px;
  line-height: 25px;
}

.bp4-running-text h4, h4.bp4-heading {
  font-size: 18px;
  line-height: 21px;
}

.bp4-running-text h5, h5.bp4-heading {
  font-size: 16px;
  line-height: 19px;
}

.bp4-running-text h6, h6.bp4-heading {
  font-size: 14px;
  line-height: 16px;
}

/*
UI text

Markup:
<div class="{{.modifier}}">
  More than a decade ago, we set out to create products that would transform
  the way organizations use their data. Today, our products are deployed at
  the most critical government, commercial, and non-profit institutions in
  the world to solve problems we hadn’t even dreamed of back then.
</div>

.bp4-ui-text - Default Blueprint font styles, applied to the `<body>` tag and available as a class for nested resets.
.bp4-monospace-text - Use a monospace font (ideal for code).
.bp4-running-text - Increase line height ideal for longform text. See [Running text](#core/typography.running-text) below for additional features.
.bp4-text-large - Use a larger font size.
.bp4-text-small - Use a smaller font size.
.bp4-text-muted - Change text color to a gentler gray.
.bp4-text-disabled - Change text color to a transparent, faded gray.
.bp4-text-overflow-ellipsis - Truncate a single line of text with an ellipsis if it overflows its container.

Styleguide ui-text
*/
.bp4-ui-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.2858142857;
  text-transform: none;
}

.bp4-monospace-text {
  font-family: monospace;
  text-transform: none;
}

.bp4-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

/*
Running text

Markup:
<div class="@ns-running-text {{.modifier}}">
  <p>
    We build products that make people better at their most important
    work — the kind of work you read about on the front page of the
    newspaper, not just the technology section.
  </p>
  <ul>
    <li>Item the <code>first</code>.</li>
    <li>Item the <strong>second</strong>.</li>
    <li>Item the <a href="#core/typography.running-text">third</a>.</li>
  </ul>
  <h3>Scale, Speed, Agility</h3>
  <p>
    A successful data transformation requires the whole organization — users, the IT shop, and
    leadership — to operate in lockstep. With Foundry, the enterprise comes together to
    transform the organization and turn data into a competitive advantage.
  </p>
</div>

.bp4-text-large - Use larger font size.

Styleguide running-text
*/
.bp4-running-text {
  font-size: 14px;
  line-height: 1.5;
}
.bp4-running-text h1 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h1 {
  color: #f6f7f9;
}
.bp4-running-text h2 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h2 {
  color: #f6f7f9;
}
.bp4-running-text h3 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h3 {
  color: #f6f7f9;
}
.bp4-running-text h4 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h4 {
  color: #f6f7f9;
}
.bp4-running-text h5 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h5 {
  color: #f6f7f9;
}
.bp4-running-text h6 {
  color: #1c2127;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.bp4-dark .bp4-running-text h6 {
  color: #f6f7f9;
}
.bp4-running-text hr {
  border: none;
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  margin: 20px 0;
}
.bp4-running-text p {
  margin: 0 0 10px;
  padding: 0;
}
.bp4-text-large {
  font-size: 16px;
}

.bp4-text-small {
  font-size: 12px;
}

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Styleguide typography.links
*/
a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*
Preformatted text

Markup:
<div>
  <p>Use the <code class="@ns-code">&lt;code></code> tag for snippets of code.</p>
  <pre class="@ns-code-block">Use the &lt;pre> tag for blocks of code.</pre>
  <pre class="@ns-code-block"><code>// code sample
export function hasModifier(
  modifiers: ts.ModifiersArray,
  ...modifierKinds: ts.SyntaxKind[],
) {
  if (modifiers == null || modifierKinds == null) {
    return false;
  }
  return modifiers.some(m => modifierKinds.some(k => m.kind === k));
}</code></pre>
</div>

Styleguide preformatted
*/
.bp4-code, .bp4-running-text code {
  font-family: monospace;
  text-transform: none;
  border-radius: 2px;
  font-size: smaller;
  padding: 2px 5px;
}

.bp4-code-block, .bp4-running-text pre {
  font-family: monospace;
  text-transform: none;
  border-radius: 2px;
  display: block;
  font-size: 13px;
  line-height: 1.4;
  margin: 10px 0;
  padding: 13px 15px 12px;
  word-break: break-all;
  word-wrap: break-word;
}
.bp4-code-block > code, .bp4-running-text pre > code {
  font-size: inherit;
  padding: 0;
}

.bp4-key, .bp4-running-text kbd {
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 24px;
  min-width: 24px;
  padding: 2px 4px;
  vertical-align: middle;
}
.bp4-key .bp4-icon, .bp4-running-text kbd .bp4-icon, .bp4-key .bp4-icon-standard, .bp4-running-text kbd .bp4-icon-standard, .bp4-key .bp4-icon-large, .bp4-running-text kbd .bp4-icon-large {
  margin-right: 5px;
}

/*
Block quotes

Markup:
<blockquote class="@ns-blockquote">
  Premium Aerotec is a key supplier for Airbus, producing 30 million parts per year,
  which is huge complexity. Skywise helps us manage all the production steps.
  It gives Airbus much better visibility into where the product is in the supply chain,
  and it lets us immediately see our weak points so we can react on the spot.
</blockquote>

Styleguide blockquote
*/
.bp4-blockquote, .bp4-running-text blockquote {
  border-left: solid 4px rgba(171, 179, 191, 0.5);
  margin: 0 0 10px;
  padding: 0 20px;
}
.bp4-dark .bp4-blockquote, .bp4-dark .bp4-running-text blockquote, .bp4-running-text .bp4-dark blockquote {
  border-color: rgba(115, 128, 145, 0.5);
}

/*
Lists

Markup:
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>
    Item the third
    <ul class="{{.modifier}}">
      <li>Item the fourth</li>
      <li>Item the fifth</li>
    </ul>
  </li>
</ol>

.bp4-list - Add a little spacing between items for readability.
.bp4-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Styleguide lists
*/
.bp4-list, .bp4-running-text ul,
.bp4-running-text ol {
  margin: 10px 0;
  padding-left: 30px;
}
.bp4-list li:not(:last-child), .bp4-running-text ul li:not(:last-child),
.bp4-running-text ol li:not(:last-child) {
  margin-bottom: 5px;
}
.bp4-list ol, .bp4-running-text ul ol,
.bp4-running-text ol ol,
.bp4-list ul,
.bp4-running-text ul ul,
.bp4-running-text ol ul {
  margin-top: 5px;
}

.bp4-list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bp4-list-unstyled li {
  padding: 0;
}

/*
Right-to-left text

Markup:
<h5 class="@ns-heading">Arabic:</h5>
<p class="@ns-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h5 class="@ns-heading">Hebrew:</h5>
<p class="@ns-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide rtl
*/
.bp4-rtl {
  text-align: right;
}

/*
Dark theme
*/
.bp4-dark {
  color: #f6f7f9;
}
.bp4-dark .bp4-text-muted {
  color: #abb3bf;
}
.bp4-dark .bp4-text-disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-running-text hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.bp4-dark a {
  color: #8abbff;
}
.bp4-dark a:hover {
  color: #8abbff;
}
.bp4-dark a .bp4-icon, .bp4-dark a .bp4-icon-standard, .bp4-dark a .bp4-icon-large {
  color: inherit;
}
.bp4-dark a code {
  color: inherit;
}
.bp4-dark .bp4-code,
.bp4-dark .bp4-running-text code {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
a > .bp4-dark .bp4-code,
a > .bp4-dark .bp4-running-text code {
  color: inherit;
}
.bp4-dark .bp4-code-block,
.bp4-dark .bp4-running-text pre {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #f6f7f9;
}
.bp4-dark .bp4-code-block > code,
.bp4-dark .bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}
.bp4-dark .bp4-key,
.bp4-dark .bp4-running-text kbd {
  background: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
.bp4-dark .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-icon.bp4-intent-success, .bp4-dark .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}

:focus {
  outline: rgba(45, 114, 210, 0.6) solid 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px;
}

.bp4-focus-disabled :focus:not(.bp4-focus-style-manager-ignore *) {
  /* stylelint-disable declaration-no-important */
  outline: none !important;
}
.bp4-focus-disabled :focus:not(.bp4-focus-style-manager-ignore *) ~ .bp4-control-indicator {
  outline: none !important;
}

.bp4-dark {
  color-scheme: dark;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-alert {
  max-width: 400px;
  padding: 20px;
}

.bp4-alert-body {
  display: flex;
}
.bp4-alert-body .bp4-icon {
  font-size: 40px;
  margin-right: 20px;
  margin-top: 0;
}

.bp4-alert-contents {
  word-break: break-word;
}

.bp4-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
}
.bp4-alert-footer .bp4-button {
  margin-left: 10px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Breadcrumbs

Markup:
<ul class="bp4-breadcrumbs">
  <li><a class="bp4-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="bp4-breadcrumb bp4-disabled">Folder one</a></li>
  <li><a class="bp4-breadcrumb" href="#">Folder two</a></li>
  <li><a class="bp4-breadcrumb" href="#">Folder three</a></li>
  <li><span class="bp4-breadcrumb bp4-breadcrumb-current">File</span></li>
</ul>

Styleguide breadcrumbs
*/
.bp4-breadcrumbs {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.bp4-breadcrumbs > li {
  align-items: center;
  display: flex;
}
.bp4-breadcrumbs > li::after {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235F6B7C'/%3e%3c/svg%3e");
  content: "";
  display: block;
  height: 16px;
  margin: 0 5px;
  width: 16px;
}
.bp4-breadcrumbs > li:last-of-type::after {
  display: none;
}

.bp4-breadcrumb,
.bp4-breadcrumb-current,
.bp4-breadcrumbs-collapsed {
  align-items: center;
  display: inline-flex;
  font-size: 16px;
}

.bp4-breadcrumb,
.bp4-breadcrumbs-collapsed {
  color: #5f6b7c;
}

.bp4-breadcrumb:hover {
  text-decoration: none;
}
.bp4-breadcrumb.bp4-disabled {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-breadcrumb .bp4-icon {
  margin-right: 5px;
}

.bp4-breadcrumb-current {
  color: inherit;
  font-weight: 600;
}
.bp4-breadcrumb-current .bp4-input {
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}

.bp4-breadcrumbs-collapsed {
  background: rgba(143, 153, 168, 0.15);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 2px;
  padding: 1px 5px;
  vertical-align: text-bottom;
}
.bp4-breadcrumbs-collapsed::before {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%235F6B7C'%3e%3ccircle cx='2' cy='8.03' r='2'/%3e%3ccircle cx='14' cy='8.03' r='2'/%3e%3ccircle cx='8' cy='8.03' r='2'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
}
.bp4-breadcrumbs-collapsed:hover {
  background: rgba(143, 153, 168, 0.3);
  color: #1c2127;
  text-decoration: none;
}

.bp4-dark .bp4-breadcrumb,
.bp4-dark .bp4-breadcrumbs-collapsed {
  color: #abb3bf;
}
.bp4-dark .bp4-breadcrumbs > li::after {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%23ABB3BF'/%3e%3c/svg%3e");
  color: #abb3bf;
}
.bp4-dark .bp4-breadcrumb.bp4-disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-breadcrumb-current {
  color: #f6f7f9;
}
.bp4-dark .bp4-breadcrumbs-collapsed {
  background: rgba(143, 153, 168, 0.2);
}
.bp4-dark .bp4-breadcrumbs-collapsed::before {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%23ABB3BF'%3e%3ccircle cx='2' cy='8.03' r='2'/%3e%3ccircle cx='14' cy='8.03' r='2'/%3e%3ccircle cx='8' cy='8.03' r='2'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
}
.bp4-dark .bp4-breadcrumbs-collapsed:hover {
  background: rgba(143, 153, 168, 0.3);
  color: #f6f7f9;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Button

Markup:
<a role="button" class="bp4-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="bp4-button bp4-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.bp4-active - Active appearance
.bp4-disabled - Disabled appearance
.bp4-intent-primary - Primary intent
.bp4-intent-success - Success intent
.bp4-intent-warning - Warning intent
.bp4-intent-danger - Danger intent
.bp4-minimal - More subtle appearance
.bp4-outlined - Subtle yet structured appearance
.bp4-large - Larger size
.bp4-small - Smaller size
.bp4-fill - Fill parent container

Styleguide button
*/
.bp4-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px;
}
.bp4-button > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-button > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-button::before,
.bp4-button > * {
  margin-right: 7px;
}
.bp4-button:empty::before,
.bp4-button > :last-child {
  margin-right: 0;
}
.bp4-button:empty {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 0 !important;
}
.bp4-button:disabled, .bp4-button.bp4-disabled {
  cursor: not-allowed;
}
.bp4-button.bp4-fill {
  display: flex;
  width: 100%;
}
.bp4-button.bp4-align-right, .bp4-align-right .bp4-button {
  text-align: right;
}
.bp4-button.bp4-align-left, .bp4-align-left .bp4-button {
  text-align: left;
}
.bp4-button:not([class*=bp4-intent-]) {
  background-color: #f6f7f9;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #1c2127;
}
.bp4-button:not([class*=bp4-intent-]):hover {
  background-clip: padding-box;
  background-color: #edeff2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button:not([class*=bp4-intent-]):active, .bp4-button:not([class*=bp4-intent-]).bp4-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button:not([class*=bp4-intent-]):disabled, .bp4-button:not([class*=bp4-intent-]).bp4-disabled {
  background-color: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  outline: none;
}
.bp4-button:not([class*=bp4-intent-]):disabled.bp4-active, .bp4-button:not([class*=bp4-intent-]):disabled.bp4-active:hover, .bp4-button:not([class*=bp4-intent-]).bp4-disabled.bp4-active, .bp4-button:not([class*=bp4-intent-]).bp4-disabled.bp4-active:hover {
  background: rgba(211, 216, 222, 0.7);
}
.bp4-button.bp4-intent-primary {
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #fff;
}
.bp4-button.bp4-intent-primary:hover, .bp4-button.bp4-intent-primary:active, .bp4-button.bp4-intent-primary.bp4-active {
  color: #fff;
}
.bp4-button.bp4-intent-primary:hover {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-primary:active, .bp4-button.bp4-intent-primary.bp4-active {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-primary:disabled, .bp4-button.bp4-intent-primary.bp4-disabled {
  background-color: rgba(45, 114, 210, 0.5);
  border-color: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-button.bp4-intent-success {
  background-color: #238551;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #fff;
}
.bp4-button.bp4-intent-success:hover, .bp4-button.bp4-intent-success:active, .bp4-button.bp4-intent-success.bp4-active {
  color: #fff;
}
.bp4-button.bp4-intent-success:hover {
  background-color: #1c6e42;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-success:active, .bp4-button.bp4-intent-success.bp4-active {
  background-color: #165a36;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-success:disabled, .bp4-button.bp4-intent-success.bp4-disabled {
  background-color: rgba(35, 133, 81, 0.5);
  border-color: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-button.bp4-intent-warning {
  background-color: #c87619;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #fff;
}
.bp4-button.bp4-intent-warning:hover, .bp4-button.bp4-intent-warning:active, .bp4-button.bp4-intent-warning.bp4-active {
  color: #fff;
}
.bp4-button.bp4-intent-warning:hover {
  background-color: #935610;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-warning:active, .bp4-button.bp4-intent-warning.bp4-active {
  background-color: #77450d;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-warning:disabled, .bp4-button.bp4-intent-warning.bp4-disabled {
  background-color: rgba(200, 118, 25, 0.5);
  border-color: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-button.bp4-intent-danger {
  background-color: #cd4246;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #fff;
}
.bp4-button.bp4-intent-danger:hover, .bp4-button.bp4-intent-danger:active, .bp4-button.bp4-intent-danger.bp4-active {
  color: #fff;
}
.bp4-button.bp4-intent-danger:hover {
  background-color: #ac2f33;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-danger:active, .bp4-button.bp4-intent-danger.bp4-active {
  background-color: #8e292c;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-button.bp4-intent-danger:disabled, .bp4-button.bp4-intent-danger.bp4-disabled {
  background-color: rgba(205, 66, 70, 0.5);
  border-color: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-button[class*=bp4-intent-] .bp4-button-spinner .bp4-spinner-head {
  stroke: #fff;
}
.bp4-button.bp4-large, .bp4-large .bp4-button {
  min-height: 40px;
  min-width: 40px;
  font-size: 16px;
  padding: 5px 15px;
}
.bp4-button.bp4-large::before,
.bp4-button.bp4-large > *, .bp4-large .bp4-button::before,
.bp4-large .bp4-button > * {
  margin-right: 10px;
}
.bp4-button.bp4-large:empty::before,
.bp4-button.bp4-large > :last-child, .bp4-large .bp4-button:empty::before,
.bp4-large .bp4-button > :last-child {
  margin-right: 0;
}
.bp4-button.bp4-small, .bp4-small .bp4-button {
  min-height: 24px;
  min-width: 24px;
  padding: 0 7px;
}
.bp4-button.bp4-loading {
  position: relative;
}
.bp4-button.bp4-loading[class*=bp4-icon-]::before {
  visibility: hidden;
}
.bp4-button.bp4-loading .bp4-button-spinner {
  margin: 0;
  position: absolute;
}
.bp4-button.bp4-loading > :not(.bp4-button-spinner) {
  visibility: hidden;
}
.bp4-button[class*=bp4-icon-]::before {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
}
.bp4-button .bp4-icon, .bp4-button .bp4-icon-standard, .bp4-button .bp4-icon-large {
  color: #5f6b7c;
}
.bp4-button .bp4-icon.bp4-align-right, .bp4-button .bp4-icon-standard.bp4-align-right, .bp4-button .bp4-icon-large.bp4-align-right {
  margin-left: 7px;
}
.bp4-button .bp4-icon:first-child:last-child,
.bp4-button .bp4-spinner + .bp4-icon:last-child {
  margin: 0 -7px;
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]) {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color: #f6f7f9;
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]):hover, .bp4-dark .bp4-button:not([class*=bp4-intent-]):active, .bp4-dark .bp4-button:not([class*=bp4-intent-]).bp4-active {
  color: #f6f7f9;
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]):hover {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]):active, .bp4-dark .bp4-button:not([class*=bp4-intent-]).bp4-active {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]):disabled, .bp4-dark .bp4-button:not([class*=bp4-intent-]).bp4-disabled {
  background-color: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]):disabled.bp4-active, .bp4-dark .bp4-button:not([class*=bp4-intent-]).bp4-disabled.bp4-active {
  background: rgba(64, 72, 84, 0.7);
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]) .bp4-button-spinner .bp4-spinner-head {
  background: rgba(17, 20, 24, 0.5);
  stroke: #8f99a8;
}
.bp4-dark .bp4-button:not([class*=bp4-intent-])[class*=bp4-icon-]::before {
  color: #abb3bf;
}
.bp4-dark .bp4-button:not([class*=bp4-intent-]) .bp4-icon:not([class*=bp4-intent-]), .bp4-dark .bp4-button:not([class*=bp4-intent-]) .bp4-icon-standard:not([class*=bp4-intent-]), .bp4-dark .bp4-button:not([class*=bp4-intent-]) .bp4-icon-large:not([class*=bp4-intent-]) {
  color: #abb3bf;
}
.bp4-dark .bp4-button[class*=bp4-intent-] {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-button[class*=bp4-intent-]:hover {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-button[class*=bp4-intent-]:active, .bp4-dark .bp4-button[class*=bp4-intent-].bp4-active {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-button[class*=bp4-intent-]:disabled, .bp4-dark .bp4-button[class*=bp4-intent-].bp4-disabled {
  box-shadow: none;
  color: rgba(255, 255, 255, 0.3);
}
.bp4-dark .bp4-button[class*=bp4-intent-] .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
}
.bp4-button:disabled::before,
.bp4-button:disabled .bp4-icon, .bp4-button:disabled .bp4-icon-standard, .bp4-button:disabled .bp4-icon-large, .bp4-button.bp4-disabled::before,
.bp4-button.bp4-disabled .bp4-icon, .bp4-button.bp4-disabled .bp4-icon-standard, .bp4-button.bp4-disabled .bp4-icon-large, .bp4-button[class*=bp4-intent-]::before,
.bp4-button[class*=bp4-intent-] .bp4-icon, .bp4-button[class*=bp4-intent-] .bp4-icon-standard, .bp4-button[class*=bp4-intent-] .bp4-icon-large {
  /* stylelint-disable-next-line declaration-no-important */
  color: inherit !important;
}
.bp4-button.bp4-minimal {
  background: none;
  box-shadow: none;
}
.bp4-button.bp4-minimal:hover {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  color: #1c2127;
  text-decoration: none;
}
.bp4-button.bp4-minimal:active, .bp4-button.bp4-minimal.bp4-active {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: none;
  color: #1c2127;
}
.bp4-button.bp4-minimal:disabled, .bp4-button.bp4-minimal:disabled:hover, .bp4-button.bp4-minimal.bp4-disabled, .bp4-button.bp4-minimal.bp4-disabled:hover {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-button.bp4-minimal:disabled.bp4-active, .bp4-button.bp4-minimal:disabled:hover.bp4-active, .bp4-button.bp4-minimal.bp4-disabled.bp4-active, .bp4-button.bp4-minimal.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button.bp4-minimal {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button.bp4-minimal:hover, .bp4-dark .bp4-button.bp4-minimal:active, .bp4-dark .bp4-button.bp4-minimal.bp4-active {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button.bp4-minimal:hover {
  background: rgba(143, 153, 168, 0.15);
}
.bp4-dark .bp4-button.bp4-minimal:active, .bp4-dark .bp4-button.bp4-minimal.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button.bp4-minimal:disabled, .bp4-dark .bp4-button.bp4-minimal:disabled:hover, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled:hover {
  background: none;
  color: rgba(171, 179, 191, 0.6);
  cursor: not-allowed;
}
.bp4-dark .bp4-button.bp4-minimal:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal:disabled:hover.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-primary {
  color: #215db0;
}
.bp4-button.bp4-minimal.bp4-intent-primary:hover, .bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  background: none;
  box-shadow: none;
  color: #215db0;
}
.bp4-button.bp4-minimal.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.15);
  color: #215db0;
}
.bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #184a90;
}
.bp4-button.bp4-minimal.bp4-intent-primary:disabled, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(33, 93, 176, 0.5);
}
.bp4-button.bp4-minimal.bp4-intent-primary:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(138, 187, 255, 0.5);
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-success {
  color: #1c6e42;
}
.bp4-button.bp4-minimal.bp4-intent-success:hover, .bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  background: none;
  box-shadow: none;
  color: #1c6e42;
}
.bp4-button.bp4-minimal.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.15);
  color: #1c6e42;
}
.bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #165a36;
}
.bp4-button.bp4-minimal.bp4-intent-success:disabled, .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(28, 110, 66, 0.5);
}
.bp4-button.bp4-minimal.bp4-intent-success:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(114, 202, 155, 0.5);
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-warning {
  color: #935610;
}
.bp4-button.bp4-minimal.bp4-intent-warning:hover, .bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  background: none;
  box-shadow: none;
  color: #935610;
}
.bp4-button.bp4-minimal.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.15);
  color: #935610;
}
.bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #77450d;
}
.bp4-button.bp4-minimal.bp4-intent-warning:disabled, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(147, 86, 16, 0.5);
}
.bp4-button.bp4-minimal.bp4-intent-warning:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(251, 179, 96, 0.5);
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-button.bp4-minimal.bp4-intent-danger:hover, .bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  background: none;
  box-shadow: none;
  color: #ac2f33;
}
.bp4-button.bp4-minimal.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.15);
  color: #ac2f33;
}
.bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #8e292c;
}
.bp4-button.bp4-minimal.bp4-intent-danger:disabled, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(172, 47, 51, 0.5);
}
.bp4-button.bp4-minimal.bp4-intent-danger:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button.bp4-minimal.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(250, 153, 156, 0.5);
}
.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button.bp4-outlined {
  background: none;
  box-shadow: none;
  border: 1px solid rgba(28, 33, 39, 0.2);
  box-sizing: border-box;
}
.bp4-button.bp4-outlined:hover {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  color: #1c2127;
  text-decoration: none;
}
.bp4-button.bp4-outlined:active, .bp4-button.bp4-outlined.bp4-active {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: none;
  color: #1c2127;
}
.bp4-button.bp4-outlined:disabled, .bp4-button.bp4-outlined:disabled:hover, .bp4-button.bp4-outlined.bp4-disabled, .bp4-button.bp4-outlined.bp4-disabled:hover {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-button.bp4-outlined:disabled.bp4-active, .bp4-button.bp4-outlined:disabled:hover.bp4-active, .bp4-button.bp4-outlined.bp4-disabled.bp4-active, .bp4-button.bp4-outlined.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button.bp4-outlined {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button.bp4-outlined:hover, .bp4-dark .bp4-button.bp4-outlined:active, .bp4-dark .bp4-button.bp4-outlined.bp4-active {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button.bp4-outlined:hover {
  background: rgba(143, 153, 168, 0.15);
}
.bp4-dark .bp4-button.bp4-outlined:active, .bp4-dark .bp4-button.bp4-outlined.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button.bp4-outlined:disabled, .bp4-dark .bp4-button.bp4-outlined:disabled:hover, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover {
  background: none;
  color: rgba(171, 179, 191, 0.6);
  cursor: not-allowed;
}
.bp4-dark .bp4-button.bp4-outlined:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined:disabled:hover.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-primary {
  color: #215db0;
}
.bp4-button.bp4-outlined.bp4-intent-primary:hover, .bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  background: none;
  box-shadow: none;
  color: #215db0;
}
.bp4-button.bp4-outlined.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.15);
  color: #215db0;
}
.bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #184a90;
}
.bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(33, 93, 176, 0.5);
}
.bp4-button.bp4-outlined.bp4-intent-primary:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(138, 187, 255, 0.5);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-success {
  color: #1c6e42;
}
.bp4-button.bp4-outlined.bp4-intent-success:hover, .bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  background: none;
  box-shadow: none;
  color: #1c6e42;
}
.bp4-button.bp4-outlined.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.15);
  color: #1c6e42;
}
.bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #165a36;
}
.bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(28, 110, 66, 0.5);
}
.bp4-button.bp4-outlined.bp4-intent-success:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(114, 202, 155, 0.5);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-warning {
  color: #935610;
}
.bp4-button.bp4-outlined.bp4-intent-warning:hover, .bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  background: none;
  box-shadow: none;
  color: #935610;
}
.bp4-button.bp4-outlined.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.15);
  color: #935610;
}
.bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #77450d;
}
.bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(147, 86, 16, 0.5);
}
.bp4-button.bp4-outlined.bp4-intent-warning:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(251, 179, 96, 0.5);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-button.bp4-outlined.bp4-intent-danger:hover, .bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  background: none;
  box-shadow: none;
  color: #ac2f33;
}
.bp4-button.bp4-outlined.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.15);
  color: #ac2f33;
}
.bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #8e292c;
}
.bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(172, 47, 51, 0.5);
}
.bp4-button.bp4-outlined.bp4-intent-danger:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button.bp4-outlined.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(250, 153, 156, 0.5);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button.bp4-outlined:disabled, .bp4-button.bp4-outlined.bp4-disabled, .bp4-button.bp4-outlined:disabled:hover, .bp4-button.bp4-outlined.bp4-disabled:hover {
  border-color: rgba(95, 107, 124, 0.1);
}
.bp4-dark .bp4-button.bp4-outlined {
  border-color: rgba(255, 255, 255, 0.4);
}
.bp4-dark .bp4-button.bp4-outlined:disabled, .bp4-dark .bp4-button.bp4-outlined:disabled:hover, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover {
  border-color: rgba(255, 255, 255, 0.2);
}
.bp4-button.bp4-outlined.bp4-intent-primary {
  border-color: rgba(33, 93, 176, 0.6);
}
.bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  border-color: rgba(33, 93, 176, 0.2);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary {
  border-color: rgba(138, 187, 255, 0.6);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  border-color: rgba(138, 187, 255, 0.2);
}
.bp4-button.bp4-outlined.bp4-intent-success {
  border-color: rgba(28, 110, 66, 0.6);
}
.bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  border-color: rgba(28, 110, 66, 0.2);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success {
  border-color: rgba(114, 202, 155, 0.6);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  border-color: rgba(114, 202, 155, 0.2);
}
.bp4-button.bp4-outlined.bp4-intent-warning {
  border-color: rgba(147, 86, 16, 0.6);
}
.bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  border-color: rgba(147, 86, 16, 0.2);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning {
  border-color: rgba(251, 179, 96, 0.6);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  border-color: rgba(251, 179, 96, 0.2);
}
.bp4-button.bp4-outlined.bp4-intent-danger {
  border-color: rgba(172, 47, 51, 0.6);
}
.bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  border-color: rgba(172, 47, 51, 0.2);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger {
  border-color: rgba(250, 153, 156, 0.6);
}
.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  border-color: rgba(250, 153, 156, 0.2);
}
.bp4-button.bp4-intent-warning {
  background: #fbb360;
  color: #1c2127;
}
.bp4-button.bp4-intent-warning:not(.bp4-disabled).bp4-icon > svg {
  fill: rgba(28, 33, 39, 0.7);
}
.bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined):hover {
  background: #ec9a3c;
  color: #1c2127;
}
.bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined):active, .bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined).bp4-active {
  background: #c87619;
  color: #1c2127;
}
.bp4-button.bp4-intent-warning:disabled, .bp4-button.bp4-intent-warning.bp4-disabled {
  background: rgba(200, 118, 25, 0.5);
  color: rgba(28, 33, 39, 0.35);
}
.bp4-dark .bp4-button.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-intent-warning.bp4-disabled {
  color: rgba(28, 33, 39, 0.6);
}
.bp4-button.bp4-intent-warning.bp4-minimal, .bp4-button.bp4-intent-warning.bp4-outlined {
  background: none;
}
.bp4-dark .bp4-button.bp4-intent-warning.bp4-minimal:not(.bp4-disabled).bp4-icon > svg, .bp4-dark .bp4-button.bp4-intent-warning.bp4-outlined:not(.bp4-disabled).bp4-icon > svg {
  fill: #fbb360;
}

a.bp4-button {
  text-align: center;
  text-decoration: none;
  transition: none;
}
a.bp4-button, a.bp4-button:hover, a.bp4-button:active {
  color: #1c2127;
}
a.bp4-button.bp4-disabled {
  color: rgba(95, 107, 124, 0.6);
}

.bp4-button-text {
  flex: 0 1 auto;
}

.bp4-button.bp4-align-left .bp4-button-text, .bp4-button.bp4-align-right .bp4-button-text,
.bp4-button-group.bp4-align-left .bp4-button-text,
.bp4-button-group.bp4-align-right .bp4-button-text {
  flex: 1 1 auto;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Button groups

Markup:
<div class="bp4-button-group {{.modifier}}">
  <button type="button" class="bp4-button bp4-intent-success">Save</button>
  <button type="button" class="bp4-button bp4-intent-success bp4-icon-caret-down"></button>
</div>
<div class="bp4-button-group {{.modifier}}">
  <a class="bp4-button bp4-icon-database" tabindex="0" role="button">Queries</a>
  <a class="bp4-button bp4-icon-function" tabindex="0" role="button">Functions</a>
  <a class="bp4-button" tabindex="0" role="button">
    Options <span class="bp4-icon-standard bp4-icon-caret-down bp4-align-right"></span>
  </a>
</div>
<div class="bp4-button-group {{.modifier}}">
  <a class="bp4-button bp4-icon-chart" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-control" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-graph" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-camera" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-map" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-code" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-th" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-time" tabindex="0" role="button"></a>
  <a class="bp4-button bp4-icon-compressed" tabindex="0" role="button"></a>
</div>

.bp4-fill - Buttons expand equally to fill container
.bp4-large - Use large buttons
.bp4-minimal - Use minimal buttons
.bp4-vertical - Vertical layout

Styleguide button-group
*/
.bp4-button-group {
  display: inline-flex;
  /*
  Responsive

  Markup:
  <div class="bp4-button-group bp4-large bp4-fill">
    <a class="bp4-button bp4-intent-primary bp4-fixed" tabindex="0" role="button">Start</a>
    <a class="bp4-button bp4-intent-primary" tabindex="0" role="button">Left</a>
    <a class="bp4-button bp4-intent-primary bp4-active" tabindex="0" role="button">Middle</a>
    <a class="bp4-button bp4-intent-primary" tabindex="0" role="button">Right</a>
    <a class="bp4-button bp4-intent-primary bp4-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="bp4-button-group bp4-fill">
    <button class="bp4-button bp4-icon-arrow-left"></button>
    <button class="bp4-button bp4-fill">Middle</button>
    <button class="bp4-button bp4-icon-arrow-right"></button>
  </div>

  Styleguide button-group}-fill
  */
  /*
  Vertical button groups

  Markup:
  <div class="bp4-button-group bp4-vertical">
    <a class="bp4-button bp4-icon-search-template" role="button" tabindex="0"></a>
    <a class="bp4-button bp4-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="bp4-button bp4-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="bp4-button bp4-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="bp4-button-group bp4-vertical">
    <button type="button" class="bp4-button bp4-active">Home</button>
    <button type="button" class="bp4-button">Pages</button>
    <button type="button" class="bp4-button">Blog</button>
    <button type="button" class="bp4-button">Calendar</button>
  </div>
  <div class="bp4-button-group bp4-vertical bp4-align-left bp4-large">
    <button type="button" class="bp4-button bp4-intent-primary bp4-icon-document">Text</button>
    <button type="button" class="bp4-button bp4-intent-primary bp4-icon-media bp4-active">Media</button>
    <button type="button" class="bp4-button bp4-intent-primary bp4-icon-link">Sources</button>
  </div>

  Styleguide button-group-vertical
  */
}
.bp4-button-group .bp4-button {
  flex: 0 0 auto;
  position: relative;
  z-index: 4;
}
.bp4-button-group .bp4-button:focus {
  z-index: 5;
}
.bp4-button-group .bp4-button:hover {
  z-index: 6;
}
.bp4-button-group .bp4-button:active, .bp4-button-group .bp4-button.bp4-active {
  z-index: 7;
}
.bp4-button-group .bp4-button:disabled, .bp4-button-group .bp4-button.bp4-disabled {
  z-index: 3;
}
.bp4-button-group .bp4-button[class*=bp4-intent-] {
  z-index: 9;
}
.bp4-button-group .bp4-button[class*=bp4-intent-]:focus {
  z-index: 10;
}
.bp4-button-group .bp4-button[class*=bp4-intent-]:hover {
  z-index: 11;
}
.bp4-button-group .bp4-button[class*=bp4-intent-]:active, .bp4-button-group .bp4-button[class*=bp4-intent-].bp4-active {
  z-index: 12;
}
.bp4-button-group .bp4-button[class*=bp4-intent-]:disabled, .bp4-button-group .bp4-button[class*=bp4-intent-].bp4-disabled {
  z-index: 8;
}
.bp4-button-group:not(.bp4-minimal) > .bp4-popover-wrapper:not(:first-child) .bp4-button,
.bp4-button-group:not(.bp4-minimal) > .bp4-button:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp4-button-group:not(.bp4-minimal) > .bp4-popover-wrapper:not(:last-child) .bp4-button,
.bp4-button-group:not(.bp4-minimal) > .bp4-button:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -1px;
}
.bp4-button-group.bp4-minimal .bp4-button {
  background: none;
  box-shadow: none;
}
.bp4-button-group.bp4-minimal .bp4-button:hover {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  color: #1c2127;
  text-decoration: none;
}
.bp4-button-group.bp4-minimal .bp4-button:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: none;
  color: #1c2127;
}
.bp4-button-group.bp4-minimal .bp4-button:disabled, .bp4-button-group.bp4-minimal .bp4-button:disabled:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-button-group.bp4-minimal .bp4-button:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button:disabled:hover.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:hover, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:hover {
  background: rgba(143, 153, 168, 0.15);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled:hover, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover {
  background: none;
  color: rgba(171, 179, 191, 0.6);
  cursor: not-allowed;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled:hover.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary {
  color: #215db0;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  background: none;
  box-shadow: none;
  color: #215db0;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.15);
  color: #215db0;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #184a90;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(33, 93, 176, 0.5);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(138, 187, 255, 0.5);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success {
  color: #1c6e42;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  background: none;
  box-shadow: none;
  color: #1c6e42;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.15);
  color: #1c6e42;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #165a36;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(28, 110, 66, 0.5);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(114, 202, 155, 0.5);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning {
  color: #935610;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  background: none;
  box-shadow: none;
  color: #935610;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.15);
  color: #935610;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #77450d;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(147, 86, 16, 0.5);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(251, 179, 96, 0.5);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  background: none;
  box-shadow: none;
  color: #ac2f33;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.15);
  color: #ac2f33;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #8e292c;
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(172, 47, 51, 0.5);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(250, 153, 156, 0.5);
}
.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-button-group .bp4-popover-wrapper,
.bp4-button-group .bp4-popover-target {
  display: flex;
  flex: 1 1 auto;
}
.bp4-button-group.bp4-fill {
  display: flex;
  width: 100%;
}
.bp4-button-group .bp4-button.bp4-fill, .bp4-button-group.bp4-fill .bp4-button:not(.bp4-fixed) {
  flex: 1 1 auto;
}
.bp4-button-group.bp4-vertical {
  align-items: stretch;
  flex-direction: column;
  vertical-align: top;
}
.bp4-button-group.bp4-vertical.bp4-fill {
  height: 100%;
  width: unset;
}
.bp4-button-group.bp4-vertical .bp4-button {
  margin-right: 0 !important;
  /* stylelint-disable-line declaration-no-important */
  width: 100%;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:first-child .bp4-button,
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:first-child {
  border-radius: 2px 2px 0 0;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:last-child .bp4-button,
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:last-child {
  border-radius: 0 0 2px 2px;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:not(:last-child) .bp4-button,
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:not(:last-child) {
  margin-bottom: -1px;
}
.bp4-button-group.bp4-align-left .bp4-button {
  text-align: left;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Callout

Markup:
<div class="bp4-callout {{.modifier}}">
  <h4 class="bp4-heading">Callout Heading</h4>
  It's dangerous to go alone! Take this.
</div>

.bp4-intent-primary - Primary intent
.bp4-intent-success - Success intent
.bp4-intent-warning - Warning intent
.bp4-intent-danger  - Danger intent
.bp4-icon-info-sign - With an icon

Styleguide callout
*/
.bp4-callout {
  font-size: 14px;
  line-height: 1.5;
  background-color: rgba(143, 153, 168, 0.15);
  border-radius: 2px;
  padding: 10px 12px 9px;
  position: relative;
  width: 100%;
}
.bp4-callout[class*=bp4-icon-] {
  padding-left: 40px;
}
.bp4-callout[class*=bp4-icon-]::before {
  font-family: "blueprint-icons-20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
  left: 10px;
  position: absolute;
  top: 10px;
}
.bp4-callout.bp4-callout-icon {
  padding-left: 40px;
}
.bp4-callout.bp4-callout-icon > .bp4-icon:first-child {
  color: #5f6b7c;
  left: 10px;
  position: absolute;
  top: 10px;
}
.bp4-callout .bp4-heading {
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 0;
}
.bp4-callout .bp4-heading:last-child {
  margin-bottom: 0;
}
.bp4-dark .bp4-callout {
  background-color: rgba(143, 153, 168, 0.2);
}
.bp4-dark .bp4-callout[class*=bp4-icon-]::before, .bp4-dark .bp4-callout.bp4-callout-icon > .bp4-icon:first-child {
  color: #abb3bf;
}
.bp4-callout.bp4-intent-primary {
  background-color: rgba(45, 114, 210, 0.1);
}
.bp4-callout.bp4-intent-primary[class*=bp4-icon-]::before,
.bp4-callout.bp4-intent-primary > .bp4-icon:first-child,
.bp4-callout.bp4-intent-primary .bp4-heading {
  color: #215db0;
}
.bp4-dark .bp4-callout.bp4-intent-primary {
  background-color: rgba(45, 114, 210, 0.2);
}
.bp4-dark .bp4-callout.bp4-intent-primary[class*=bp4-icon-]::before,
.bp4-dark .bp4-callout.bp4-intent-primary > .bp4-icon:first-child,
.bp4-dark .bp4-callout.bp4-intent-primary .bp4-heading {
  color: #8abbff;
}
.bp4-callout.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
}
.bp4-callout.bp4-intent-success[class*=bp4-icon-]::before,
.bp4-callout.bp4-intent-success > .bp4-icon:first-child,
.bp4-callout.bp4-intent-success .bp4-heading {
  color: #1c6e42;
}
.bp4-dark .bp4-callout.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
}
.bp4-dark .bp4-callout.bp4-intent-success[class*=bp4-icon-]::before,
.bp4-dark .bp4-callout.bp4-intent-success > .bp4-icon:first-child,
.bp4-dark .bp4-callout.bp4-intent-success .bp4-heading {
  color: #72ca9b;
}
.bp4-callout.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
}
.bp4-callout.bp4-intent-warning[class*=bp4-icon-]::before,
.bp4-callout.bp4-intent-warning > .bp4-icon:first-child,
.bp4-callout.bp4-intent-warning .bp4-heading {
  color: #935610;
}
.bp4-dark .bp4-callout.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
}
.bp4-dark .bp4-callout.bp4-intent-warning[class*=bp4-icon-]::before,
.bp4-dark .bp4-callout.bp4-intent-warning > .bp4-icon:first-child,
.bp4-dark .bp4-callout.bp4-intent-warning .bp4-heading {
  color: #fbb360;
}
.bp4-callout.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
}
.bp4-callout.bp4-intent-danger[class*=bp4-icon-]::before,
.bp4-callout.bp4-intent-danger > .bp4-icon:first-child,
.bp4-callout.bp4-intent-danger .bp4-heading {
  color: #ac2f33;
}
.bp4-dark .bp4-callout.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
}
.bp4-dark .bp4-callout.bp4-intent-danger[class*=bp4-icon-]::before,
.bp4-dark .bp4-callout.bp4-intent-danger > .bp4-icon:first-child,
.bp4-dark .bp4-callout.bp4-intent-danger .bp4-heading {
  color: #fa999c;
}
.bp4-running-text .bp4-callout {
  margin: 20px 0;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Cards

Markup:
<div class="bp4-card {{.modifier}}">
  We build products that make people better at their most important work.
</div>

.bp4-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.bp4-elevation-1 - First. Subtle drop shadow intended for static containers.
.bp4-elevation-2 - Second. An even stronger shadow, moving on up.
.bp4-elevation-3 - Third. For containers overlaying content temporarily.
.bp4-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.
.bp4-interactive - On hover, increase elevation and use pointer cursor.

Styleguide card
*/
.bp4-card {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.15);
  padding: 20px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-card.bp4-dark, .bp4-dark .bp4-card {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.bp4-elevation-0 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp4-elevation-0.bp4-dark, .bp4-dark .bp4-elevation-0 {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.bp4-elevation-1 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-elevation-1.bp4-dark, .bp4-dark .bp4-elevation-1 {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}

.bp4-elevation-2 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2), 0 2px 6px rgba(17, 20, 24, 0.2);
}
.bp4-elevation-2.bp4-dark, .bp4-dark .bp4-elevation-2 {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px rgba(17, 20, 24, 0.4), 0 2px 6px rgba(17, 20, 24, 0.4);
}

.bp4-elevation-3 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp4-elevation-3.bp4-dark, .bp4-dark .bp4-elevation-3 {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}

.bp4-elevation-4 {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
}
.bp4-elevation-4.bp4-dark, .bp4-dark .bp4-elevation-4 {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);
}

.bp4-card.bp4-interactive:hover {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  cursor: pointer;
}
.bp4-card.bp4-interactive:hover.bp4-dark, .bp4-dark .bp4-card.bp4-interactive:hover {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-card.bp4-interactive:active {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  opacity: 0.9;
  transition-duration: 0;
}
.bp4-card.bp4-interactive:active.bp4-dark, .bp4-dark .bp4-card.bp4-interactive:active {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-collapse {
  height: 0;
  overflow-y: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-collapse .bp4-collapse-body {
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-collapse .bp4-collapse-body[aria-hidden=true] {
  display: none;
}

.bp4-context-menu .bp4-popover-target {
  display: block;
}

.bp4-context-menu-popover-target {
  position: fixed;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-divider {
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  border-right: 1px solid rgba(17, 20, 24, 0.15);
  margin: 5px;
}
.bp4-dark .bp4-divider {
  border-color: rgba(255, 255, 255, 0.2);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .bp4-dialog within it -->
<div class="bp4-dialog-container">
  <div class="bp4-dialog">
    <div class="bp4-dialog-header">
      <span class="bp4-icon-large bp4-icon-inbox"></span>
      <h5 class="bp4-heading">Dialog header</h5>
      <button aria-label="Close" class="bp4-dialog-close-button bp4-button bp4-minimal bp4-icon-cross"></button>
    </div>
    <div class="bp4-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="bp4-dialog-footer">
      <div class="bp4-dialog-footer-actions">
        <button type="button" class="bp4-button">Secondary button</button>
        <button type="submit" class="bp4-button bp4-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide dialog
*/
.bp4-dialog-container {
  opacity: 1;
  transform: scale(1);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  pointer-events: none;
  user-select: none;
  width: 100%;
}
.bp4-dialog-container.bp4-overlay-enter > .bp4-dialog, .bp4-dialog-container.bp4-overlay-appear > .bp4-dialog {
  opacity: 0;
  transform: scale(0.5);
}
.bp4-dialog-container.bp4-overlay-enter-active > .bp4-dialog, .bp4-dialog-container.bp4-overlay-appear-active > .bp4-dialog {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: opacity, transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-dialog-container.bp4-overlay-exit > .bp4-dialog {
  opacity: 1;
  transform: scale(1);
}
.bp4-dialog-container.bp4-overlay-exit-active > .bp4-dialog {
  opacity: 0;
  transform: scale(0.5);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: opacity, transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}

.bp4-dialog {
  background: #edeff2;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  pointer-events: all;
  user-select: text;
  width: 500px;
}
.bp4-dialog:focus {
  outline: 0;
}
.bp4-dialog.bp4-dark, .bp4-dark .bp4-dialog {
  background: #252a31;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color: #f6f7f9;
}

.bp4-dialog-header {
  align-items: center;
  background: #fff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 0 rgba(17, 20, 24, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 35px;
  padding-left: 15px;
  padding-right: 5px;
  z-index: 0;
}
.bp4-dialog-header .bp4-icon-large,
.bp4-dialog-header .bp4-icon {
  color: #5f6b7c;
  flex: 0 0 auto;
  margin-left: -3px;
  margin-right: 7.5px;
}
.bp4-dialog-header .bp4-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  line-height: inherit;
  margin: 10px 0;
}
.bp4-dialog-header .bp4-heading:last-child {
  margin-right: 15px;
}
.bp4-dark .bp4-dialog-header {
  background: #2f343c;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.bp4-dark .bp4-dialog-header .bp4-icon-large,
.bp4-dark .bp4-dialog-header .bp4-icon {
  color: #abb3bf;
}

.bp4-dialog-body {
  flex: 1 1 auto;
  line-height: 18px;
  margin: 15px;
}

.bp4-dialog-footer {
  flex: 0 0 auto;
  margin: 0 15px;
}

.bp4-dialog-footer-actions {
  display: flex;
  justify-content: flex-end;
}
.bp4-dialog-footer-actions .bp4-button {
  margin-left: 10px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-multistep-dialog-panels {
  display: flex;
}
.bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 4px 0 0 0;
}
.bp4-multistep-dialog-panels:first-child .bp4-multistep-dialog-right-panel {
  border-top-right-radius: 4px;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels {
  flex-direction: column;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 4px 0 0 0;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:last-child {
  border-radius: 0 4px 0 0;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-left-panel {
  flex-direction: row;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container {
  flex-grow: 1;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container:not(:first-child) {
  border-left: 1px solid rgba(17, 20, 24, 0.15);
}
.bp4-dark .bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container {
  border-color: rgba(17, 20, 24, 0.4);
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-left: none;
}
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel,
.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-footer {
  border-radius: 0 0 4px 4px;
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels {
  flex-direction: row-reverse;
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels:first-child .bp4-multistep-dialog-right-panel {
  border-radius: 4px 0 0 4px;
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 0 4px 0 0;
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-left-panel {
  border-radius: 0 0 4px 0;
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-left: none;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid rgba(17, 20, 24, 0.15);
}
.bp4-dark .bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-color: rgba(17, 20, 24, 0.4);
}
.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-footer {
  border-radius: 0 0 0 4px;
}

.bp4-multistep-dialog-left-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.bp4-dark .bp4-multistep-dialog-left-panel {
  background: #252a31;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom-left-radius: 4px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.bp4-multistep-dialog-right-panel {
  background-color: #f6f7f9;
  border-left: 1px solid rgba(17, 20, 24, 0.15);
  border-radius: 0 0 4px 0;
  flex: 3;
  min-width: 0;
}
.bp4-dark .bp4-multistep-dialog-right-panel {
  background-color: #2f343c;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom-right-radius: 4px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.bp4-multistep-dialog-footer {
  background-color: #fff;
  border-radius: 0 0 4px 0;
  border-top: 1px solid rgba(17, 20, 24, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.bp4-dark .bp4-multistep-dialog-footer {
  background: #383e47;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.bp4-multistep-dialog-footer .bp4-dialog-footer-actions {
  flex-grow: 1;
}

.bp4-dialog-step-container {
  background-color: #f6f7f9;
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
}
.bp4-dark .bp4-dialog-step-container {
  background: #2f343c;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.bp4-dialog-step-container.bp4-dialog-step-viewed {
  background-color: #fff;
}
.bp4-dark .bp4-dialog-step-container.bp4-dialog-step-viewed {
  background: #383e47;
}

.bp4-dialog-step {
  align-items: center;
  background-color: #f6f7f9;
  border-radius: 4px;
  cursor: not-allowed;
  display: flex;
  margin: 4px;
  padding: 6px 14px;
}
.bp4-dark .bp4-dialog-step {
  background: #2f343c;
}
.bp4-dialog-step-viewed .bp4-dialog-step {
  background-color: #fff;
  cursor: pointer;
}
.bp4-dark .bp4-dialog-step-viewed .bp4-dialog-step {
  background: #383e47;
}
.bp4-dialog-step:hover {
  background-color: #f6f7f9;
}
.bp4-dark .bp4-dialog-step:hover {
  background: #2f343c;
}

.bp4-dialog-step-icon {
  align-items: center;
  background-color: rgba(95, 107, 124, 0.6);
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
}
.bp4-dark .bp4-dialog-step-icon {
  background-color: rgba(171, 179, 191, 0.6);
}
.bp4-active.bp4-dialog-step-viewed .bp4-dialog-step-icon {
  background-color: #4c90f0;
}
.bp4-dialog-step-viewed .bp4-dialog-step-icon {
  background-color: #8f99a8;
}

.bp4-dialog-step-title {
  color: rgba(95, 107, 124, 0.6);
  flex: 1;
  padding-left: 10px;
}
.bp4-dark .bp4-dialog-step-title {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-active.bp4-dialog-step-viewed .bp4-dialog-step-title {
  color: #4c90f0;
}
.bp4-dialog-step-viewed:not(.bp4-active) .bp4-dialog-step-title {
  color: #1c2127;
}
.bp4-dark .bp4-dialog-step-viewed:not(.bp4-active) .bp4-dialog-step-title {
  color: #f6f7f9;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-drawer {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.bp4-drawer:focus {
  outline: 0;
}
.bp4-drawer.bp4-position-top {
  height: 50%;
  left: 0;
  right: 0;
  top: 0;
}
.bp4-drawer.bp4-position-top.bp4-overlay-enter, .bp4-drawer.bp4-position-top.bp4-overlay-appear {
  transform: translateY(-100%);
}
.bp4-drawer.bp4-position-top.bp4-overlay-enter-active, .bp4-drawer.bp4-position-top.bp4-overlay-appear-active {
  transform: translateY(0);
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-top.bp4-overlay-exit {
  transform: translateY(0);
}
.bp4-drawer.bp4-position-top.bp4-overlay-exit-active {
  transform: translateY(-100%);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-bottom {
  bottom: 0;
  height: 50%;
  left: 0;
  right: 0;
}
.bp4-drawer.bp4-position-bottom.bp4-overlay-enter, .bp4-drawer.bp4-position-bottom.bp4-overlay-appear {
  transform: translateY(100%);
}
.bp4-drawer.bp4-position-bottom.bp4-overlay-enter-active, .bp4-drawer.bp4-position-bottom.bp4-overlay-appear-active {
  transform: translateY(0);
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-bottom.bp4-overlay-exit {
  transform: translateY(0);
}
.bp4-drawer.bp4-position-bottom.bp4-overlay-exit-active {
  transform: translateY(100%);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-left {
  bottom: 0;
  left: 0;
  top: 0;
  width: 50%;
}
.bp4-drawer.bp4-position-left.bp4-overlay-enter, .bp4-drawer.bp4-position-left.bp4-overlay-appear {
  transform: translateX(-100%);
}
.bp4-drawer.bp4-position-left.bp4-overlay-enter-active, .bp4-drawer.bp4-position-left.bp4-overlay-appear-active {
  transform: translateX(0);
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-left.bp4-overlay-exit {
  transform: translateX(0);
}
.bp4-drawer.bp4-position-left.bp4-overlay-exit-active {
  transform: translateX(-100%);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-right {
  bottom: 0;
  right: 0;
  top: 0;
  width: 50%;
}
.bp4-drawer.bp4-position-right.bp4-overlay-enter, .bp4-drawer.bp4-position-right.bp4-overlay-appear {
  transform: translateX(100%);
}
.bp4-drawer.bp4-position-right.bp4-overlay-enter-active, .bp4-drawer.bp4-position-right.bp4-overlay-appear-active {
  transform: translateX(0);
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-position-right.bp4-overlay-exit {
  transform: translateX(0);
}
.bp4-drawer.bp4-position-right.bp4-overlay-exit-active {
  transform: translateX(100%);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-drawer.bp4-dark, .bp4-dark .bp4-drawer {
  background: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color: #f6f7f9;
}

.bp4-drawer-header {
  align-items: center;
  border-radius: 0;
  box-shadow: 0 1px 0 rgba(17, 20, 24, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding: 5px;
  padding-left: 20px;
  position: relative;
}
.bp4-drawer-header .bp4-icon-large,
.bp4-drawer-header .bp4-icon {
  color: #5f6b7c;
  flex: 0 0 auto;
  margin-right: 10px;
}
.bp4-drawer-header .bp4-heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  line-height: inherit;
  margin: 0;
}
.bp4-drawer-header .bp4-heading:last-child {
  margin-right: 20px;
}
.bp4-dark .bp4-drawer-header {
  box-shadow: 0 1px 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-drawer-header .bp4-icon-large,
.bp4-dark .bp4-drawer-header .bp4-icon {
  color: #abb3bf;
}

.bp4-drawer-body {
  flex: 1 1 auto;
  line-height: 18px;
  overflow: auto;
}

.bp4-drawer-footer {
  box-shadow: inset 0 1px 0 rgba(17, 20, 24, 0.15);
  flex: 0 0 auto;
  padding: 10px 20px;
  position: relative;
}
.bp4-dark .bp4-drawer-footer {
  box-shadow: inset 0 1px 0 rgba(17, 20, 24, 0.4);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-editable-text {
  cursor: text;
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
}
.bp4-editable-text::before {
  bottom: -2px;
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  border-radius: 2px;
  content: "";
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-editable-text:hover::before {
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp4-editable-text.bp4-editable-text-editing::before {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-editable-text.bp4-intent-primary .bp4-editable-text-content,
.bp4-editable-text.bp4-intent-primary .bp4-editable-text-input,
.bp4-editable-text.bp4-intent-primary .bp4-editable-text-input::placeholder {
  color: #2d72d2;
}
.bp4-editable-text.bp4-intent-primary:hover::before {
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(45, 114, 210, 0.4);
}
.bp4-editable-text.bp4-intent-primary.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-editable-text.bp4-intent-success .bp4-editable-text-content,
.bp4-editable-text.bp4-intent-success .bp4-editable-text-input,
.bp4-editable-text.bp4-intent-success .bp4-editable-text-input::placeholder {
  color: #238551;
}
.bp4-editable-text.bp4-intent-success:hover::before {
  box-shadow: 0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px rgba(35, 133, 81, 0.4);
}
.bp4-editable-text.bp4-intent-success.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-editable-text.bp4-intent-warning .bp4-editable-text-content,
.bp4-editable-text.bp4-intent-warning .bp4-editable-text-input,
.bp4-editable-text.bp4-intent-warning .bp4-editable-text-input::placeholder {
  color: #c87619;
}
.bp4-editable-text.bp4-intent-warning:hover::before {
  box-shadow: 0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px rgba(200, 118, 25, 0.4);
}
.bp4-editable-text.bp4-intent-warning.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-editable-text.bp4-intent-danger .bp4-editable-text-content,
.bp4-editable-text.bp4-intent-danger .bp4-editable-text-input,
.bp4-editable-text.bp4-intent-danger .bp4-editable-text-input::placeholder {
  color: #cd4246;
}
.bp4-editable-text.bp4-intent-danger:hover::before {
  box-shadow: 0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px rgba(205, 66, 70, 0.4);
}
.bp4-editable-text.bp4-intent-danger.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-editable-text:hover::before {
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}
.bp4-dark .bp4-editable-text.bp4-editable-text-editing::before {
  background-color: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-editable-text.bp4-disabled::before {
  box-shadow: none;
}
.bp4-dark .bp4-editable-text.bp4-intent-primary .bp4-editable-text-content,
.bp4-dark .bp4-editable-text.bp4-intent-primary .bp4-editable-text-input,
.bp4-dark .bp4-editable-text.bp4-intent-primary .bp4-editable-text-input::placeholder {
  color: #8abbff;
}
.bp4-dark .bp4-editable-text.bp4-intent-primary:hover::before {
  box-shadow: 0 0 0 0 rgba(138, 187, 255, 0), 0 0 0 0 rgba(138, 187, 255, 0), inset 0 0 0 1px rgba(138, 187, 255, 0.4);
}
.bp4-dark .bp4-editable-text.bp4-intent-primary.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #8abbff, 0 0 0 2px rgba(138, 187, 255, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-editable-text.bp4-intent-success .bp4-editable-text-content,
.bp4-dark .bp4-editable-text.bp4-intent-success .bp4-editable-text-input,
.bp4-dark .bp4-editable-text.bp4-intent-success .bp4-editable-text-input::placeholder {
  color: #72ca9b;
}
.bp4-dark .bp4-editable-text.bp4-intent-success:hover::before {
  box-shadow: 0 0 0 0 rgba(114, 202, 155, 0), 0 0 0 0 rgba(114, 202, 155, 0), inset 0 0 0 1px rgba(114, 202, 155, 0.4);
}
.bp4-dark .bp4-editable-text.bp4-intent-success.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #72ca9b, 0 0 0 2px rgba(114, 202, 155, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-editable-text.bp4-intent-warning .bp4-editable-text-content,
.bp4-dark .bp4-editable-text.bp4-intent-warning .bp4-editable-text-input,
.bp4-dark .bp4-editable-text.bp4-intent-warning .bp4-editable-text-input::placeholder {
  color: #fbb360;
}
.bp4-dark .bp4-editable-text.bp4-intent-warning:hover::before {
  box-shadow: 0 0 0 0 rgba(251, 179, 96, 0), 0 0 0 0 rgba(251, 179, 96, 0), inset 0 0 0 1px rgba(251, 179, 96, 0.4);
}
.bp4-dark .bp4-editable-text.bp4-intent-warning.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #fbb360, 0 0 0 2px rgba(251, 179, 96, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-editable-text.bp4-intent-danger .bp4-editable-text-content,
.bp4-dark .bp4-editable-text.bp4-intent-danger .bp4-editable-text-input,
.bp4-dark .bp4-editable-text.bp4-intent-danger .bp4-editable-text-input::placeholder {
  color: #fa999c;
}
.bp4-dark .bp4-editable-text.bp4-intent-danger:hover::before {
  box-shadow: 0 0 0 0 rgba(250, 153, 156, 0), 0 0 0 0 rgba(250, 153, 156, 0), inset 0 0 0 1px rgba(250, 153, 156, 0.4);
}
.bp4-dark .bp4-editable-text.bp4-intent-danger.bp4-editable-text-editing::before {
  box-shadow: inset 0 0 0 1px #fa999c, 0 0 0 2px rgba(250, 153, 156, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-editable-text.bp4-disabled::before {
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
}

.bp4-editable-text-input,
.bp4-editable-text-content {
  color: inherit;
  display: inherit;
  font: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  min-width: inherit;
  position: relative;
  resize: none;
  text-transform: inherit;
  vertical-align: top;
}

.bp4-editable-text-input {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  white-space: pre-wrap;
  width: 100%;
}
.bp4-editable-text-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}
.bp4-editable-text-input:focus {
  outline: none;
}
.bp4-editable-text-input::-ms-clear {
  display: none;
}

.bp4-editable-text-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre;
}
.bp4-editable-text-editing > .bp4-editable-text-content {
  left: 0;
  position: absolute;
  visibility: hidden;
}
.bp4-editable-text-placeholder > .bp4-editable-text-content {
  color: #5f6b7c;
}
.bp4-dark .bp4-editable-text-placeholder > .bp4-editable-text-content {
  color: #abb3bf;
}

.bp4-editable-text.bp4-multiline {
  display: block;
}
.bp4-editable-text.bp4-multiline .bp4-editable-text-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-divider {
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  border-right: 1px solid rgba(17, 20, 24, 0.15);
  margin: 5px;
}
.bp4-dark .bp4-divider {
  border-color: rgba(255, 255, 255, 0.2);
}

/*
Control groups

Markup:
<div class="bp4-control-group">
  <button class="bp4-button bp4-icon-filter">Filter</button>
  <input type="text" class="bp4-input" placeholder="Find filters..." />
</div>
<div class="bp4-control-group">
  <div class="bp4-select">
    <select>
      <option selected>Filter...</option>
      <option value="1">Issues</option>
      <option value="2">Requests</option>
      <option value="3">Projects</option>
    </select>
  </div>
  <div class="bp4-input-group">
    <span class="bp4-icon bp4-icon-search"></span>
    <input type="text" class="bp4-input" value="from:ggray to:allorca" />
  </div>
</div>
<div class="bp4-control-group">
  <div class="bp4-input-group">
    <span class="bp4-icon bp4-icon-people"></span>
    <input type="text" class="bp4-input" placeholder="Find collaborators..." style="padding-right:94px" />
    <div class="bp4-input-action">
      <button class="bp4-button bp4-minimal bp4-intent-primary">
        can view<span class="bp4-icon-standard bp4-icon-caret-down bp4-align-right"></span>
      </button>
    </div>
  </div>
  <button class="bp4-button bp4-intent-primary">Add</button>
</div>
<div class="bp4-control-group">
  <div class="bp4-select">
    <select>
      <option selected value="dollar">$</option>
      <option value="euro">€</option>
    </select>
  </div>
  <div class="bp4-control-group bp4-numeric-input">
    <div class="bp4-input-group">
      <input type="text" autocomplete="off" class="bp4-input" style="padding-right: 10px;" value="1">
    </div>
    <div class="bp4-button-group bp4-vertical bp4-fixed">
      <button type="button" class="bp4-button">
        <span icon="chevron-up" class="bp4-icon bp4-icon-chevron-up">
          <svg data-icon="chevron-up" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-up</desc><path d="M12.71 9.29l-4-4C8.53 5.11 8.28 5 8 5s-.53.11-.71.29l-4 4a1.003 1.003 0 001.42 1.42L8 7.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
      <button type="button" class="bp4-button">
        <span icon="chevron-down" class="bp4-icon bp4-icon-chevron-down">
          <svg data-icon="chevron-down" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-down</desc><path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
    </div>
  </div>
</div>

Styleguide control-group
*/
.bp4-control-group {
  transform: translateZ(0);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*
  Responsive control groups

  Markup:
  <div class="bp4-control-group">
    <div class="bp4-input-group bp4-fill">
      <span class="bp4-icon bp4-icon-people"></span>
      <input type="text" class="bp4-input" placeholder="Find collaborators..." />
    </div>
    <button class="bp4-button bp4-intent-primary">Add</button>
  </div>
  <div class="bp4-control-group bp4-fill">
    <button class="bp4-button bp4-icon-minus bp4-fixed"></button>
    <input type="text" class="bp4-input" placeholder="Enter a value..." />
    <button class="bp4-button bp4-icon-plus bp4-fixed"></button>
  </div>

  Styleguide control-group-fill
  */
  /*
  Vertical control groups

  Markup:
  <div class="bp4-control-group bp4-vertical" style="width: 300px;">
    <div class="bp4-input-group bp4-large">
      <span class="bp4-icon bp4-icon-person"></span>
      <input type="text" class="bp4-input" placeholder="Username" />
    </div>
    <div class="bp4-input-group bp4-large">
      <span class="bp4-icon bp4-icon-lock"></span>
      <input type="password" class="bp4-input" placeholder="Password" />
    </div>
    <button class="bp4-button bp4-large bp4-intent-primary">Login</button>
  </div>

  Styleguide control-group-vertical
  */
}
.bp4-control-group > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-control-group > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-control-group .bp4-button,
.bp4-control-group .bp4-html-select,
.bp4-control-group .bp4-input,
.bp4-control-group .bp4-select {
  position: relative;
}
.bp4-control-group .bp4-input {
  z-index: 2;
}
.bp4-control-group .bp4-input:focus {
  z-index: 14;
}
.bp4-control-group .bp4-input[class*=bp4-intent] {
  z-index: 13;
}
.bp4-control-group .bp4-input[class*=bp4-intent]:focus {
  z-index: 15;
}
.bp4-control-group .bp4-input[readonly], .bp4-control-group .bp4-input:disabled, .bp4-control-group .bp4-input.bp4-disabled {
  z-index: 1;
}
.bp4-control-group .bp4-input-group[class*=bp4-intent] .bp4-input {
  z-index: 13;
}
.bp4-control-group .bp4-input-group[class*=bp4-intent] .bp4-input:focus {
  z-index: 15;
}
.bp4-control-group .bp4-button,
.bp4-control-group .bp4-html-select select,
.bp4-control-group .bp4-select select {
  transform: translateZ(0);
  z-index: 4;
}
.bp4-control-group .bp4-button:focus,
.bp4-control-group .bp4-html-select select:focus,
.bp4-control-group .bp4-select select:focus {
  z-index: 5;
}
.bp4-control-group .bp4-button:hover,
.bp4-control-group .bp4-html-select select:hover,
.bp4-control-group .bp4-select select:hover {
  z-index: 6;
}
.bp4-control-group .bp4-button:active,
.bp4-control-group .bp4-html-select select:active,
.bp4-control-group .bp4-select select:active {
  z-index: 7;
}
.bp4-control-group .bp4-button[readonly], .bp4-control-group .bp4-button:disabled, .bp4-control-group .bp4-button.bp4-disabled,
.bp4-control-group .bp4-html-select select[readonly],
.bp4-control-group .bp4-html-select select:disabled,
.bp4-control-group .bp4-html-select select.bp4-disabled,
.bp4-control-group .bp4-select select[readonly],
.bp4-control-group .bp4-select select:disabled,
.bp4-control-group .bp4-select select.bp4-disabled {
  z-index: 3;
}
.bp4-control-group .bp4-button[class*=bp4-intent],
.bp4-control-group .bp4-html-select select[class*=bp4-intent],
.bp4-control-group .bp4-select select[class*=bp4-intent] {
  z-index: 9;
}
.bp4-control-group .bp4-button[class*=bp4-intent]:focus,
.bp4-control-group .bp4-html-select select[class*=bp4-intent]:focus,
.bp4-control-group .bp4-select select[class*=bp4-intent]:focus {
  z-index: 10;
}
.bp4-control-group .bp4-button[class*=bp4-intent]:hover,
.bp4-control-group .bp4-html-select select[class*=bp4-intent]:hover,
.bp4-control-group .bp4-select select[class*=bp4-intent]:hover {
  z-index: 11;
}
.bp4-control-group .bp4-button[class*=bp4-intent]:active,
.bp4-control-group .bp4-html-select select[class*=bp4-intent]:active,
.bp4-control-group .bp4-select select[class*=bp4-intent]:active {
  z-index: 12;
}
.bp4-control-group .bp4-button[class*=bp4-intent][readonly], .bp4-control-group .bp4-button[class*=bp4-intent]:disabled, .bp4-control-group .bp4-button[class*=bp4-intent].bp4-disabled,
.bp4-control-group .bp4-html-select select[class*=bp4-intent][readonly],
.bp4-control-group .bp4-html-select select[class*=bp4-intent]:disabled,
.bp4-control-group .bp4-html-select select[class*=bp4-intent].bp4-disabled,
.bp4-control-group .bp4-select select[class*=bp4-intent][readonly],
.bp4-control-group .bp4-select select[class*=bp4-intent]:disabled,
.bp4-control-group .bp4-select select[class*=bp4-intent].bp4-disabled {
  z-index: 8;
}
.bp4-control-group .bp4-input-group > .bp4-icon,
.bp4-control-group .bp4-input-group > .bp4-button,
.bp4-control-group .bp4-input-group > .bp4-input-left-container,
.bp4-control-group .bp4-input-group > .bp4-input-action {
  z-index: 16;
}
.bp4-control-group .bp4-select::after,
.bp4-control-group .bp4-html-select::after,
.bp4-control-group .bp4-select > .bp4-icon,
.bp4-control-group .bp4-html-select > .bp4-icon {
  z-index: 17;
}
.bp4-control-group .bp4-select:focus-within {
  z-index: 5;
}
.bp4-control-group:not(.bp4-vertical) > :not(:last-child) {
  margin-right: 2px;
}
.bp4-control-group .bp4-numeric-input:not(:first-child) .bp4-input-group {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.bp4-control-group.bp4-fill {
  width: 100%;
}
.bp4-control-group > .bp4-fill {
  flex: 1 1 auto;
}
.bp4-control-group.bp4-fill > *:not(.bp4-fixed) {
  flex: 1 1 auto;
}
.bp4-control-group.bp4-vertical {
  flex-direction: column;
}
.bp4-control-group.bp4-vertical > :not(:last-child) {
  margin-bottom: 2px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-control {
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-transform: none;
  /*
  Checkbox

  Markup:
  <label class="bp4-control bp4-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp4-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.bp4-disabled</code> to <code>.bp4-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .bp4-align-right - Right-aligned indicator
  .bp4-large - Large

  Styleguide checkbox
  */
  /*
  Radio

  Markup:
  <label class="bp4-control bp4-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="bp4-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp4-disabled</code> to <code>.bp4-control</code> to change text color (not shown below).
  .bp4-align-right - Right-aligned indicator
  .bp4-large - Large

  Styleguide radio
  */
  /*
  Switch

  Markup:
  <label class="bp4-control bp4-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp4-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp4-disabled</code> to <code>.bp4-control</code> to change text color (not shown below).
  .bp4-align-right - Right-aligned indicator
  .bp4-large - Large

  Styleguide switch
  */
  /* stylelint-disable-next-line order/order */
  /*
  Inline labels

  Markup:
  <div>
    <label class="bp4-label">A group of related options</label>
    <label class="bp4-control bp4-checkbox bp4-inline">
      <input type="checkbox" />
      <span class="bp4-control-indicator"></span>
      First
    </label>
    <label class="bp4-control bp4-checkbox bp4-inline">
      <input type="checkbox" />
      <span class="bp4-control-indicator"></span>
      Second
    </label>
    <label class="bp4-control bp4-checkbox bp4-inline">
      <input type="checkbox" />
      <span class="bp4-control-indicator"></span>
      Third
    </label>
  </div>

  Styleguide checkbox-inline
  */
}
.bp4-control input:checked ~ .bp4-control-indicator {
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color: #fff;
}
.bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #215db0;
}
.bp4-control input:not(:disabled):active:checked ~ .bp4-control-indicator {
  background: #184a90;
}
.bp4-control input:disabled:checked ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-dark .bp4-control input:checked ~ .bp4-control-indicator {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control input:not(:disabled):active:checked ~ .bp4-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control input:disabled:checked ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-control:not(.bp4-align-right) {
  padding-left: 26px;
}
.bp4-control:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -26px;
}
.bp4-control.bp4-align-right {
  padding-right: 26px;
}
.bp4-control.bp4-align-right .bp4-control-indicator {
  margin-right: -26px;
}
.bp4-control.bp4-disabled {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-control.bp4-inline {
  display: inline-block;
  margin-right: 20px;
}
.bp4-control input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.bp4-control .bp4-control-indicator {
  background-clip: padding-box;
  background-color: transparent;
  border: none;
  box-shadow: inset 0 0 0 1px #738091;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 1em;
  margin-right: 10px;
  margin-top: -3px;
  position: relative;
  user-select: none;
  vertical-align: middle;
  width: 1em;
}
.bp4-control .bp4-control-indicator::before {
  content: "";
  display: block;
  height: 1em;
  width: 1em;
}
.bp4-control:hover .bp4-control-indicator {
  background-color: rgba(143, 153, 168, 0.15);
}
.bp4-control input:not(:disabled):active ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: inset 0 0 0 1px #738091;
}
.bp4-control input:disabled ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  cursor: not-allowed;
}
.bp4-control input:focus ~ .bp4-control-indicator {
  outline: rgba(45, 114, 210, 0.6) solid 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px;
  outline: #2d72d2 solid 2px;
}
.bp4-control.bp4-align-right .bp4-control-indicator {
  float: right;
  margin-left: 10px;
  margin-top: 1px;
}
.bp4-control.bp4-large {
  font-size: 16px;
}
.bp4-control.bp4-large:not(.bp4-align-right) {
  padding-left: 30px;
}
.bp4-control.bp4-large:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -30px;
}
.bp4-control.bp4-large.bp4-align-right {
  padding-right: 30px;
}
.bp4-control.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-right: -30px;
}
.bp4-control.bp4-large .bp4-control-indicator {
  font-size: 20px;
}
.bp4-control.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-top: 0;
}
.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color: #fff;
}
.bp4-control.bp4-checkbox:hover input:indeterminate ~ .bp4-control-indicator {
  background-color: #215db0;
}
.bp4-control.bp4-checkbox input:not(:disabled):active:indeterminate ~ .bp4-control-indicator {
  background: #184a90;
}
.bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-dark .bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control.bp4-checkbox:hover input:indeterminate ~ .bp4-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control.bp4-checkbox input:not(:disabled):active:indeterminate ~ .bp4-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}
.bp4-dark .bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
}
.bp4-control.bp4-checkbox .bp4-control-indicator {
  border-radius: 2px;
}
.bp4-control.bp4-checkbox input:checked ~ .bp4-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3e%3c/svg%3e");
}
.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e");
}
.bp4-control.bp4-radio .bp4-control-indicator {
  border-radius: 50%;
}
.bp4-control.bp4-radio input:checked ~ .bp4-control-indicator::before {
  background-image: radial-gradient(#fff, #fff 28%, transparent 32%);
}
.bp4-control.bp4-radio input:checked:disabled ~ .bp4-control-indicator::before {
  opacity: 0.5;
}
.bp4-control.bp4-radio input:focus ~ .bp4-control-indicator {
  -moz-outline-radius: 16px;
}
.bp4-control.bp4-switch input ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.3);
  color: #1c2127;
}
.bp4-control.bp4-switch:hover input ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.4);
}
.bp4-control.bp4-switch input:not(:disabled):active ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.5);
}
.bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.15);
  color: rgba(95, 107, 124, 0.6);
}
.bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator::before {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: none;
}
.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
  background: #2d72d2;
  color: #fff;
}
.bp4-control.bp4-switch:hover input:checked ~ .bp4-control-indicator {
  background: #215db0;
}
.bp4-control.bp4-switch input:checked:not(:disabled):active ~ .bp4-control-indicator {
  background: #184a90;
}
.bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  color: rgba(255, 255, 255, 0.6);
}
.bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator::before {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
.bp4-control.bp4-switch:not(.bp4-align-right) {
  padding-left: 38px;
}
.bp4-control.bp4-switch:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -38px;
}
.bp4-control.bp4-switch.bp4-align-right {
  padding-right: 38px;
}
.bp4-control.bp4-switch.bp4-align-right .bp4-control-indicator {
  margin-right: -38px;
}
.bp4-control.bp4-switch .bp4-control-indicator {
  border: none;
  border-radius: 1.75em;
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  min-width: 1.75em;
  transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width: auto;
}
.bp4-control.bp4-switch .bp4-control-indicator::before {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.5);
  height: calc(1em - 4px);
  left: 0;
  margin: 2px;
  position: absolute;
  transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width: calc(1em - 4px);
}
.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator::before {
  left: calc(100% - 1em);
}
.bp4-control.bp4-switch.bp4-large:not(.bp4-align-right) {
  padding-left: 45px;
}
.bp4-control.bp4-switch.bp4-large:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -45px;
}
.bp4-control.bp4-switch.bp4-large.bp4-align-right {
  padding-right: 45px;
}
.bp4-control.bp4-switch.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-right: -45px;
}
.bp4-dark .bp4-control.bp4-switch input ~ .bp4-control-indicator {
  background: rgba(17, 20, 24, 0.5);
  color: #f6f7f9;
}
.bp4-dark .bp4-control.bp4-switch:hover input ~ .bp4-control-indicator {
  background: rgba(17, 20, 24, 0.8);
}
.bp4-dark .bp4-control.bp4-switch input:not(:disabled):active ~ .bp4-control-indicator {
  background: rgba(17, 20, 24, 0.9);
}
.bp4-dark .bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.15);
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator::before {
  background: rgba(171, 179, 191, 0.5);
  box-shadow: none;
}
.bp4-dark .bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
  background: #2d72d2;
  color: #fff;
}
.bp4-dark .bp4-control.bp4-switch:hover input:checked ~ .bp4-control-indicator {
  background: #215db0;
}
.bp4-dark .bp4-control.bp4-switch input:checked:not(:disabled):active ~ .bp4-control-indicator {
  background: #184a90;
}
.bp4-dark .bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator::before {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: none;
}
.bp4-dark .bp4-control.bp4-switch .bp4-control-indicator::before {
  background: #abb3bf;
}
.bp4-dark .bp4-control.bp4-switch input:checked ~ .bp4-control-indicator::before {
  background: #fff;
}
.bp4-control.bp4-switch .bp4-switch-inner-text {
  font-size: 0.7em;
  text-align: center;
}
.bp4-control.bp4-switch .bp4-control-indicator-child:first-child {
  line-height: 0;
  margin-left: 0.5em;
  margin-right: 1.2em;
  visibility: hidden;
}
.bp4-control.bp4-switch .bp4-control-indicator-child:last-child {
  line-height: 1em;
  margin-left: 1.2em;
  margin-right: 0.5em;
  visibility: visible;
}
.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator .bp4-control-indicator-child:first-child {
  line-height: 1em;
  visibility: visible;
}
.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator .bp4-control-indicator-child:last-child {
  line-height: 0;
  visibility: hidden;
}
.bp4-dark .bp4-control {
  color: #f6f7f9;
}
.bp4-dark .bp4-control.bp4-disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-control .bp4-control-indicator {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #8f99a8;
}
.bp4-dark .bp4-control:hover .bp4-control-indicator {
  background-color: rgba(143, 153, 168, 0.15);
}
.bp4-dark .bp4-control input:focus ~ .bp4-control-indicator {
  outline: #8abbff solid 2px;
}
.bp4-dark .bp4-control input:not(:disabled):active ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: inset 0 0 0 1px #8f99a8;
}
.bp4-dark .bp4-control input:disabled ~ .bp4-control-indicator {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  cursor: not-allowed;
}
.bp4-dark .bp4-control.bp4-checkbox input:disabled:checked ~ .bp4-control-indicator, .bp4-dark .bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  background: rgba(45, 114, 210, 0.5);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
File input

Markup:
<label class="bp4-file-input {{.modifier}}">
  <input type="file" {{:modifier}}/>
  <span class="bp4-file-upload-input">Choose file...</span>
</label>

:disabled - Disabled
.bp4-large - Larger size
.bp4-fill - Take up full width of parent element
.bp4-file-input-has-selection - User has made a selection

Styleguide file-input
*/
.bp4-file-input {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
}
.bp4-file-input input {
  margin: 0;
  min-width: 200px;
  opacity: 0;
}
.bp4-file-input input:disabled + .bp4-file-upload-input, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input {
  background: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  resize: none;
}
.bp4-file-input input:disabled + .bp4-file-upload-input::placeholder, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input::placeholder {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-file-input input:disabled + .bp4-file-upload-input::after, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input::after {
  background-color: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  outline: none;
}
.bp4-dark .bp4-file-input input:disabled + .bp4-file-upload-input, .bp4-dark .bp4-file-input input.bp4-disabled + .bp4-file-upload-input {
  background: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-file-input input:disabled + .bp4-file-upload-input::after, .bp4-dark .bp4-file-input input.bp4-disabled + .bp4-file-upload-input::after {
  background-color: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-file-input.bp4-file-input-has-selection .bp4-file-upload-input {
  color: #1c2127;
}
.bp4-dark .bp4-file-input.bp4-file-input-has-selection .bp4-file-upload-input {
  color: #f6f7f9;
}
.bp4-file-input.bp4-fill {
  width: 100%;
}
.bp4-file-input.bp4-large, .bp4-large .bp4-file-input {
  height: 40px;
}
.bp4-file-input .bp4-file-upload-input-custom-text::after {
  content: attr(bp4-button-text);
}

.bp4-file-upload-input {
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
  color: #1c2127;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: rgba(95, 107, 124, 0.6);
  left: 0;
  padding-right: 80px;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
}
.bp4-file-upload-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}
.bp4-file-upload-input:focus, .bp4-file-upload-input.bp4-active {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-file-upload-input[type=search], .bp4-file-upload-input.bp4-round {
  border-radius: 30px;
  box-sizing: border-box;
  padding-left: 10px;
}
.bp4-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp4-file-upload-input:disabled, .bp4-file-upload-input.bp4-disabled {
  background: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  resize: none;
}
.bp4-file-upload-input:disabled::placeholder, .bp4-file-upload-input.bp4-disabled::placeholder {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-file-upload-input::after {
  background-color: #f6f7f9;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #1c2127;
  min-height: 24px;
  min-width: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  border-radius: 2px;
  content: "Browse";
  line-height: 24px;
  margin: 3px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
}
.bp4-file-upload-input:hover::after {
  background-clip: padding-box;
  background-color: #edeff2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-file-upload-input:active::after {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-large .bp4-file-upload-input {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding-right: 95px;
}
.bp4-large .bp4-file-upload-input[type=search], .bp4-large .bp4-file-upload-input.bp4-round {
  padding: 0 15px;
}
.bp4-large .bp4-file-upload-input::after {
  min-height: 30px;
  min-width: 30px;
  line-height: 30px;
  margin: 5px;
  width: 85px;
}
.bp4-dark .bp4-file-upload-input {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
  color: #f6f7f9;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-file-upload-input::placeholder {
  color: #abb3bf;
}
.bp4-dark .bp4-file-upload-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3);
}
.bp4-dark .bp4-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-file-upload-input:disabled, .bp4-dark .bp4-file-upload-input.bp4-disabled {
  background: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-file-upload-input::after {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color: #f6f7f9;
}
.bp4-dark .bp4-file-upload-input:hover::after {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-file-upload-input:active::after {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}

/* stylelint-disable-next-line no-duplicate-selectors */
.bp4-file-upload-input::after {
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Form groups

Markup:
<div class="bp4-form-group {{.modifier}}">
  <label class="bp4-label" for="form-group-input">
    Label
    <span class="bp4-text-muted">(required)</span>
  </label>
  <div class="bp4-form-content">
    <div class="bp4-input-group {{.modifier}}">
      <span class="bp4-icon bp4-icon-calendar"></span>
      <input id="form-group-input" type="text" {{:modifier}} class="bp4-input"
             style="width: 200px;" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="bp4-form-helper-text">Please enter a value</div>
  </div>
</div>

:disabled - Disable the input.
.bp4-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp4-inline - Label and content appear side by side.
.bp4-intent-primary - Apply intent to form group and input separately.

Styleguide form-group
*/
.bp4-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  /* stylelint-disable-next-line order/declaration-block-order */
}
.bp4-form-group label.bp4-label {
  margin-bottom: 5px;
}
.bp4-form-group .bp4-control {
  margin-top: 7px;
}
.bp4-form-group .bp4-form-group-sub-label,
.bp4-form-group .bp4-form-helper-text {
  color: #5f6b7c;
  font-size: 12px;
}
.bp4-form-group .bp4-form-group-sub-label {
  margin-bottom: 5px;
}
.bp4-form-group .bp4-form-helper-text {
  margin-top: 5px;
}
.bp4-form-group.bp4-intent-primary .bp4-form-group-sub-label,
.bp4-form-group.bp4-intent-primary .bp4-form-helper-text {
  color: #215db0;
}
.bp4-form-group.bp4-intent-success .bp4-form-group-sub-label,
.bp4-form-group.bp4-intent-success .bp4-form-helper-text {
  color: #1c6e42;
}
.bp4-form-group.bp4-intent-warning .bp4-form-group-sub-label,
.bp4-form-group.bp4-intent-warning .bp4-form-helper-text {
  color: #935610;
}
.bp4-form-group.bp4-intent-danger .bp4-form-group-sub-label,
.bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: #ac2f33;
}
.bp4-form-group.bp4-inline {
  align-items: flex-start;
  flex-direction: row;
}
.bp4-form-group.bp4-inline.bp4-large label.bp4-label {
  line-height: 40px;
  margin: 0 10px 0 0;
}
.bp4-form-group.bp4-inline label.bp4-label {
  line-height: 30px;
  margin: 0 10px 0 0;
}
.bp4-form-group.bp4-disabled .bp4-label,
.bp4-form-group.bp4-disabled .bp4-text-muted,
.bp4-form-group.bp4-disabled .bp4-form-group-sub-label,
.bp4-form-group.bp4-disabled .bp4-form-helper-text {
  /* stylelint-disable-next-line declaration-no-important */
  color: rgba(95, 107, 124, 0.6) !important;
}
.bp4-dark .bp4-form-group.bp4-intent-primary .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-intent-primary .bp4-form-helper-text {
  color: #8abbff;
}
.bp4-dark .bp4-form-group.bp4-intent-success .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-intent-success .bp4-form-helper-text {
  color: #72ca9b;
}
.bp4-dark .bp4-form-group.bp4-intent-warning .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-intent-warning .bp4-form-helper-text {
  color: #fbb360;
}
.bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: #fa999c;
}
.bp4-dark .bp4-form-group .bp4-form-helper-text {
  color: #abb3bf;
}
.bp4-dark .bp4-form-group.bp4-disabled .bp4-label,
.bp4-dark .bp4-form-group.bp4-disabled .bp4-text-muted,
.bp4-dark .bp4-form-group.bp4-disabled .bp4-form-group-sub-label,
.bp4-dark .bp4-form-group.bp4-disabled .bp4-form-helper-text {
  /* stylelint-disable-next-line declaration-no-important */
  color: rgba(171, 179, 191, 0.6) !important;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Input groups

Markup:
<div class="bp4-input-group {{.modifier}}">
  <span class="bp4-icon bp4-icon-filter"></span>
  <input type="text" class="bp4-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="bp4-input-group {{.modifier}}">
  <input type="password" class="bp4-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="bp4-button bp4-minimal bp4-intent-warning bp4-icon-lock" {{:modifier}}></button>
</div>
<div class="bp4-input-group {{.modifier}}">
  <span class="bp4-icon bp4-icon-search"></span>
  <input type="text" class="bp4-input" {{:modifier}} placeholder="Search" />
  <button class="bp4-button bp4-minimal bp4-intent-primary bp4-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.bp4-disabled</code> to <code>.bp4-input-group</code> for icon coloring (not shown below).
.bp4-round - Rounded caps. Button will also be rounded.
.bp4-large - Large group. Children will adjust size accordingly.
.bp4-small - Small group. Children will adjust size accordingly.
.bp4-intent-primary - Primary intent. (All 4 intents are supported.)
.bp4-fill - Take up full width of parent element.

Styleguide input-group
*/
.bp4-input-group {
  display: block;
  position: relative;
}
.bp4-input-group .bp4-input {
  position: relative;
  width: 100%;
}
.bp4-input-group .bp4-input:not(:first-child) {
  padding-left: 30px;
}
.bp4-input-group .bp4-input:not(:last-child) {
  padding-right: 30px;
}
.bp4-input-group .bp4-input-action,
.bp4-input-group > .bp4-input-left-container,
.bp4-input-group > .bp4-button,
.bp4-input-group > .bp4-icon {
  position: absolute;
  top: 0;
}
.bp4-input-group .bp4-input-action:first-child,
.bp4-input-group > .bp4-input-left-container:first-child,
.bp4-input-group > .bp4-button:first-child,
.bp4-input-group > .bp4-icon:first-child {
  left: 0;
}
.bp4-input-group .bp4-input-action:last-child,
.bp4-input-group > .bp4-input-left-container:last-child,
.bp4-input-group > .bp4-button:last-child,
.bp4-input-group > .bp4-icon:last-child {
  right: 0;
}
.bp4-input-group .bp4-button {
  min-height: 24px;
  min-width: 24px;
  margin: 3px;
  padding: 0 7px;
}
.bp4-input-group .bp4-button:empty {
  padding: 0;
}
.bp4-input-group > .bp4-input-left-container,
.bp4-input-group > .bp4-icon {
  z-index: 1;
}
.bp4-input-group > .bp4-input-left-container > .bp4-icon,
.bp4-input-group > .bp4-icon {
  color: #5f6b7c;
}
.bp4-input-group > .bp4-input-left-container > .bp4-icon:empty,
.bp4-input-group > .bp4-icon:empty {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.bp4-input-group > .bp4-input-left-container > .bp4-icon,
.bp4-input-group > .bp4-icon,
.bp4-input-group .bp4-input-action > .bp4-spinner {
  margin: 7px;
}
.bp4-input-group .bp4-tag {
  margin: 5px;
}
.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus),
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) {
  color: #5f6b7c;
  /* stylelint-disable-next-line selector-max-compound-selectors */
}
.bp4-dark .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus),
.bp4-dark .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) {
  color: #abb3bf;
}
.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-large,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-standard,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-large {
  color: #5f6b7c;
}
.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled {
  /* stylelint-disable declaration-no-important */
  color: rgba(95, 107, 124, 0.6) !important;
}
.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon-large,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon-standard,
.bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon-large {
  color: rgba(95, 107, 124, 0.6) !important;
}
.bp4-input-group.bp4-disabled {
  cursor: not-allowed;
}
.bp4-input-group.bp4-disabled .bp4-icon {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-input-group.bp4-large .bp4-button {
  min-height: 30px;
  min-width: 30px;
  margin: 5px;
}
.bp4-input-group.bp4-large > .bp4-input-left-container > .bp4-icon,
.bp4-input-group.bp4-large > .bp4-icon,
.bp4-input-group.bp4-large .bp4-input-action > .bp4-spinner {
  margin: 12px;
}
.bp4-input-group.bp4-large .bp4-input {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.bp4-input-group.bp4-large .bp4-input[type=search], .bp4-input-group.bp4-large .bp4-input.bp4-round {
  padding: 0 15px;
}
.bp4-input-group.bp4-large .bp4-input:not(:first-child) {
  padding-left: 40px;
}
.bp4-input-group.bp4-large .bp4-input:not(:last-child) {
  padding-right: 40px;
}
.bp4-input-group.bp4-small .bp4-button {
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
}
.bp4-input-group.bp4-small .bp4-tag {
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
}
.bp4-input-group.bp4-small > .bp4-input-left-container > .bp4-icon,
.bp4-input-group.bp4-small > .bp4-icon,
.bp4-input-group.bp4-small .bp4-input-action > .bp4-spinner {
  margin: 4px;
}
.bp4-input-group.bp4-small .bp4-input {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
}
.bp4-input-group.bp4-small .bp4-input[type=search], .bp4-input-group.bp4-small .bp4-input.bp4-round {
  padding: 0 12px;
}
.bp4-input-group.bp4-small .bp4-input:not(:first-child) {
  padding-left: 24px;
}
.bp4-input-group.bp4-small .bp4-input:not(:last-child) {
  padding-right: 24px;
}
.bp4-input-group.bp4-fill {
  flex: 1 1 auto;
  width: 100%;
}
.bp4-input-group.bp4-round .bp4-button,
.bp4-input-group.bp4-round .bp4-input,
.bp4-input-group.bp4-round .bp4-tag {
  border-radius: 30px;
}
.bp4-dark .bp4-input-group .bp4-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-input-group.bp4-disabled .bp4-icon {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-input-group.bp4-intent-primary .bp4-input {
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input-group.bp4-intent-primary .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input-group.bp4-intent-primary .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}
.bp4-input-group.bp4-intent-primary .bp4-input:disabled, .bp4-input-group.bp4-intent-primary .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input {
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}
.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-input-group.bp4-intent-primary > .bp4-icon {
  color: #215db0;
}
.bp4-dark .bp4-input-group.bp4-intent-primary > .bp4-icon {
  color: #8abbff;
}
.bp4-input-group.bp4-intent-success .bp4-input {
  box-shadow: 0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input-group.bp4-intent-success .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input-group.bp4-intent-success .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}
.bp4-input-group.bp4-intent-success .bp4-input:disabled, .bp4-input-group.bp4-intent-success .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input {
  box-shadow: 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}
.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-success .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-input-group.bp4-intent-success > .bp4-icon {
  color: #1c6e42;
}
.bp4-dark .bp4-input-group.bp4-intent-success > .bp4-icon {
  color: #72ca9b;
}
.bp4-input-group.bp4-intent-warning .bp4-input {
  box-shadow: 0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input-group.bp4-intent-warning .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input-group.bp4-intent-warning .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}
.bp4-input-group.bp4-intent-warning .bp4-input:disabled, .bp4-input-group.bp4-intent-warning .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input {
  box-shadow: 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}
.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-input-group.bp4-intent-warning > .bp4-icon {
  color: #935610;
}
.bp4-dark .bp4-input-group.bp4-intent-warning > .bp4-icon {
  color: #fbb360;
}
.bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow: 0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input-group.bp4-intent-danger .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input-group.bp4-intent-danger .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}
.bp4-input-group.bp4-intent-danger .bp4-input:disabled, .bp4-input-group.bp4-intent-danger .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow: 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}
.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input.bp4-disabled {
  box-shadow: none;
}
.bp4-input-group.bp4-intent-danger > .bp4-icon {
  color: #ac2f33;
}
.bp4-dark .bp4-input-group.bp4-intent-danger > .bp4-icon {
  color: #fa999c;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Text inputs

Markup:
<input class="bp4-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.bp4-round - Rounded ends
.bp4-large - Larger size
.bp4-small - Small size
.bp4-intent-primary - Primary intent
.bp4-intent-success - Success intent
.bp4-intent-warning - Warning intent
.bp4-intent-danger - Danger intent
.bp4-fill - Take up full width of parent element

Styleguide input
*/
.bp4-input {
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
  color: #1c2127;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
}
.bp4-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}
.bp4-input:focus, .bp4-input.bp4-active {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input[type=search], .bp4-input.bp4-round {
  border-radius: 30px;
  box-sizing: border-box;
  padding-left: 10px;
}
.bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.15);
}
.bp4-input:disabled, .bp4-input.bp4-disabled {
  background: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  resize: none;
}
.bp4-input:disabled::placeholder, .bp4-input.bp4-disabled::placeholder {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-input.bp4-large {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
.bp4-input.bp4-large[type=search], .bp4-input.bp4-large.bp4-round {
  padding: 0 15px;
}
.bp4-input.bp4-small {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
}
.bp4-input.bp4-small[type=search], .bp4-input.bp4-small.bp4-round {
  padding: 0 12px;
}
.bp4-input.bp4-fill {
  flex: 1 1 auto;
  width: 100%;
}
.bp4-dark .bp4-input {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
  color: #f6f7f9;
}
.bp4-dark .bp4-input::placeholder {
  color: #abb3bf;
}
.bp4-dark .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3);
}
.bp4-dark .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-input:disabled, .bp4-dark .bp4-input.bp4-disabled {
  background: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-input.bp4-intent-primary {
  box-shadow: 0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}
.bp4-input.bp4-intent-primary:disabled, .bp4-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input.bp4-intent-primary {
  box-shadow: 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}
.bp4-dark .bp4-input.bp4-intent-primary:disabled, .bp4-dark .bp4-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}
.bp4-input.bp4-intent-success {
  box-shadow: 0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}
.bp4-input.bp4-intent-success:disabled, .bp4-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input.bp4-intent-success {
  box-shadow: 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}
.bp4-dark .bp4-input.bp4-intent-success:disabled, .bp4-dark .bp4-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}
.bp4-input.bp4-intent-warning {
  box-shadow: 0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}
.bp4-input.bp4-intent-warning:disabled, .bp4-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input.bp4-intent-warning {
  box-shadow: 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}
.bp4-dark .bp4-input.bp4-intent-warning:disabled, .bp4-dark .bp4-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}
.bp4-input.bp4-intent-danger {
  box-shadow: 0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}
.bp4-input.bp4-intent-danger:disabled, .bp4-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}
.bp4-dark .bp4-input.bp4-intent-danger {
  box-shadow: 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}
.bp4-dark .bp4-input.bp4-intent-danger:disabled, .bp4-dark .bp4-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}
.bp4-input::-ms-clear {
  display: none;
}

/*
Search inputs

Markup:
<div class="bp4-input-group {{.modifier}}">
  <span class="bp4-icon bp4-icon-search"></span>
  <input class="bp4-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.bp4-disabled</code> to <code>.bp4-input-group</code> for icon coloring (not shown below).
.bp4-large - Large
.bp4-small - Small

Styleguide input-search
*/
/*
Textareas

Markup:
<textarea class="bp4-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.bp4-large - Larger font size
.bp4-small - Small font size
.bp4-intent-primary - Primary intent
.bp4-intent-danger  - Danger intent
.bp4-fill  - Take up full width of parent element

Styleguide textarea
*/
/* stylelint-disable-next-line selector-no-qualifying-type */
textarea.bp4-input {
  max-width: 100%;
  padding: 10px;
}
textarea.bp4-input, textarea.bp4-input.bp4-large, textarea.bp4-input.bp4-small {
  height: auto;
  line-height: inherit;
}
textarea.bp4-input.bp4-small {
  padding: 8px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Labels

Markup:
<div>
  <label class="bp4-label {{.modifier}}">
    Label A
    <span class="bp4-text-muted">(optional)</span>
    <input {{:modifier}} class="bp4-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
  </label>
  <label class="bp4-label {{.modifier}}">
    Label B
    <div class="bp4-input-group {{.modifier}}">
      <span class="bp4-icon bp4-icon-calendar"></span>
      <input {{:modifier}} class="bp4-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
    </div>
  </label>
  <label class="bp4-label {{.modifier}}">
    Label C
    <div class="bp4-select {{.modifier}}">
      <select {{:modifier}}>
        <option selected>Choose an item...</option>
        <option value="1">One</option>
      </select>
    </div>
  </label>
</div>

:disabled - Disable the input.
.bp4-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp4-inline - Label and content appear side by side.

Styleguide label
*/
label.bp4-label {
  display: block;
  margin-bottom: 15px;
  margin-top: 0;
}
label.bp4-label .bp4-html-select,
label.bp4-label .bp4-input,
label.bp4-label .bp4-select,
label.bp4-label .bp4-slider,
label.bp4-label .bp4-popover-wrapper {
  display: block;
  margin-top: 5px;
  text-transform: none;
}
label.bp4-label .bp4-button-group {
  margin-top: 5px;
}
label.bp4-label .bp4-select select,
label.bp4-label .bp4-html-select select {
  font-weight: 400;
  vertical-align: top;
  width: 100%;
}
label.bp4-label.bp4-disabled,
label.bp4-label.bp4-disabled .bp4-text-muted {
  color: rgba(95, 107, 124, 0.6);
}
label.bp4-label.bp4-inline {
  line-height: 30px;
}
label.bp4-label.bp4-inline .bp4-html-select,
label.bp4-label.bp4-inline .bp4-input,
label.bp4-label.bp4-inline .bp4-input-group,
label.bp4-label.bp4-inline .bp4-select,
label.bp4-label.bp4-inline .bp4-popover-wrapper {
  display: inline-block;
  margin: 0 0 0 5px;
  vertical-align: top;
}
label.bp4-label.bp4-inline .bp4-button-group {
  margin: 0 0 0 5px;
}
label.bp4-label.bp4-inline .bp4-input-group .bp4-input {
  margin-left: 0;
}
label.bp4-label.bp4-inline.bp4-large {
  line-height: 40px;
}
label.bp4-label:not(.bp4-inline) .bp4-popover-target {
  display: block;
}
.bp4-dark label.bp4-label {
  color: #f6f7f9;
}
.bp4-dark label.bp4-label.bp4-disabled,
.bp4-dark label.bp4-label.bp4-disabled .bp4-text-muted {
  color: rgba(171, 179, 191, 0.6);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-numeric-input .bp4-button-group.bp4-vertical > .bp4-button {
  flex: 1 1 14px;
  min-height: 0;
  padding: 0;
  width: 30px;
}
.bp4-numeric-input.bp4-large .bp4-button-group.bp4-vertical > .bp4-button {
  width: 40px;
}

form {
  display: block;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-html-select select,
.bp4-select select {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  background-color: #f6f7f9;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #1c2127;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 2px;
  height: 30px;
  padding: 0 25px 0 10px;
  width: 100%;
  /* stylelint-enable property-no-vendor-prefix */
}
.bp4-html-select select > *,
.bp4-select select > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-html-select select > .bp4-fill,
.bp4-select select > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-html-select select::before,
.bp4-select select::before,
.bp4-html-select select > *,
.bp4-select select > * {
  margin-right: 7px;
}
.bp4-html-select select:empty::before,
.bp4-select select:empty::before,
.bp4-html-select select > :last-child,
.bp4-select select > :last-child {
  margin-right: 0;
}
.bp4-html-select select:hover,
.bp4-select select:hover {
  background-clip: padding-box;
  background-color: #edeff2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-html-select select:active,
.bp4-select select:active, .bp4-html-select select.bp4-active,
.bp4-select select.bp4-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-html-select select:disabled,
.bp4-select select:disabled, .bp4-html-select select.bp4-disabled,
.bp4-select select.bp4-disabled {
  background-color: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  outline: none;
}
.bp4-html-select select:disabled.bp4-active,
.bp4-select select:disabled.bp4-active, .bp4-html-select select.bp4-disabled.bp4-active,
.bp4-select select.bp4-disabled.bp4-active {
  background: rgba(211, 216, 222, 0.7);
}

.bp4-html-select.bp4-minimal select,
.bp4-select.bp4-minimal select {
  background: none;
  box-shadow: none;
}
.bp4-html-select.bp4-minimal select:hover,
.bp4-select.bp4-minimal select:hover {
  background: rgba(143, 153, 168, 0.15);
  box-shadow: none;
  color: #1c2127;
  text-decoration: none;
}
.bp4-html-select.bp4-minimal select:active,
.bp4-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal select.bp4-active,
.bp4-select.bp4-minimal select.bp4-active {
  background: rgba(143, 153, 168, 0.3);
  box-shadow: none;
  color: #1c2127;
}
.bp4-html-select.bp4-minimal select:disabled,
.bp4-select.bp4-minimal select:disabled, .bp4-html-select.bp4-minimal select.bp4-disabled,
.bp4-select.bp4-minimal select.bp4-disabled {
  background: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-html-select.bp4-minimal select:disabled.bp4-active,
.bp4-select.bp4-minimal select:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-disabled.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-html-select.bp4-minimal select, .bp4-html-select.bp4-minimal .bp4-dark select,
.bp4-dark .bp4-select.bp4-minimal select,
.bp4-select.bp4-minimal .bp4-dark select {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-html-select.bp4-minimal select:hover, .bp4-html-select.bp4-minimal .bp4-dark select:hover,
.bp4-dark .bp4-select.bp4-minimal select:hover,
.bp4-select.bp4-minimal .bp4-dark select:hover, .bp4-dark .bp4-html-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal .bp4-dark select:active,
.bp4-dark .bp4-select.bp4-minimal select:active,
.bp4-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-active {
  background: none;
  box-shadow: none;
  color: #fff;
}
.bp4-dark .bp4-html-select.bp4-minimal select:hover, .bp4-html-select.bp4-minimal .bp4-dark select:hover,
.bp4-dark .bp4-select.bp4-minimal select:hover,
.bp4-select.bp4-minimal .bp4-dark select:hover {
  background: rgba(143, 153, 168, 0.15);
}
.bp4-dark .bp4-html-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal .bp4-dark select:active,
.bp4-dark .bp4-select.bp4-minimal select:active,
.bp4-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-html-select.bp4-minimal select:disabled, .bp4-html-select.bp4-minimal .bp4-dark select:disabled,
.bp4-dark .bp4-select.bp4-minimal select:disabled,
.bp4-select.bp4-minimal .bp4-dark select:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-disabled {
  background: none;
  color: rgba(171, 179, 191, 0.6);
  cursor: not-allowed;
}
.bp4-dark .bp4-html-select.bp4-minimal select:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select:disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select:disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-disabled.bp4-active {
  background: rgba(143, 153, 168, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary,
.bp4-select.bp4-minimal select.bp4-intent-primary {
  color: #215db0;
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary:hover,
.bp4-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-html-select.bp4-minimal select.bp4-intent-primary:active,
.bp4-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active {
  background: none;
  box-shadow: none;
  color: #215db0;
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary:hover,
.bp4-select.bp4-minimal select.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.15);
  color: #215db0;
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary:active,
.bp4-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #184a90;
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled,
.bp4-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled,
.bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(33, 93, 176, 0.5);
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head,
.bp4-select.bp4-minimal select.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:hover,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:hover,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:hover {
  background: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-active {
  background: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled {
  background: none;
  color: rgba(138, 187, 255, 0.5);
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled.bp4-active {
  background: rgba(45, 114, 210, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-success,
.bp4-select.bp4-minimal select.bp4-intent-success {
  color: #1c6e42;
}
.bp4-html-select.bp4-minimal select.bp4-intent-success:hover,
.bp4-select.bp4-minimal select.bp4-intent-success:hover, .bp4-html-select.bp4-minimal select.bp4-intent-success:active,
.bp4-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-success.bp4-active {
  background: none;
  box-shadow: none;
  color: #1c6e42;
}
.bp4-html-select.bp4-minimal select.bp4-intent-success:hover,
.bp4-select.bp4-minimal select.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.15);
  color: #1c6e42;
}
.bp4-html-select.bp4-minimal select.bp4-intent-success:active,
.bp4-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #165a36;
}
.bp4-html-select.bp4-minimal select.bp4-intent-success:disabled,
.bp4-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled,
.bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(28, 110, 66, 0.5);
}
.bp4-html-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-success .bp4-button-spinner .bp4-spinner-head,
.bp4-select.bp4-minimal select.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:hover,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:hover,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:hover {
  background: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-active {
  background: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled {
  background: none;
  color: rgba(114, 202, 155, 0.5);
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled.bp4-active {
  background: rgba(35, 133, 81, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning,
.bp4-select.bp4-minimal select.bp4-intent-warning {
  color: #935610;
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning:hover,
.bp4-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-html-select.bp4-minimal select.bp4-intent-warning:active,
.bp4-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active {
  background: none;
  box-shadow: none;
  color: #935610;
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning:hover,
.bp4-select.bp4-minimal select.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.15);
  color: #935610;
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning:active,
.bp4-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #77450d;
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled,
.bp4-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled,
.bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(147, 86, 16, 0.5);
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head,
.bp4-select.bp4-minimal select.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:hover,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:hover,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:hover {
  background: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-active {
  background: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled {
  background: none;
  color: rgba(251, 179, 96, 0.5);
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled.bp4-active {
  background: rgba(200, 118, 25, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger,
.bp4-select.bp4-minimal select.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger:hover,
.bp4-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-html-select.bp4-minimal select.bp4-intent-danger:active,
.bp4-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active {
  background: none;
  box-shadow: none;
  color: #ac2f33;
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger:hover,
.bp4-select.bp4-minimal select.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.15);
  color: #ac2f33;
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger:active,
.bp4-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #8e292c;
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled,
.bp4-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled,
.bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(172, 47, 51, 0.5);
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}
.bp4-html-select.bp4-minimal select.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head,
.bp4-select.bp4-minimal select.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:hover,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:hover,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:hover {
  background: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-active {
  background: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled {
  background: none;
  color: rgba(250, 153, 156, 0.5);
}
.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled.bp4-active,
.bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active,
.bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled.bp4-active {
  background: rgba(205, 66, 70, 0.3);
}

.bp4-html-select.bp4-large select,
.bp4-select.bp4-large select {
  font-size: 16px;
  height: 40px;
  padding-right: 35px;
}

.bp4-dark .bp4-html-select select,
.bp4-dark .bp4-select select {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
  color: #f6f7f9;
}
.bp4-dark .bp4-html-select select:hover,
.bp4-dark .bp4-select select:hover, .bp4-dark .bp4-html-select select:active,
.bp4-dark .bp4-select select:active, .bp4-dark .bp4-html-select select.bp4-active,
.bp4-dark .bp4-select select.bp4-active {
  color: #f6f7f9;
}
.bp4-dark .bp4-html-select select:hover,
.bp4-dark .bp4-select select:hover {
  background-color: #2f343c;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-html-select select:active,
.bp4-dark .bp4-select select:active, .bp4-dark .bp4-html-select select.bp4-active,
.bp4-dark .bp4-select select.bp4-active {
  background-color: #1c2127;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-html-select select:disabled,
.bp4-dark .bp4-select select:disabled, .bp4-dark .bp4-html-select select.bp4-disabled,
.bp4-dark .bp4-select select.bp4-disabled {
  background-color: rgba(64, 72, 84, 0.5);
  box-shadow: none;
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-html-select select:disabled.bp4-active,
.bp4-dark .bp4-select select:disabled.bp4-active, .bp4-dark .bp4-html-select select.bp4-disabled.bp4-active,
.bp4-dark .bp4-select select.bp4-disabled.bp4-active {
  background: rgba(64, 72, 84, 0.7);
}
.bp4-dark .bp4-html-select select .bp4-button-spinner .bp4-spinner-head,
.bp4-dark .bp4-select select .bp4-button-spinner .bp4-spinner-head {
  background: rgba(17, 20, 24, 0.5);
  stroke: #8f99a8;
}

.bp4-html-select select:disabled,
.bp4-select select:disabled {
  background-color: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}

.bp4-select::after, .bp4-html-select .bp4-icon,
.bp4-select .bp4-icon {
  color: #5f6b7c;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 7px;
}
.bp4-disabled.bp4-select::after, .bp4-html-select .bp4-disabled.bp4-icon,
.bp4-select .bp4-disabled.bp4-icon {
  color: rgba(95, 107, 124, 0.6);
}

/*
HTML select

Markup:
<div class="bp4-html-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
  <span class="bp4-icon bp4-icon-double-caret-vertical"></span>
</div>

:disabled - Disabled. Also add <code>.bp4-disabled</code> to <code>.bp4-select</code> for icon coloring (not shown below).
.bp4-fill - Expand to fill parent container
.bp4-large - Large size
.bp4-minimal - Minimal appearance

Styleguide select
*/
.bp4-html-select,
.bp4-select {
  display: inline-block;
  letter-spacing: normal;
  position: relative;
  vertical-align: middle;
}
.bp4-html-select select::-ms-expand,
.bp4-select select::-ms-expand {
  display: none;
}
.bp4-html-select .bp4-icon,
.bp4-select .bp4-icon {
  color: #5f6b7c;
}
.bp4-html-select .bp4-icon:hover,
.bp4-select .bp4-icon:hover {
  color: #1c2127;
}
.bp4-dark .bp4-html-select .bp4-icon,
.bp4-dark .bp4-select .bp4-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-html-select .bp4-icon:hover,
.bp4-dark .bp4-select .bp4-icon:hover {
  color: #f6f7f9;
}
.bp4-html-select.bp4-large::after,
.bp4-html-select.bp4-large .bp4-icon,
.bp4-select.bp4-large::after,
.bp4-select.bp4-large .bp4-icon {
  right: 12px;
  top: 12px;
}
.bp4-html-select.bp4-fill,
.bp4-html-select.bp4-fill select,
.bp4-select.bp4-fill,
.bp4-select.bp4-fill select {
  width: 100%;
}
.bp4-dark .bp4-html-select option,
.bp4-dark .bp4-select option {
  background-color: #2f343c;
  color: #f6f7f9;
}
.bp4-dark .bp4-html-select option:disabled,
.bp4-dark .bp4-select option:disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-html-select::after,
.bp4-dark .bp4-select::after {
  color: #abb3bf;
}

.bp4-select::after {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\f184";
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Tables

Markup:
<table class="bp4-html-table {{.modifier}}">
  <thead>
    <tr>
      <th>Project</th>
      <th>Description</th>
      <th>Technologies</th>
      <th>Contributors</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
      <td>268</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
      <td>403</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
      <td>737</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan=3>Total</td>
      <td>1408</td>
    </tr>
  </tfoot>
</table>

.bp4-html-table-bordered - Bordered appearance
.bp4-html-table-condensed - Condensed smaller appearance
.bp4-html-table-striped - Striped appearance
.bp4-interactive - Enables hover styles on rows

Styleguide html-table
*/
table.bp4-html-table, .bp4-running-text table {
  border-spacing: 0;
  font-size: 14px;
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-enable selector-max-compound-selectors */
}
table.bp4-html-table th, .bp4-running-text table th,
table.bp4-html-table td,
.bp4-running-text table td {
  padding: 11px;
  text-align: left;
  vertical-align: top;
}
table.bp4-html-table th, .bp4-running-text table th {
  color: #1c2127;
  font-weight: 600;
}
table.bp4-html-table td, .bp4-running-text table td {
  color: #1c2127;
}
table.bp4-html-table tbody tr:first-child th, .bp4-running-text table tbody tr:first-child th,
table.bp4-html-table tbody tr:first-child td,
.bp4-running-text table tbody tr:first-child td,
table.bp4-html-table tfoot tr:first-child th,
.bp4-running-text table tfoot tr:first-child th,
table.bp4-html-table tfoot tr:first-child td,
.bp4-running-text table tfoot tr:first-child td {
  box-shadow: inset 0 1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-dark table.bp4-html-table th, .bp4-dark .bp4-running-text table th, .bp4-running-text .bp4-dark table th {
  color: #f6f7f9;
}
.bp4-dark table.bp4-html-table td, .bp4-dark .bp4-running-text table td, .bp4-running-text .bp4-dark table td {
  color: #f6f7f9;
}
.bp4-dark table.bp4-html-table tbody tr:first-child th, .bp4-dark .bp4-running-text table tbody tr:first-child th, .bp4-running-text .bp4-dark table tbody tr:first-child th,
.bp4-dark table.bp4-html-table tbody tr:first-child td,
.bp4-dark .bp4-running-text table tbody tr:first-child td,
.bp4-running-text .bp4-dark table tbody tr:first-child td,
.bp4-dark table.bp4-html-table tfoot tr:first-child th,
.bp4-dark .bp4-running-text table tfoot tr:first-child th,
.bp4-running-text .bp4-dark table tfoot tr:first-child th,
.bp4-dark table.bp4-html-table tfoot tr:first-child td,
.bp4-dark .bp4-running-text table tfoot tr:first-child td,
.bp4-running-text .bp4-dark table tfoot tr:first-child td {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
}

table.bp4-html-table.bp4-html-table-condensed th,
table.bp4-html-table.bp4-html-table-condensed td {
  padding-bottom: 6px;
  padding-top: 6px;
}
table.bp4-html-table.bp4-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(143, 153, 168, 0.15);
}
table.bp4-html-table.bp4-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(17, 20, 24, 0.15);
}
table.bp4-html-table.bp4-html-table-bordered tbody tr td,
table.bp4-html-table.bp4-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px 0 0 rgba(17, 20, 24, 0.15);
}
table.bp4-html-table.bp4-html-table-bordered tbody tr td:not(:first-child),
table.bp4-html-table.bp4-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px 0 0 rgba(17, 20, 24, 0.15);
}
table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none;
}
table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(17, 20, 24, 0.15);
}
table.bp4-html-table.bp4-interactive tbody tr:hover td {
  background-color: rgba(143, 153, 168, 0.3);
  cursor: pointer;
}
table.bp4-html-table.bp4-interactive tbody tr:active td {
  background-color: rgba(143, 153, 168, 0.35);
}
.bp4-dark table.bp4-html-table {
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-enable selector-max-compound-selectors */
}
.bp4-dark table.bp4-html-table.bp4-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(95, 107, 124, 0.15);
}
.bp4-dark table.bp4-html-table.bp4-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.2);
}
.bp4-dark table.bp4-html-table.bp4-html-table-bordered tbody tr td,
.bp4-dark table.bp4-html-table.bp4-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
}
.bp4-dark table.bp4-html-table.bp4-html-table-bordered tbody tr td:not(:first-child),
.bp4-dark table.bp4-html-table.bp4-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.2);
}
.bp4-dark table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.2);
  /* stylelint-disable max-nesting-depth */
}
.bp4-dark table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td:first-child {
  box-shadow: none;
}
.bp4-dark table.bp4-html-table.bp4-interactive tbody tr:hover td {
  background-color: rgba(95, 107, 124, 0.3);
  cursor: pointer;
}
.bp4-dark table.bp4-html-table.bp4-interactive tbody tr:active td {
  background-color: rgba(95, 107, 124, 0.4);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-key-combo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bp4-key-combo > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-key-combo > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-key-combo::before,
.bp4-key-combo > * {
  margin-right: 5px;
}
.bp4-key-combo:empty::before,
.bp4-key-combo > :last-child {
  margin-right: 0;
}

.bp4-hotkey-dialog {
  padding-bottom: 0;
  top: 40px;
}
.bp4-hotkey-dialog .bp4-dialog-body {
  margin: 0;
  padding: 0;
}
.bp4-hotkey-dialog .bp4-hotkey-label {
  flex-grow: 1;
}

.bp4-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px;
}
.bp4-hotkey-column .bp4-heading {
  margin-bottom: 20px;
}
.bp4-hotkey-column .bp4-heading:not(:first-child) {
  margin-top: 40px;
}

.bp4-hotkey {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
.bp4-hotkey:not(:last-child) {
  margin-bottom: 10px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-icon {
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom;
}
.bp4-icon:not(:empty)::before {
  /* stylelint-disable */
  content: "" !important;
  content: unset !important;
  /* stylelint-enable */
}
.bp4-icon > svg {
  display: block;
}
.bp4-icon > svg:not([fill]) {
  fill: currentcolor;
}

span.bp4-icon-standard {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

span.bp4-icon-large {
  font-family: "blueprint-icons-20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

span.bp4-icon:empty {
  font-family: "blueprint-icons-20";
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
span.bp4-icon:empty::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bp4-icon-blank::before {
  content: "\f133";
}

.bp4-icon-style::before {
  content: "\f2d4";
}

.bp4-icon-align-left::before {
  content: "\f10d";
}

.bp4-icon-align-center::before {
  content: "\f10b";
}

.bp4-icon-align-right::before {
  content: "\f10e";
}

.bp4-icon-align-justify::before {
  content: "\f10c";
}

.bp4-icon-bold::before {
  content: "\f135";
}

.bp4-icon-italic::before {
  content: "\f20a";
}

.bp4-icon-underline::before {
  content: "\f301";
}

.bp4-icon-search-around::before {
  content: "\f29f";
}

.bp4-icon-graph-remove::before {
  content: "\f1d4";
}

.bp4-icon-group-objects::before {
  content: "\f1da";
}

.bp4-icon-merge-links::before {
  content: "\f242";
}

.bp4-icon-layout::before {
  content: "\f225";
}

.bp4-icon-layout-auto::before {
  content: "\f21c";
}

.bp4-icon-layout-circle::before {
  content: "\f21e";
}

.bp4-icon-layout-hierarchy::before {
  content: "\f221";
}

.bp4-icon-layout-grid::before {
  content: "\f21f";
}

.bp4-icon-layout-group-by::before {
  content: "\f220";
}

.bp4-icon-layout-skew-grid::before {
  content: "\f223";
}

.bp4-icon-geosearch::before {
  content: "\f1c9";
}

.bp4-icon-heatmap::before {
  content: "\f1ea";
}

.bp4-icon-drive-time::before {
  content: "\f192";
}

.bp4-icon-select::before {
  content: "\f2a4";
}

.bp4-icon-predictive-analysis::before {
  content: "\f27c";
}

.bp4-icon-layers::before {
  content: "\f21b";
}

.bp4-icon-locate::before {
  content: "\f231";
}

.bp4-icon-bookmark::before {
  content: "\f137";
}

.bp4-icon-citation::before {
  content: "\f154";
}

.bp4-icon-tag::before {
  content: "\f2df";
}

.bp4-icon-clipboard::before {
  content: "\f157";
}

.bp4-icon-selection::before {
  content: "\f2a5";
}

.bp4-icon-timeline-events::before {
  content: "\f2f3";
}

.bp4-icon-timeline-line-chart::before {
  content: "\f2f4";
}

.bp4-icon-timeline-bar-chart::before {
  content: "\f2f2";
}

.bp4-icon-applications::before {
  content: "\f119";
}

.bp4-icon-projects::before {
  content: "\f280";
}

.bp4-icon-changes::before {
  content: "\f146";
}

.bp4-icon-notifications::before {
  content: "\f25e";
}

.bp4-icon-lock::before {
  content: "\f232";
}

.bp4-icon-unlock::before {
  content: "\f305";
}

.bp4-icon-user::before {
  content: "\f30a";
}

.bp4-icon-search-template::before {
  content: "\f2a0";
}

.bp4-icon-inbox::before {
  content: "\f1ff";
}

.bp4-icon-more::before {
  content: "\f24a";
}

.bp4-icon-help::before {
  content: "\f1ec";
}

.bp4-icon-calendar::before {
  content: "\f13e";
}

.bp4-icon-media::before {
  content: "\f23d";
}

.bp4-icon-link::before {
  content: "\f22d";
}

.bp4-icon-share::before {
  content: "\f2b1";
}

.bp4-icon-download::before {
  content: "\f18a";
}

.bp4-icon-document::before {
  content: "\f180";
}

.bp4-icon-properties::before {
  content: "\f281";
}

.bp4-icon-import::before {
  content: "\f1fa";
}

.bp4-icon-export::before {
  content: "\f1a0";
}

.bp4-icon-minimize::before {
  content: "\f243";
}

.bp4-icon-maximize::before {
  content: "\f23c";
}

.bp4-icon-tick::before {
  content: "\f2ef";
}

.bp4-icon-cross::before {
  content: "\f169";
}

.bp4-icon-plus::before {
  content: "\f279";
}

.bp4-icon-minus::before {
  content: "\f244";
}

.bp4-icon-arrow-left::before {
  content: "\f125";
}

.bp4-icon-arrow-right::before {
  content: "\f126";
}

.bp4-icon-exchange::before {
  content: "\f19d";
}

.bp4-icon-comparison::before {
  content: "\f161";
}

.bp4-icon-list::before {
  content: "\f230";
}

.bp4-icon-filter::before {
  content: "\f1ad";
}

.bp4-icon-confirm::before {
  content: "\f164";
}

.bp4-icon-fork::before {
  content: "\f1c0";
}

.bp4-icon-trash::before {
  content: "\f2fa";
}

.bp4-icon-person::before {
  content: "\f272";
}

.bp4-icon-people::before {
  content: "\f270";
}

.bp4-icon-add::before {
  content: "\f109";
}

.bp4-icon-remove::before {
  content: "\f290";
}

.bp4-icon-geolocation::before {
  content: "\f1c8";
}

.bp4-icon-zoom-in::before {
  content: "\f31e";
}

.bp4-icon-zoom-out::before {
  content: "\f31f";
}

.bp4-icon-refresh::before {
  content: "\f289";
}

.bp4-icon-delete::before {
  content: "\f175";
}

.bp4-icon-cog::before {
  content: "\f15d";
}

.bp4-icon-flag::before {
  content: "\f1ae";
}

.bp4-icon-pin::before {
  content: "\f275";
}

.bp4-icon-warning-sign::before {
  content: "\f315";
}

.bp4-icon-error::before {
  content: "\f19b";
}

.bp4-icon-info-sign::before {
  content: "\f200";
}

.bp4-icon-credit-card::before {
  content: "\f168";
}

.bp4-icon-edit::before {
  content: "\f194";
}

.bp4-icon-history::before {
  content: "\f1f0";
}

.bp4-icon-search::before {
  content: "\f2a2";
}

.bp4-icon-log-out::before {
  content: "\f234";
}

.bp4-icon-star::before {
  content: "\f2cd";
}

.bp4-icon-star-empty::before {
  content: "\f2cc";
}

.bp4-icon-sort-alphabetical::before {
  content: "\f2c2";
}

.bp4-icon-sort-numerical::before {
  content: "\f2c6";
}

.bp4-icon-sort::before {
  content: "\f2c7";
}

.bp4-icon-folder-open::before {
  content: "\f1ba";
}

.bp4-icon-folder-close::before {
  content: "\f1b8";
}

.bp4-icon-folder-shared::before {
  content: "\f1bc";
}

.bp4-icon-caret-up::before {
  content: "\f143";
}

.bp4-icon-caret-right::before {
  content: "\f142";
}

.bp4-icon-caret-down::before {
  content: "\f140";
}

.bp4-icon-caret-left::before {
  content: "\f141";
}

.bp4-icon-menu-open::before {
  content: "\f23f";
}

.bp4-icon-menu-closed::before {
  content: "\f23e";
}

.bp4-icon-feed::before {
  content: "\f1a7";
}

.bp4-icon-two-columns::before {
  content: "\f2ff";
}

.bp4-icon-one-column::before {
  content: "\f264";
}

.bp4-icon-dot::before {
  content: "\f182";
}

.bp4-icon-property::before {
  content: "\f282";
}

.bp4-icon-time::before {
  content: "\f2f0";
}

.bp4-icon-disable::before {
  content: "\f17d";
}

.bp4-icon-unpin::before {
  content: "\f306";
}

.bp4-icon-flows::before {
  content: "\f1b7";
}

.bp4-icon-new-text-box::before {
  content: "\f259";
}

.bp4-icon-new-link::before {
  content: "\f255";
}

.bp4-icon-new-object::before {
  content: "\f256";
}

.bp4-icon-path-search::before {
  content: "\f26d";
}

.bp4-icon-automatic-updates::before {
  content: "\f12d";
}

.bp4-icon-page-layout::before {
  content: "\f268";
}

.bp4-icon-code::before {
  content: "\f15c";
}

.bp4-icon-map::before {
  content: "\f23b";
}

.bp4-icon-search-text::before {
  content: "\f2a1";
}

.bp4-icon-envelope::before {
  content: "\f198";
}

.bp4-icon-paperclip::before {
  content: "\f26b";
}

.bp4-icon-label::before {
  content: "\f218";
}

.bp4-icon-globe::before {
  content: "\f1d3";
}

.bp4-icon-home::before {
  content: "\f1f1";
}

.bp4-icon-th::before {
  content: "\f2ea";
}

.bp4-icon-th-list::before {
  content: "\f2e9";
}

.bp4-icon-th-derived::before {
  content: "\f2e6";
}

.bp4-icon-circle::before {
  content: "\f153";
}

.bp4-icon-draw::before {
  content: "\f18d";
}

.bp4-icon-insert::before {
  content: "\f204";
}

.bp4-icon-helper-management::before {
  content: "\f1ed";
}

.bp4-icon-send-to::before {
  content: "\f2a9";
}

.bp4-icon-eye-open::before {
  content: "\f1a3";
}

.bp4-icon-folder-shared-open::before {
  content: "\f1bb";
}

.bp4-icon-social-media::before {
  content: "\f2c0";
}

.bp4-icon-arrow-up::before {
  content: "\f129";
}

.bp4-icon-arrow-down::before {
  content: "\f124";
}

.bp4-icon-arrows-horizontal::before {
  content: "\f12a";
}

.bp4-icon-arrows-vertical::before {
  content: "\f12b";
}

.bp4-icon-resolve::before {
  content: "\f293";
}

.bp4-icon-graph::before {
  content: "\f1d5";
}

.bp4-icon-briefcase::before {
  content: "\f139";
}

.bp4-icon-dollar::before {
  content: "\f181";
}

.bp4-icon-ninja::before {
  content: "\f25a";
}

.bp4-icon-delta::before {
  content: "\f176";
}

.bp4-icon-barcode::before {
  content: "\f132";
}

.bp4-icon-torch::before {
  content: "\f2f6";
}

.bp4-icon-widget::before {
  content: "\f31b";
}

.bp4-icon-unresolve::before {
  content: "\f307";
}

.bp4-icon-offline::before {
  content: "\f262";
}

.bp4-icon-zoom-to-fit::before {
  content: "\f320";
}

.bp4-icon-add-to-artifact::before {
  content: "\f107";
}

.bp4-icon-map-marker::before {
  content: "\f23a";
}

.bp4-icon-chart::before {
  content: "\f147";
}

.bp4-icon-control::before {
  content: "\f167";
}

.bp4-icon-multi-select::before {
  content: "\f24e";
}

.bp4-icon-direction-left::before {
  content: "\f17b";
}

.bp4-icon-direction-right::before {
  content: "\f17c";
}

.bp4-icon-database::before {
  content: "\f174";
}

.bp4-icon-pie-chart::before {
  content: "\f274";
}

.bp4-icon-full-circle::before {
  content: "\f1c2";
}

.bp4-icon-square::before {
  content: "\f2c9";
}

.bp4-icon-print::before {
  content: "\f27f";
}

.bp4-icon-presentation::before {
  content: "\f27e";
}

.bp4-icon-ungroup-objects::before {
  content: "\f303";
}

.bp4-icon-chat::before {
  content: "\f148";
}

.bp4-icon-comment::before {
  content: "\f160";
}

.bp4-icon-circle-arrow-right::before {
  content: "\f151";
}

.bp4-icon-circle-arrow-left::before {
  content: "\f150";
}

.bp4-icon-circle-arrow-up::before {
  content: "\f152";
}

.bp4-icon-circle-arrow-down::before {
  content: "\f14f";
}

.bp4-icon-upload::before {
  content: "\f309";
}

.bp4-icon-asterisk::before {
  content: "\f12c";
}

.bp4-icon-cloud::before {
  content: "\f15a";
}

.bp4-icon-cloud-download::before {
  content: "\f158";
}

.bp4-icon-cloud-upload::before {
  content: "\f159";
}

.bp4-icon-repeat::before {
  content: "\f291";
}

.bp4-icon-move::before {
  content: "\f24c";
}

.bp4-icon-chevron-left::before {
  content: "\f14c";
}

.bp4-icon-chevron-right::before {
  content: "\f14d";
}

.bp4-icon-chevron-up::before {
  content: "\f14e";
}

.bp4-icon-chevron-down::before {
  content: "\f14a";
}

.bp4-icon-random::before {
  content: "\f286";
}

.bp4-icon-fullscreen::before {
  content: "\f1c4";
}

.bp4-icon-log-in::before {
  content: "\f233";
}

.bp4-icon-heart::before {
  content: "\f1e8";
}

.bp4-icon-office::before {
  content: "\f261";
}

.bp4-icon-duplicate::before {
  content: "\f193";
}

.bp4-icon-ban-circle::before {
  content: "\f130";
}

.bp4-icon-camera::before {
  content: "\f13f";
}

.bp4-icon-mobile-video::before {
  content: "\f246";
}

.bp4-icon-video::before {
  content: "\f30f";
}

.bp4-icon-film::before {
  content: "\f1a8";
}

.bp4-icon-settings::before {
  content: "\f2af";
}

.bp4-icon-volume-off::before {
  content: "\f312";
}

.bp4-icon-volume-down::before {
  content: "\f311";
}

.bp4-icon-volume-up::before {
  content: "\f313";
}

.bp4-icon-music::before {
  content: "\f24f";
}

.bp4-icon-step-backward::before {
  content: "\f2ce";
}

.bp4-icon-fast-backward::before {
  content: "\f1a4";
}

.bp4-icon-pause::before {
  content: "\f26f";
}

.bp4-icon-stop::before {
  content: "\f2d1";
}

.bp4-icon-play::before {
  content: "\f278";
}

.bp4-icon-fast-forward::before {
  content: "\f1a5";
}

.bp4-icon-step-forward::before {
  content: "\f2d0";
}

.bp4-icon-eject::before {
  content: "\f195";
}

.bp4-icon-record::before {
  content: "\f287";
}

.bp4-icon-desktop::before {
  content: "\f178";
}

.bp4-icon-phone::before {
  content: "\f273";
}

.bp4-icon-lightbulb::before {
  content: "\f22b";
}

.bp4-icon-glass::before {
  content: "\f1d1";
}

.bp4-icon-tint::before {
  content: "\f2f5";
}

.bp4-icon-flash::before {
  content: "\f1b0";
}

.bp4-icon-font::before {
  content: "\f1bf";
}

.bp4-icon-header::before {
  content: "\f1e5";
}

.bp4-icon-saved::before {
  content: "\f29d";
}

.bp4-icon-floppy-disk::before {
  content: "\f1b1";
}

.bp4-icon-book::before {
  content: "\f136";
}

.bp4-icon-hand-right::before {
  content: "\f1de";
}

.bp4-icon-hand-up::before {
  content: "\f1df";
}

.bp4-icon-hand-down::before {
  content: "\f1dc";
}

.bp4-icon-hand-left::before {
  content: "\f1dd";
}

.bp4-icon-thumbs-up::before {
  content: "\f2ed";
}

.bp4-icon-thumbs-down::before {
  content: "\f2ec";
}

.bp4-icon-box::before {
  content: "\f138";
}

.bp4-icon-compressed::before {
  content: "\f163";
}

.bp4-icon-shopping-cart::before {
  content: "\f2b6";
}

.bp4-icon-shop::before {
  content: "\f2b5";
}

.bp4-icon-layout-linear::before {
  content: "\f222";
}

.bp4-icon-undo::before {
  content: "\f302";
}

.bp4-icon-redo::before {
  content: "\f288";
}

.bp4-icon-code-block::before {
  content: "\f15b";
}

.bp4-icon-double-caret-vertical::before {
  content: "\f184";
}

.bp4-icon-double-caret-horizontal::before {
  content: "\f183";
}

.bp4-icon-sort-alphabetical-desc::before {
  content: "\f2c1";
}

.bp4-icon-sort-numerical-desc::before {
  content: "\f2c5";
}

.bp4-icon-take-action::before {
  content: "\f2e0";
}

.bp4-icon-contrast::before {
  content: "\f166";
}

.bp4-icon-eye-off::before {
  content: "\f1a1";
}

.bp4-icon-timeline-area-chart::before {
  content: "\f2f1";
}

.bp4-icon-doughnut-chart::before {
  content: "\f189";
}

.bp4-icon-layer::before {
  content: "\f21a";
}

.bp4-icon-grid::before {
  content: "\f1d9";
}

.bp4-icon-polygon-filter::before {
  content: "\f27a";
}

.bp4-icon-add-to-folder::before {
  content: "\f108";
}

.bp4-icon-layout-balloon::before {
  content: "\f21d";
}

.bp4-icon-layout-sorted-clusters::before {
  content: "\f224";
}

.bp4-icon-sort-asc::before {
  content: "\f2c3";
}

.bp4-icon-sort-desc::before {
  content: "\f2c4";
}

.bp4-icon-small-cross::before {
  content: "\f2ba";
}

.bp4-icon-small-tick::before {
  content: "\f2be";
}

.bp4-icon-power::before {
  content: "\f27b";
}

.bp4-icon-column-layout::before {
  content: "\f15f";
}

.bp4-icon-arrow-top-left::before {
  content: "\f127";
}

.bp4-icon-arrow-top-right::before {
  content: "\f128";
}

.bp4-icon-arrow-bottom-right::before {
  content: "\f123";
}

.bp4-icon-arrow-bottom-left::before {
  content: "\f122";
}

.bp4-icon-mugshot::before {
  content: "\f24d";
}

.bp4-icon-headset::before {
  content: "\f1e6";
}

.bp4-icon-text-highlight::before {
  content: "\f2e5";
}

.bp4-icon-hand::before {
  content: "\f1e0";
}

.bp4-icon-chevron-backward::before {
  content: "\f149";
}

.bp4-icon-chevron-forward::before {
  content: "\f14b";
}

.bp4-icon-rotate-document::before {
  content: "\f299";
}

.bp4-icon-rotate-page::before {
  content: "\f29a";
}

.bp4-icon-badge::before {
  content: "\f12f";
}

.bp4-icon-grid-view::before {
  content: "\f1d8";
}

.bp4-icon-function::before {
  content: "\f1c5";
}

.bp4-icon-waterfall-chart::before {
  content: "\f316";
}

.bp4-icon-stacked-chart::before {
  content: "\f2ca";
}

.bp4-icon-pulse::before {
  content: "\f284";
}

.bp4-icon-new-person::before {
  content: "\f257";
}

.bp4-icon-exclude-row::before {
  content: "\f19e";
}

.bp4-icon-pivot-table::before {
  content: "\f276";
}

.bp4-icon-segmented-control::before {
  content: "\f2a3";
}

.bp4-icon-highlight::before {
  content: "\f1ef";
}

.bp4-icon-filter-list::before {
  content: "\f1aa";
}

.bp4-icon-cut::before {
  content: "\f16f";
}

.bp4-icon-annotation::before {
  content: "\f115";
}

.bp4-icon-pivot::before {
  content: "\f277";
}

.bp4-icon-ring::before {
  content: "\f296";
}

.bp4-icon-heat-grid::before {
  content: "\f1e9";
}

.bp4-icon-gantt-chart::before {
  content: "\f1c6";
}

.bp4-icon-variable::before {
  content: "\f30b";
}

.bp4-icon-manual::before {
  content: "\f235";
}

.bp4-icon-add-row-top::before {
  content: "\f106";
}

.bp4-icon-add-row-bottom::before {
  content: "\f105";
}

.bp4-icon-add-column-left::before {
  content: "\f102";
}

.bp4-icon-add-column-right::before {
  content: "\f103";
}

.bp4-icon-remove-row-top::before {
  content: "\f28f";
}

.bp4-icon-remove-row-bottom::before {
  content: "\f28e";
}

.bp4-icon-remove-column-left::before {
  content: "\f28b";
}

.bp4-icon-remove-column-right::before {
  content: "\f28c";
}

.bp4-icon-double-chevron-left::before {
  content: "\f186";
}

.bp4-icon-double-chevron-right::before {
  content: "\f187";
}

.bp4-icon-double-chevron-up::before {
  content: "\f188";
}

.bp4-icon-double-chevron-down::before {
  content: "\f185";
}

.bp4-icon-key-control::before {
  content: "\f20e";
}

.bp4-icon-key-command::before {
  content: "\f20d";
}

.bp4-icon-key-shift::before {
  content: "\f213";
}

.bp4-icon-key-backspace::before {
  content: "\f20c";
}

.bp4-icon-key-delete::before {
  content: "\f20f";
}

.bp4-icon-key-escape::before {
  content: "\f211";
}

.bp4-icon-key-enter::before {
  content: "\f210";
}

.bp4-icon-calculator::before {
  content: "\f13d";
}

.bp4-icon-horizontal-bar-chart::before {
  content: "\f1f4";
}

.bp4-icon-small-plus::before {
  content: "\f2bc";
}

.bp4-icon-small-minus::before {
  content: "\f2bb";
}

.bp4-icon-step-chart::before {
  content: "\f2cf";
}

.bp4-icon-euro::before {
  content: "\f19c";
}

.bp4-icon-drag-handle-vertical::before {
  content: "\f18c";
}

.bp4-icon-drag-handle-horizontal::before {
  content: "\f18b";
}

.bp4-icon-mobile-phone::before {
  content: "\f245";
}

.bp4-icon-sim-card::before {
  content: "\f2b8";
}

.bp4-icon-trending-up::before {
  content: "\f2fd";
}

.bp4-icon-trending-down::before {
  content: "\f2fc";
}

.bp4-icon-curved-range-chart::before {
  content: "\f16e";
}

.bp4-icon-vertical-bar-chart-desc::before {
  content: "\f30d";
}

.bp4-icon-horizontal-bar-chart-desc::before {
  content: "\f1f3";
}

.bp4-icon-document-open::before {
  content: "\f17e";
}

.bp4-icon-document-share::before {
  content: "\f17f";
}

.bp4-icon-horizontal-distribution::before {
  content: "\f1f5";
}

.bp4-icon-vertical-distribution::before {
  content: "\f30e";
}

.bp4-icon-alignment-left::before {
  content: "\f111";
}

.bp4-icon-alignment-vertical-center::before {
  content: "\f114";
}

.bp4-icon-alignment-right::before {
  content: "\f112";
}

.bp4-icon-alignment-top::before {
  content: "\f113";
}

.bp4-icon-alignment-horizontal-center::before {
  content: "\f110";
}

.bp4-icon-alignment-bottom::before {
  content: "\f10f";
}

.bp4-icon-git-pull::before {
  content: "\f1ce";
}

.bp4-icon-git-merge::before {
  content: "\f1cc";
}

.bp4-icon-git-branch::before {
  content: "\f1ca";
}

.bp4-icon-git-commit::before {
  content: "\f1cb";
}

.bp4-icon-git-push::before {
  content: "\f1cf";
}

.bp4-icon-build::before {
  content: "\f13c";
}

.bp4-icon-symbol-circle::before {
  content: "\f2d8";
}

.bp4-icon-symbol-square::before {
  content: "\f2db";
}

.bp4-icon-symbol-diamond::before {
  content: "\f2da";
}

.bp4-icon-symbol-cross::before {
  content: "\f2d9";
}

.bp4-icon-symbol-triangle-up::before {
  content: "\f2dd";
}

.bp4-icon-symbol-triangle-down::before {
  content: "\f2dc";
}

.bp4-icon-wrench::before {
  content: "\f31d";
}

.bp4-icon-application::before {
  content: "\f118";
}

.bp4-icon-send-to-graph::before {
  content: "\f2a7";
}

.bp4-icon-send-to-map::before {
  content: "\f2a8";
}

.bp4-icon-join-table::before {
  content: "\f20b";
}

.bp4-icon-derive-column::before {
  content: "\f177";
}

.bp4-icon-image-rotate-left::before {
  content: "\f1f8";
}

.bp4-icon-image-rotate-right::before {
  content: "\f1f9";
}

.bp4-icon-known-vehicle::before {
  content: "\f216";
}

.bp4-icon-unknown-vehicle::before {
  content: "\f304";
}

.bp4-icon-scatter-plot::before {
  content: "\f29e";
}

.bp4-icon-oil-field::before {
  content: "\f263";
}

.bp4-icon-rig::before {
  content: "\f294";
}

.bp4-icon-map-create::before {
  content: "\f239";
}

.bp4-icon-key-option::before {
  content: "\f212";
}

.bp4-icon-list-detail-view::before {
  content: "\f22f";
}

.bp4-icon-swap-vertical::before {
  content: "\f2d6";
}

.bp4-icon-swap-horizontal::before {
  content: "\f2d5";
}

.bp4-icon-numbered-list::before {
  content: "\f25f";
}

.bp4-icon-new-grid-item::before {
  content: "\f252";
}

.bp4-icon-git-repo::before {
  content: "\f1d0";
}

.bp4-icon-git-new-branch::before {
  content: "\f1cd";
}

.bp4-icon-manually-entered-data::before {
  content: "\f236";
}

.bp4-icon-airplane::before {
  content: "\f10a";
}

.bp4-icon-merge-columns::before {
  content: "\f241";
}

.bp4-icon-split-columns::before {
  content: "\f2c8";
}

.bp4-icon-dashboard::before {
  content: "\f171";
}

.bp4-icon-publish-function::before {
  content: "\f283";
}

.bp4-icon-path::before {
  content: "\f26e";
}

.bp4-icon-moon::before {
  content: "\f249";
}

.bp4-icon-remove-column::before {
  content: "\f28d";
}

.bp4-icon-numerical::before {
  content: "\f260";
}

.bp4-icon-key-tab::before {
  content: "\f214";
}

.bp4-icon-regression-chart::before {
  content: "\f28a";
}

.bp4-icon-translate::before {
  content: "\f2f9";
}

.bp4-icon-eye-on::before {
  content: "\f1a2";
}

.bp4-icon-vertical-bar-chart-asc::before {
  content: "\f30c";
}

.bp4-icon-horizontal-bar-chart-asc::before {
  content: "\f1f2";
}

.bp4-icon-grouped-bar-chart::before {
  content: "\f1db";
}

.bp4-icon-full-stacked-chart::before {
  content: "\f1c3";
}

.bp4-icon-endorsed::before {
  content: "\f197";
}

.bp4-icon-follower::before {
  content: "\f1bd";
}

.bp4-icon-following::before {
  content: "\f1be";
}

.bp4-icon-menu::before {
  content: "\f240";
}

.bp4-icon-collapse-all::before {
  content: "\f15e";
}

.bp4-icon-expand-all::before {
  content: "\f19f";
}

.bp4-icon-intersection::before {
  content: "\f205";
}

.bp4-icon-blocked-person::before {
  content: "\f134";
}

.bp4-icon-slash::before {
  content: "\f2b9";
}

.bp4-icon-percentage::before {
  content: "\f271";
}

.bp4-icon-satellite::before {
  content: "\f29c";
}

.bp4-icon-paragraph::before {
  content: "\f26c";
}

.bp4-icon-bank-account::before {
  content: "\f131";
}

.bp4-icon-cell-tower::before {
  content: "\f145";
}

.bp4-icon-id-number::before {
  content: "\f1f7";
}

.bp4-icon-ip-address::before {
  content: "\f206";
}

.bp4-icon-eraser::before {
  content: "\f19a";
}

.bp4-icon-issue::before {
  content: "\f209";
}

.bp4-icon-issue-new::before {
  content: "\f208";
}

.bp4-icon-issue-closed::before {
  content: "\f207";
}

.bp4-icon-panel-stats::before {
  content: "\f269";
}

.bp4-icon-panel-table::before {
  content: "\f26a";
}

.bp4-icon-tick-circle::before {
  content: "\f2ee";
}

.bp4-icon-prescription::before {
  content: "\f27d";
}

.bp4-icon-new-prescription::before {
  content: "\f258";
}

.bp4-icon-filter-keep::before {
  content: "\f1a9";
}

.bp4-icon-filter-remove::before {
  content: "\f1ac";
}

.bp4-icon-key::before {
  content: "\f215";
}

.bp4-icon-feed-subscribed::before {
  content: "\f1a6";
}

.bp4-icon-widget-button::before {
  content: "\f318";
}

.bp4-icon-widget-header::before {
  content: "\f31a";
}

.bp4-icon-widget-footer::before {
  content: "\f319";
}

.bp4-icon-header-one::before {
  content: "\f1e2";
}

.bp4-icon-header-two::before {
  content: "\f1e4";
}

.bp4-icon-form::before {
  content: "\f1c1";
}

.bp4-icon-series-add::before {
  content: "\f2aa";
}

.bp4-icon-series-search::before {
  content: "\f2ae";
}

.bp4-icon-series-filtered::before {
  content: "\f2ad";
}

.bp4-icon-series-derived::before {
  content: "\f2ac";
}

.bp4-icon-series-configuration::before {
  content: "\f2ab";
}

.bp4-icon-console::before {
  content: "\f165";
}

.bp4-icon-compass::before {
  content: "\f162";
}

.bp4-icon-walk::before {
  content: "\f314";
}

.bp4-icon-taxi::before {
  content: "\f2e3";
}

.bp4-icon-train::before {
  content: "\f2f8";
}

.bp4-icon-heart-broken::before {
  content: "\f1e7";
}

.bp4-icon-inner-join::before {
  content: "\f203";
}

.bp4-icon-left-join::before {
  content: "\f227";
}

.bp4-icon-right-join::before {
  content: "\f295";
}

.bp4-icon-strikethrough::before {
  content: "\f2d3";
}

.bp4-icon-updated::before {
  content: "\f308";
}

.bp4-icon-outdated::before {
  content: "\f267";
}

.bp4-icon-flame::before {
  content: "\f1af";
}

.bp4-icon-folder-new::before {
  content: "\f1b9";
}

.bp4-icon-mountain::before {
  content: "\f24b";
}

.bp4-icon-shield::before {
  content: "\f2b3";
}

.bp4-icon-diagram-tree::before {
  content: "\f17a";
}

.bp4-icon-crown::before {
  content: "\f16a";
}

.bp4-icon-globe-network::before {
  content: "\f1d2";
}

.bp4-icon-snowflake::before {
  content: "\f2bf";
}

.bp4-icon-tree::before {
  content: "\f2fb";
}

.bp4-icon-notifications-updated::before {
  content: "\f25d";
}

.bp4-icon-list-columns::before {
  content: "\f22e";
}

.bp4-icon-flow-linear::before {
  content: "\f1b4";
}

.bp4-icon-flow-branch::before {
  content: "\f1b2";
}

.bp4-icon-flow-review::before {
  content: "\f1b6";
}

.bp4-icon-flow-review-branch::before {
  content: "\f1b5";
}

.bp4-icon-flow-end::before {
  content: "\f1b3";
}

.bp4-icon-clean::before {
  content: "\f155";
}

.bp4-icon-th-filtered::before {
  content: "\f2e8";
}

.bp4-icon-lifesaver::before {
  content: "\f22a";
}

.bp4-icon-cube::before {
  content: "\f16d";
}

.bp4-icon-cube-add::before {
  content: "\f16b";
}

.bp4-icon-cube-remove::before {
  content: "\f16c";
}

.bp4-icon-inbox-filtered::before {
  content: "\f1fb";
}

.bp4-icon-inbox-geo::before {
  content: "\f1fc";
}

.bp4-icon-inbox-search::before {
  content: "\f1fd";
}

.bp4-icon-inbox-update::before {
  content: "\f1fe";
}

.bp4-icon-inheritance::before {
  content: "\f201";
}

.bp4-icon-reset::before {
  content: "\f292";
}

.bp4-icon-filter-open::before {
  content: "\f1ab";
}

.bp4-icon-th-disconnect::before {
  content: "\f2e7";
}

.bp4-icon-equals::before {
  content: "\f199";
}

.bp4-icon-not-equal-to::before {
  content: "\f25b";
}

.bp4-icon-greater-than::before {
  content: "\f1d7";
}

.bp4-icon-greater-than-or-equal-to::before {
  content: "\f1d6";
}

.bp4-icon-less-than::before {
  content: "\f229";
}

.bp4-icon-less-than-or-equal-to::before {
  content: "\f228";
}

.bp4-icon-learning::before {
  content: "\f226";
}

.bp4-icon-new-layer::before {
  content: "\f253";
}

.bp4-icon-new-layers::before {
  content: "\f254";
}

.bp4-icon-stopwatch::before {
  content: "\f2d2";
}

.bp4-icon-archive::before {
  content: "\f11a";
}

.bp4-icon-unarchive::before {
  content: "\f300";
}

.bp4-icon-data-lineage::before {
  content: "\f173";
}

.bp4-icon-new-drawing::before {
  content: "\f251";
}

.bp4-icon-signal-search::before {
  content: "\f2b7";
}

.bp4-icon-bring-data::before {
  content: "\f13a";
}

.bp4-icon-tractor::before {
  content: "\f2f7";
}

.bp4-icon-truck::before {
  content: "\f2fe";
}

.bp4-icon-diagnosis::before {
  content: "\f179";
}

.bp4-icon-lab-test::before {
  content: "\f217";
}

.bp4-icon-virus::before {
  content: "\f310";
}

.bp4-icon-inherited-group::before {
  content: "\f202";
}

.bp4-icon-hat::before {
  content: "\f1e1";
}

.bp4-icon-cycle::before {
  content: "\f170";
}

.bp4-icon-route::before {
  content: "\f29b";
}

.bp4-icon-modal::before {
  content: "\f248";
}

.bp4-icon-modal-filled::before {
  content: "\f247";
}

.bp4-icon-drawer-left::before {
  content: "\f18f";
}

.bp4-icon-drawer-left-filled::before {
  content: "\f18e";
}

.bp4-icon-drawer-right::before {
  content: "\f191";
}

.bp4-icon-drawer-right-filled::before {
  content: "\f190";
}

.bp4-icon-app-header::before {
  content: "\f117";
}

.bp4-icon-send-message::before {
  content: "\f2a6";
}

.bp4-icon-backlink::before {
  content: "\f12e";
}

.bp4-icon-geofence::before {
  content: "\f1c7";
}

.bp4-icon-data-connection::before {
  content: "\f172";
}

.bp4-icon-switch::before {
  content: "\f2d7";
}

.bp4-icon-array::before {
  content: "\f121";
}

.bp4-icon-array-boolean::before {
  content: "\f11c";
}

.bp4-icon-array-date::before {
  content: "\f11d";
}

.bp4-icon-array-numeric::before {
  content: "\f11e";
}

.bp4-icon-array-string::before {
  content: "\f11f";
}

.bp4-icon-array-timestamp::before {
  content: "\f120";
}

.bp4-icon-layer-outline::before {
  content: "\f219";
}

.bp4-icon-notifications-snooze::before {
  content: "\f25c";
}

.bp4-icon-high-priority::before {
  content: "\f1ee";
}

.bp4-icon-emoji::before {
  content: "\f196";
}

.bp4-icon-add-location::before {
  content: "\f104";
}

.bp4-icon-shapes::before {
  content: "\f2b0";
}

.bp4-icon-shared-filter::before {
  content: "\f2b2";
}

.bp4-icon-one-to-one::before {
  content: "\f266";
}

.bp4-icon-one-to-many::before {
  content: "\f265";
}

.bp4-icon-many-to-one::before {
  content: "\f238";
}

.bp4-icon-many-to-many::before {
  content: "\f237";
}

.bp4-icon-stadium-geometry::before {
  content: "\f2cb";
}

.bp4-icon-area-of-interest::before {
  content: "\f11b";
}

.bp4-icon-buggy::before {
  content: "\f13b";
}

.bp4-icon-antenna::before {
  content: "\f116";
}

.bp4-icon-tank::before {
  content: "\f2e1";
}

.bp4-icon-third-party::before {
  content: "\f2eb";
}

.bp4-icon-rocket::before {
  content: "\f298";
}

.bp4-icon-rocket-slant::before {
  content: "\f297";
}

.bp4-icon-header-three::before {
  content: "\f1e3";
}

.bp4-icon-helicopter::before {
  content: "\f1eb";
}

.bp4-icon-syringe::before {
  content: "\f2de";
}

.bp4-icon-temperature::before {
  content: "\f2e4";
}

.bp4-icon-waves::before {
  content: "\f317";
}

.bp4-icon-rain::before {
  content: "\f285";
}

.bp4-icon-lightning::before {
  content: "\f22c";
}

.bp4-icon-wind::before {
  content: "\f31c";
}

.bp4-icon-hurricane::before {
  content: "\f1f6";
}

.bp4-icon-nest::before {
  content: "\f250";
}

.bp4-icon-target::before {
  content: "\f2e2";
}

.bp4-icon-small-square::before {
  content: "\f2bd";
}

.bp4-icon-ship::before {
  content: "\f2b4";
}

.bp4-icon-cargo-ship::before {
  content: "\f144";
}

.bp4-icon-clip::before {
  content: "\f156";
}

.bp4-icon-add-clip::before {
  content: "\f101";
}

.bp4-icon-rectangle::before {
  content: "\f321";
}

.bp4-icon-symbol-rectangle::before {
  content: "\f322";
}

.bp4-icon-fuel::before {
  content: "\f323";
}

.bp4-icon-playbook::before {
  content: "\f324";
}

.bp4-icon-rect-height::before {
  content: "\f325";
}

.bp4-icon-rect-width::before {
  content: "\f326";
}

.bp4-icon-divide::before {
  content: "\f327";
}

.bp4-icon-color-fill::before {
  content: "\f328";
}

.bp4-icon-horizontal-inbetween::before {
  content: "\f329";
}

.bp4-icon-vertical-inbetween::before {
  content: "\f32a";
}

.bp4-icon-open-application::before {
  content: "\f32b";
}

.bp4-icon-floating-point::before {
  content: "\f32c";
}

.bp4-icon-array-floating-point::before {
  content: "\f32d";
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-submenu > .bp4-popover-wrapper {
  display: block;
}
.bp4-submenu .bp4-popover-target {
  display: block;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item {
  /* stylelint-disable scss/at-extend-no-missing-placeholder */
  /* stylelint-enable scss/at-extend-no-missing-placeholder */
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]) {
  background-color: rgba(143, 153, 168, 0.15);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected {
  background-color: rgba(45, 114, 210, 0.1);
  color: #215db0;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected .bp4-menu-item-label {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected .bp4-submenu-icon {
  color: #215db0;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
  color: #1c6e42;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
  color: #935610;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
  color: #ac2f33;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]) {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]) .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]) .bp4-submenu-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected {
  background-color: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected .bp4-submenu-icon {
  color: #8abbff;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*=bp4-intent-]).ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-], .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active {
  background-color: rgba(45, 114, 210, 0.1);
  color: #215db0;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-] .bp4-menu-item-label, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover .bp4-menu-item-label, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active .bp4-menu-item-label {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-] .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-] .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active .bp4-submenu-icon {
  color: #215db0;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
  color: #1c6e42;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
  color: #935610;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
  color: #ac2f33;
}
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger::before,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger .bp4-menu-item-icon,
.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-], .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active {
  background-color: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-] .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-] .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active .bp4-submenu-icon {
  color: #8abbff;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-].bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger::before,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*=bp4-intent-]:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-submenu.bp4-popover {
  box-shadow: none;
  padding: 0 5px;
}
.bp4-submenu.bp4-popover > .bp4-popover-content {
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-submenu.bp4-popover, .bp4-submenu.bp4-popover.bp4-dark {
  box-shadow: none;
}
.bp4-dark .bp4-submenu.bp4-popover > .bp4-popover-content, .bp4-submenu.bp4-popover.bp4-dark > .bp4-popover-content {
  box-shadow: 0 0 0 1px hsl(215deg, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}

/*
Menus

Markup:
<ul class="bp4-menu {{.modifier}} bp4-elevation-1">
  <li>
    <a class="bp4-menu-item bp4-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="bp4-menu-item bp4-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="bp4-menu-item bp4-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="bp4-menu-divider"></li>
  <li>
    <a class="bp4-menu-item bp4-icon-trash bp4-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.bp4-large - Large size (only supported on <code>.bp4-menu</code>)

Styleguide menu
*/
.bp4-menu {
  background: #fff;
  border-radius: 2px;
  color: #1c2127;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 5px;
  text-align: left;
}

.bp4-menu-divider {
  border-top: 1px solid rgba(17, 20, 24, 0.15);
  display: block;
  margin: 5px;
}
.bp4-dark .bp4-menu-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.bp4-menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  color: inherit;
  line-height: 20px;
  padding: 5px 7px;
  text-decoration: none;
  user-select: none;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-menu-item > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-menu-item > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-menu-item::before,
.bp4-menu-item > * {
  margin-right: 7px;
}
.bp4-menu-item:empty::before,
.bp4-menu-item > :last-child {
  margin-right: 0;
}
.bp4-menu-item > .bp4-fill {
  word-break: break-word;
}
.bp4-menu-item .bp4-menu-item-icon {
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
}
.bp4-menu-item .bp4-menu-item-label {
  color: #5f6b7c;
}
.bp4-menu-item::before,
.bp4-menu-item .bp4-menu-item-icon,
.bp4-menu-item .bp4-submenu-icon {
  color: #5f6b7c;
}
.bp4-menu-item::before,
.bp4-menu-item .bp4-submenu-icon {
  margin-top: 2px;
}
.bp4-menu-item:hover {
  background-color: rgba(143, 153, 168, 0.15);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
.bp4-menu-item:hover.ns-selected {
  background-color: rgba(45, 114, 210, 0.1);
  color: #215db0;
}
.bp4-menu-item:hover.ns-selected .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item:hover.ns-selected::before,
.bp4-menu-item:hover.ns-selected .bp4-menu-item-icon,
.bp4-menu-item:hover.ns-selected .bp4-submenu-icon {
  color: #215db0;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
  color: #1c6e42;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-success::before,
.bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
  color: #935610;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-warning::before,
.bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
  color: #ac2f33;
}
.bp4-menu-item:hover.ns-selected.bp4-intent-danger::before,
.bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item:active, .bp4-menu-item.bp4-active {
  background-color: rgba(143, 153, 168, 0.3);
}
.bp4-menu-item:active .bp4-menu-item-label, .bp4-menu-item.bp4-active .bp4-menu-item-label {
  color: #1c2127;
}
.bp4-menu-item.bp4-selected, .bp4-menu-item.bp4-selected:hover, .bp4-menu-item.bp4-selected:active {
  background-color: rgba(45, 114, 210, 0.1);
  color: #215db0;
}
.bp4-menu-item.bp4-selected .bp4-menu-item-label, .bp4-menu-item.bp4-selected:hover .bp4-menu-item-label, .bp4-menu-item.bp4-selected:active .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item.bp4-selected::before,
.bp4-menu-item.bp4-selected .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover::before,
.bp4-menu-item.bp4-selected:hover .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:hover .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active::before,
.bp4-menu-item.bp4-selected:active .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:active .bp4-submenu-icon {
  color: #215db0;
}
.bp4-menu-item.bp4-selected.bp4-intent-success, .bp4-menu-item.bp4-selected:hover.bp4-intent-success, .bp4-menu-item.bp4-selected:active.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
  color: #1c6e42;
}
.bp4-menu-item.bp4-selected.bp4-intent-success::before,
.bp4-menu-item.bp4-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected.bp4-intent-success .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-success::before,
.bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-success::before,
.bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item.bp4-selected.bp4-intent-warning, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning, .bp4-menu-item.bp4-selected:active.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
  color: #935610;
}
.bp4-menu-item.bp4-selected.bp4-intent-warning::before,
.bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning::before,
.bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-warning::before,
.bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item.bp4-selected.bp4-intent-danger, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger, .bp4-menu-item.bp4-selected:active.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
  color: #ac2f33;
}
.bp4-menu-item.bp4-selected.bp4-intent-danger::before,
.bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger::before,
.bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-danger::before,
.bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-menu-item-icon,
.bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-menu-item.bp4-disabled {
  background-color: inherit !important;
  color: rgba(95, 107, 124, 0.6) !important;
  cursor: not-allowed !important;
  outline: none !important;
}
.bp4-menu-item.bp4-disabled::before,
.bp4-menu-item.bp4-disabled .bp4-menu-item-icon,
.bp4-menu-item.bp4-disabled .bp4-submenu-icon {
  color: rgba(95, 107, 124, 0.6) !important;
}
.bp4-menu-item.bp4-disabled .bp4-menu-item-label {
  color: rgba(95, 107, 124, 0.6) !important;
}
.bp4-menu-item.bp4-intent-primary {
  color: #215db0;
}
.bp4-menu-item.bp4-intent-primary::before,
.bp4-menu-item.bp4-intent-primary .bp4-menu-item-icon,
.bp4-menu-item.bp4-intent-primary .bp4-submenu-icon,
.bp4-menu-item.bp4-intent-primary .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item.bp4-intent-primary:hover {
  background-color: rgba(45, 114, 210, 0.1);
}
.bp4-menu-item.bp4-intent-primary:active, .bp4-menu-item.bp4-intent-primary.bp4-active {
  background-color: rgba(45, 114, 210, 0.2);
  color: #184a90;
}
.bp4-menu-item.bp4-intent-success {
  color: #1c6e42;
}
.bp4-menu-item.bp4-intent-success::before,
.bp4-menu-item.bp4-intent-success .bp4-menu-item-icon,
.bp4-menu-item.bp4-intent-success .bp4-submenu-icon,
.bp4-menu-item.bp4-intent-success .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item.bp4-intent-success:hover {
  background-color: rgba(35, 133, 81, 0.1);
}
.bp4-menu-item.bp4-intent-success:active, .bp4-menu-item.bp4-intent-success.bp4-active {
  background-color: rgba(35, 133, 81, 0.2);
  color: #165a36;
}
.bp4-menu-item.bp4-intent-warning {
  color: #935610;
}
.bp4-menu-item.bp4-intent-warning::before,
.bp4-menu-item.bp4-intent-warning .bp4-menu-item-icon,
.bp4-menu-item.bp4-intent-warning .bp4-submenu-icon,
.bp4-menu-item.bp4-intent-warning .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item.bp4-intent-warning:hover {
  background-color: rgba(200, 118, 25, 0.1);
}
.bp4-menu-item.bp4-intent-warning:active, .bp4-menu-item.bp4-intent-warning.bp4-active {
  background-color: rgba(200, 118, 25, 0.2);
  color: #77450d;
}
.bp4-menu-item.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-menu-item.bp4-intent-danger::before,
.bp4-menu-item.bp4-intent-danger .bp4-menu-item-icon,
.bp4-menu-item.bp4-intent-danger .bp4-submenu-icon,
.bp4-menu-item.bp4-intent-danger .bp4-menu-item-label {
  color: inherit;
}
.bp4-menu-item.bp4-intent-danger:hover {
  background-color: rgba(205, 66, 70, 0.1);
}
.bp4-menu-item.bp4-intent-danger:active, .bp4-menu-item.bp4-intent-danger.bp4-active {
  background-color: rgba(205, 66, 70, 0.2);
  color: #8e292c;
}
.bp4-menu-item::before {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 7px;
}
.bp4-large .bp4-menu-item {
  font-size: 16px;
  line-height: 22px;
  padding: 9px 7px;
}
.bp4-large .bp4-menu-item .bp4-menu-item-icon {
  height: 22px;
}
.bp4-large .bp4-menu-item::before,
.bp4-large .bp4-menu-item .bp4-submenu-icon {
  margin-top: 3px;
}
.bp4-large .bp4-menu-item::before {
  font-family: "blueprint-icons-20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
}

button.bp4-menu-item {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
}

/*
Menu headers

Markup:
<ul class="bp4-menu bp4-elevation-1">
  <li class="bp4-menu-header"><h6 class="bp4-heading">Layouts</h6></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-layout-grid">Grid</button></li>
  <li class="bp4-menu-header"><h6 class="bp4-heading">Views</h6></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-history">History</button></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-star">Favorites</button></li>
  <li><button type="button" class="bp4-menu-item bp4-icon-envelope">Messages</button></li>
</ul>

Styleguide menu-header
*/
.bp4-menu-header {
  border-top: 1px solid rgba(17, 20, 24, 0.15);
  display: block;
  margin: 5px;
  cursor: default;
  padding-left: 2px;
}
.bp4-dark .bp4-menu-header {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.bp4-menu-header:first-of-type {
  border-top: none;
}
.bp4-menu-header > h6 {
  color: #1c2127;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: 17px;
  margin: 0;
  padding: 10px 7px 0 1px;
}
.bp4-dark .bp4-menu-header > h6 {
  color: #f6f7f9;
}
.bp4-menu-header:first-of-type > h6 {
  padding-top: 0;
}
.bp4-large .bp4-menu-header > h6 {
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 15px;
}
.bp4-large .bp4-menu-header:first-of-type > h6 {
  padding-top: 0;
}

.bp4-dark .bp4-menu {
  background: #2f343c;
  color: #f6f7f9;
}
.bp4-dark .bp4-menu-item {
  color: inherit;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-dark .bp4-menu-item .bp4-menu-item-label {
  color: #abb3bf;
}
.bp4-dark .bp4-menu-item::before,
.bp4-dark .bp4-menu-item .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item .bp4-submenu-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-menu-item:hover {
  color: inherit;
}
.bp4-dark .bp4-menu-item:hover .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item:hover .bp4-submenu-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-menu-item:hover.ns-selected {
  background-color: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-menu-item:hover.ns-selected::before,
.bp4-dark .bp4-menu-item:hover.ns-selected .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item:hover.ns-selected .bp4-submenu-icon {
  color: #8abbff;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success::before,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning::before,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger::before,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item:active .bp4-menu-item-label, .bp4-dark .bp4-menu-item.bp4-active .bp4-menu-item-label {
  color: #f6f7f9;
}
.bp4-dark .bp4-menu-item.bp4-selected, .bp4-dark .bp4-menu-item.bp4-selected:hover, .bp4-dark .bp4-menu-item.bp4-selected:active {
  background-color: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-menu-item.bp4-selected::before,
.bp4-dark .bp4-menu-item.bp4-selected .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover::before,
.bp4-dark .bp4-menu-item.bp4-selected:hover .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:hover .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active::before,
.bp4-dark .bp4-menu-item.bp4-selected:active .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:active .bp4-submenu-icon {
  color: #8abbff;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success::before,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success::before,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success::before,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning::before,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning::before,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning::before,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger::before,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger::before,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger::before,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-disabled {
  color: rgba(171, 179, 191, 0.6) !important;
}
.bp4-dark .bp4-menu-item.bp4-disabled::before,
.bp4-dark .bp4-menu-item.bp4-disabled .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-disabled .bp4-submenu-icon {
  color: rgba(171, 179, 191, 0.6) !important;
}
.bp4-dark .bp4-menu-item.bp4-disabled .bp4-menu-item-label {
  color: rgba(171, 179, 191, 0.6) !important;
}
.bp4-dark .bp4-menu-item.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-menu-item.bp4-intent-primary::before,
.bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-submenu-icon,
.bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-menu-item-label {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-intent-primary:hover {
  background-color: rgba(45, 114, 210, 0.2);
}
.bp4-dark .bp4-menu-item.bp4-intent-primary:active, .bp4-dark .bp4-menu-item.bp4-intent-primary.bp4-active {
  background-color: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-menu-item.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-menu-item.bp4-intent-success::before,
.bp4-dark .bp4-menu-item.bp4-intent-success .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-intent-success .bp4-submenu-icon,
.bp4-dark .bp4-menu-item.bp4-intent-success .bp4-menu-item-label {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-intent-success:hover {
  background-color: rgba(35, 133, 81, 0.2);
}
.bp4-dark .bp4-menu-item.bp4-intent-success:active, .bp4-dark .bp4-menu-item.bp4-intent-success.bp4-active {
  background-color: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-menu-item.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-menu-item.bp4-intent-warning::before,
.bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-submenu-icon,
.bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-menu-item-label {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-intent-warning:hover {
  background-color: rgba(200, 118, 25, 0.2);
}
.bp4-dark .bp4-menu-item.bp4-intent-warning:active, .bp4-dark .bp4-menu-item.bp4-intent-warning.bp4-active {
  background-color: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-menu-item.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-menu-item.bp4-intent-danger::before,
.bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-menu-item-icon,
.bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-submenu-icon,
.bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-menu-item-label {
  color: inherit;
}
.bp4-dark .bp4-menu-item.bp4-intent-danger:hover {
  background-color: rgba(205, 66, 70, 0.2);
}
.bp4-dark .bp4-menu-item.bp4-intent-danger:active, .bp4-dark .bp4-menu-item.bp4-intent-danger.bp4-active {
  background-color: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-menu-divider,
.bp4-dark .bp4-menu-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.bp4-dark .bp4-menu-header > h6 {
  color: #f6f7f9;
}

.bp4-label .bp4-menu {
  margin-top: 5px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Navbars

Markup:
<nav class="bp4-navbar {{.modifier}}">
  <div class="bp4-navbar-group bp4-align-left">
    <div class="bp4-navbar-heading">Blueprint</div>
    <input class="bp4-input" placeholder="Search files..." type="text" />
  </div>
  <div class="bp4-navbar-group bp4-align-right">
    <button class="bp4-button bp4-minimal bp4-icon-home">Home</button>
    <button class="bp4-button bp4-minimal bp4-icon-document">Files</button>
    <span class="bp4-navbar-divider"></span>
    <button class="bp4-button bp4-minimal bp4-icon-user"></button>
    <button class="bp4-button bp4-minimal bp4-icon-notifications"></button>
    <button class="bp4-button bp4-minimal bp4-icon-cog"></button>
  </div>
</nav>

.bp4-dark - Dark theme

Styleguide navbar
*/
.bp4-navbar {
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  height: 50px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10;
}
.bp4-navbar.bp4-dark, .bp4-dark .bp4-navbar {
  background-color: #383e47;
}
.bp4-navbar.bp4-dark {
  box-shadow: inset inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-navbar {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
}
.bp4-navbar.bp4-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.bp4-navbar-heading {
  font-size: 16px;
  margin-right: 15px;
}

.bp4-navbar-group {
  align-items: center;
  display: flex;
  height: 50px;
}
.bp4-navbar-group.bp4-align-left {
  float: left;
}
.bp4-navbar-group.bp4-align-right {
  float: right;
}

.bp4-navbar-divider {
  border-left: 1px solid rgba(17, 20, 24, 0.15);
  height: 20px;
  margin: 0 10px;
}
.bp4-dark .bp4-navbar-divider {
  border-left-color: rgba(255, 255, 255, 0.2);
}

/*
Fixed width

Markup:
<nav class="bp4-navbar bp4-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="bp4-navbar-group bp4-align-left">
      <div class="bp4-navbar-heading">Blueprint</div>
    </div>
    <div class="bp4-navbar-group bp4-align-right">
      <button class="bp4-button bp4-minimal bp4-icon-home">Home</button>
      <button class="bp4-button bp4-minimal bp4-icon-document">Files</button>
      <span class="bp4-navbar-divider"></span>
      <button class="bp4-button bp4-minimal bp4-icon-user"></button>
      <button class="bp4-button bp4-minimal bp4-icon-notifications"></button>
      <button class="bp4-button bp4-minimal bp4-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide navbar-container
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Non-ideal state

Markup:
<div class="bp4-non-ideal-state">
  <div class="bp4-non-ideal-state-visual" style="font-size: 48px; line-height: 48px;">
    <span class="bp4-icon bp4-icon-folder-open"></span>
  </div>
  <div class="bp4-non-ideal-state-text">
    <h4 class="bp4-heading">This folder is empty</h4>
    <div>Create a new file to populate the folder.</div>
  </div>
  <button class="bp4-button bp4-intent-primary">Create</button>
</div>

Styleguide non-ideal-state
*/
.bp4-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5f6b7c;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.bp4-non-ideal-state > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-non-ideal-state > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-non-ideal-state::before,
.bp4-non-ideal-state > * {
  margin-bottom: 20px;
}
.bp4-non-ideal-state:empty::before,
.bp4-non-ideal-state > :last-child {
  margin-bottom: 0;
}
.bp4-non-ideal-state > * {
  max-width: 400px;
}
.bp4-non-ideal-state .bp4-heading {
  color: #5f6b7c;
  line-height: 20px;
  margin-bottom: 10px;
}
.bp4-non-ideal-state .bp4-heading:only-child {
  margin-bottom: 0;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal {
  display: flex;
  flex-direction: row;
  text-align: left;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal::before,
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal > * {
  margin-right: 20px;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal:empty::before,
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal > :last-child {
  margin-right: 0;
}
.bp4-non-ideal-state.bp4-non-ideal-state-horizontal > * {
  margin-bottom: 0;
}
.bp4-dark .bp4-non-ideal-state {
  color: #abb3bf;
}
.bp4-dark .bp4-non-ideal-state .bp4-heading {
  color: #abb3bf;
}

.bp4-non-ideal-state-visual {
  color: #8f99a8;
}
.bp4-non-ideal-state-visual .bp4-icon svg {
  fill-opacity: 0.15;
  overflow: visible;
}
.bp4-non-ideal-state-visual .bp4-icon svg path {
  stroke: #8f99a8;
  stroke-opacity: 0.5;
  stroke-width: 0.5px;
}
.bp4-dark .bp4-non-ideal-state-visual .bp4-icon svg {
  fill-opacity: 0.2;
}

.bp4-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
}

.bp4-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
body.bp4-overlay-open {
  overflow: hidden;
}

.bp4-overlay {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  top: 0;
  z-index: 20;
}
.bp4-overlay:not(.bp4-overlay-open) {
  pointer-events: none;
}
.bp4-overlay.bp4-overlay-container {
  overflow: hidden;
  position: fixed;
}
.bp4-overlay.bp4-overlay-container.bp4-overlay-inline {
  position: absolute;
}
.bp4-overlay.bp4-overlay-scroll-container {
  overflow: auto;
  position: fixed;
}
.bp4-overlay.bp4-overlay-scroll-container.bp4-overlay-inline {
  position: absolute;
}
.bp4-overlay.bp4-overlay-inline {
  display: inline;
  overflow: visible;
}

.bp4-overlay-content {
  position: fixed;
  z-index: 20;
}
.bp4-overlay-inline .bp4-overlay-content, .bp4-overlay-scroll-container .bp4-overlay-content {
  position: absolute;
}

.bp4-overlay-backdrop {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 1;
  background-color: rgba(17, 20, 24, 0.7);
  overflow: auto;
  user-select: none;
  z-index: 20;
}
.bp4-overlay-backdrop.bp4-overlay-enter, .bp4-overlay-backdrop.bp4-overlay-appear {
  opacity: 0;
}
.bp4-overlay-backdrop.bp4-overlay-enter-active, .bp4-overlay-backdrop.bp4-overlay-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-overlay-backdrop.bp4-overlay-exit {
  opacity: 1;
}
.bp4-overlay-backdrop.bp4-overlay-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-duration: 200ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-overlay-backdrop:focus {
  outline: none;
}
.bp4-overlay-inline .bp4-overlay-backdrop {
  position: absolute;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-panel-stack {
  overflow: hidden;
  position: relative;
}

.bp4-panel-stack-header {
  align-items: center;
  box-shadow: 0 1px rgba(17, 20, 24, 0.15);
  display: flex;
  flex-shrink: 0;
  height: 30px;
  z-index: 1;
}
.bp4-dark .bp4-panel-stack-header {
  box-shadow: 0 1px rgba(255, 255, 255, 0.2);
}
.bp4-panel-stack-header > span {
  align-items: stretch;
  display: flex;
  flex: 1;
}
.bp4-panel-stack-header .bp4-heading {
  margin: 0 5px;
}

.bp4-button.bp4-panel-stack-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap;
}
.bp4-button.bp4-panel-stack-header-back .bp4-icon {
  margin: 0 2px;
}

.bp4-panel-stack-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  border-right: 1px solid rgba(17, 20, 24, 0.15);
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  overflow-y: auto;
  z-index: 1;
}
.bp4-dark .bp4-panel-stack-view {
  background-color: #383e47;
}
.bp4-panel-stack-view:nth-last-child(n+4) {
  display: none;
}

.bp4-panel-stack-push .bp4-panel-stack-enter, .bp4-panel-stack-push .bp4-panel-stack-appear {
  transform: translateX(100%);
  opacity: 0;
}
.bp4-panel-stack-push .bp4-panel-stack-enter-active, .bp4-panel-stack-push .bp4-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}
.bp4-panel-stack-push .bp4-panel-stack-exit {
  transform: translate(0%);
  opacity: 1;
}
.bp4-panel-stack-push .bp4-panel-stack-exit-active {
  transform: translateX(-50%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}

.bp4-panel-stack-pop .bp4-panel-stack-enter, .bp4-panel-stack-pop .bp4-panel-stack-appear {
  transform: translateX(-50%);
  opacity: 0;
}
.bp4-panel-stack-pop .bp4-panel-stack-enter-active, .bp4-panel-stack-pop .bp4-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}
.bp4-panel-stack-pop .bp4-panel-stack-exit {
  transform: translate(0%);
  opacity: 1;
}
.bp4-panel-stack-pop .bp4-panel-stack-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-panel-stack2 {
  overflow: hidden;
  position: relative;
}

.bp4-panel-stack2-header {
  align-items: center;
  box-shadow: 0 1px rgba(17, 20, 24, 0.15);
  display: flex;
  flex-shrink: 0;
  height: 30px;
  z-index: 1;
}
.bp4-dark .bp4-panel-stack2-header {
  box-shadow: 0 1px rgba(255, 255, 255, 0.2);
}
.bp4-panel-stack2-header > span {
  align-items: stretch;
  display: flex;
  flex: 1;
}
.bp4-panel-stack2-header .bp4-heading {
  margin: 0 5px;
}

.bp4-button.bp4-panel-stack2-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap;
}
.bp4-button.bp4-panel-stack2-header-back .bp4-icon {
  margin: 0 2px;
}

.bp4-panel-stack2-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  border-right: 1px solid rgba(17, 20, 24, 0.15);
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  overflow-y: auto;
  z-index: 1;
}
.bp4-dark .bp4-panel-stack2-view {
  background-color: #383e47;
}
.bp4-panel-stack2-view:nth-last-child(n+4) {
  display: none;
}

.bp4-panel-stack2-push .bp4-panel-stack2-enter, .bp4-panel-stack2-push .bp4-panel-stack2-appear {
  transform: translateX(100%);
  opacity: 0;
}
.bp4-panel-stack2-push .bp4-panel-stack2-enter-active, .bp4-panel-stack2-push .bp4-panel-stack2-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}
.bp4-panel-stack2-push .bp4-panel-stack2-exit {
  transform: translate(0%);
  opacity: 1;
}
.bp4-panel-stack2-push .bp4-panel-stack2-exit-active {
  transform: translateX(-50%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}

.bp4-panel-stack2-pop .bp4-panel-stack2-enter, .bp4-panel-stack2-pop .bp4-panel-stack2-appear {
  transform: translateX(-50%);
  opacity: 0;
}
.bp4-panel-stack2-pop .bp4-panel-stack2-enter-active, .bp4-panel-stack2-pop .bp4-panel-stack2-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}
.bp4-panel-stack2-pop .bp4-panel-stack2-exit {
  transform: translate(0%);
  opacity: 1;
}
.bp4-panel-stack2-pop .bp4-panel-stack2-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: ease;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-popover {
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform: scale(1);
  border-radius: 2px;
  display: inline-block;
  z-index: 20;
}
.bp4-popover .bp4-popover-arrow {
  height: 30px;
  position: absolute;
  width: 30px;
}
.bp4-popover .bp4-popover-arrow::before {
  height: 20px;
  margin: 5px;
  width: 20px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover {
  margin-bottom: 17px;
  margin-top: -17px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow {
  bottom: -11px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(-90deg);
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover {
  margin-left: 17px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow {
  left: -11px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(0);
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover {
  margin-top: 17px;
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow {
  top: -11px;
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(90deg);
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover {
  margin-left: -17px;
  margin-right: 17px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow {
  right: -11px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(180deg);
}
.bp4-tether-element-attached-middle > .bp4-popover > .bp4-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.bp4-tether-element-attached-center > .bp4-popover > .bp4-popover-arrow {
  right: 50%;
  transform: translateX(50%);
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow {
  top: -0.3933982644px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow {
  right: -0.3933982644px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow {
  left: -0.3933982644px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow {
  bottom: -0.3933982644px;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: top left;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: top center;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: top right;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: center left;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: center center;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: center right;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: bottom left;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: bottom center;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: bottom right;
}
.bp4-popover .bp4-popover-content {
  background: #fff;
}
.bp4-popover .bp4-popover-content,
.bp4-popover .bp4-heading {
  color: inherit;
}
.bp4-popover .bp4-popover-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-popover .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: 0.1;
}
.bp4-popover .bp4-popover-arrow-fill {
  fill: #fff;
}
.bp4-popover-enter > .bp4-popover, .bp4-popover-appear > .bp4-popover {
  transform: scale(0.3);
}
.bp4-popover-enter-active > .bp4-popover, .bp4-popover-appear-active > .bp4-popover {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover-exit > .bp4-popover {
  transform: scale(1);
}
.bp4-popover-exit-active > .bp4-popover {
  transform: scale(0.3);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover .bp4-popover-content {
  border-radius: 2px;
  position: relative;
}
.bp4-popover.bp4-popover-content-sizing .bp4-popover-content {
  max-width: 350px;
  padding: 20px;
}
.bp4-popover-target + .bp4-overlay .bp4-popover.bp4-popover-content-sizing {
  width: 350px;
}
.bp4-popover.bp4-minimal {
  margin: 0 !important;
  /* stylelint-disable-line declaration-no-important */
}
.bp4-popover.bp4-minimal .bp4-popover-arrow {
  display: none;
}
.bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}
.bp4-popover-enter > .bp4-popover.bp4-minimal.bp4-popover, .bp4-popover-appear > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}
.bp4-popover-enter-active > .bp4-popover.bp4-minimal.bp4-popover, .bp4-popover-appear-active > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover-exit > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}
.bp4-popover-exit-active > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover.bp4-dark, .bp4-dark .bp4-popover {
  box-shadow: 0 0 0 1px hsl(215deg, 3%, 38%), inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-popover.bp4-dark .bp4-popover-content, .bp4-dark .bp4-popover .bp4-popover-content {
  background: #2f343c;
}
.bp4-popover.bp4-dark .bp4-popover-content,
.bp4-popover.bp4-dark .bp4-heading, .bp4-dark .bp4-popover .bp4-popover-content,
.bp4-dark .bp4-popover .bp4-heading {
  color: inherit;
}
.bp4-popover.bp4-dark .bp4-popover-arrow::before, .bp4-dark .bp4-popover .bp4-popover-arrow::before {
  box-shadow: 0 0 0 1px #777a7e, 1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-popover.bp4-dark .bp4-popover-arrow-border, .bp4-dark .bp4-popover .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: 0.2;
}
.bp4-popover.bp4-dark .bp4-popover-arrow-fill, .bp4-dark .bp4-popover .bp4-popover-arrow-fill {
  fill: #2f343c;
}

.bp4-popover-arrow::before {
  border-radius: 1px;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.bp4-tether-pinned .bp4-popover-arrow {
  display: none;
}

.bp4-popover-backdrop {
  background: rgba(255, 255, 255, 0);
}

.bp4-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20;
}
.bp4-transition-container.bp4-popover-enter, .bp4-transition-container.bp4-popover-appear {
  opacity: 0;
}
.bp4-transition-container.bp4-popover-enter-active, .bp4-transition-container.bp4-popover-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-transition-container.bp4-popover-exit {
  opacity: 1;
}
.bp4-transition-container.bp4-popover-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: opacity;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-transition-container:focus {
  outline: none;
}
.bp4-transition-container.bp4-popover-leave .bp4-popover-content {
  pointer-events: none;
}
.bp4-transition-container[data-x-out-of-boundaries] {
  display: none;
}

span.bp4-popover-target {
  display: inline-block;
}

.bp4-popover-wrapper.bp4-fill {
  width: 100%;
}

.bp4-portal {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Progress bars

Markup:
<div class="bp4-progress-bar {{.modifier}}">
  <div class="bp4-progress-meter" style="width: 25%"></div>
</div>
<div class="bp4-progress-bar bp4-intent-primary {{.modifier}}">
  <div class="bp4-progress-meter" style="width: 50%"></div>
</div>
<div class="bp4-progress-bar bp4-intent-success {{.modifier}}">
  <div class="bp4-progress-meter" style="width: 75%"></div>
</div>
<div class="bp4-progress-bar bp4-intent-danger {{.modifier}}">
  <div class="bp4-progress-meter" style="width: 100%"></div>
</div>

.bp4-no-stripes   - No stripes
.bp4-no-animation - No animation

Styleguide progress-bar
*/
@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 30px 0;
  }
}
.bp4-progress-bar {
  background: rgba(95, 107, 124, 0.2);
  border-radius: 40px;
  display: block;
  height: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.bp4-progress-bar .bp4-progress-meter {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
  background-color: rgba(95, 107, 124, 0.8);
  background-size: 30px 30px;
  border-radius: 40px;
  height: 100%;
  position: absolute;
  transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width: 100%;
}
.bp4-progress-bar:not(.bp4-no-animation):not(.bp4-no-stripes) .bp4-progress-meter {
  animation: linear-progress-bar-stripes 300ms linear infinite reverse;
}
.bp4-progress-bar.bp4-no-stripes .bp4-progress-meter {
  background-image: none;
}

.bp4-dark .bp4-progress-bar {
  background: rgba(17, 20, 24, 0.5);
}
.bp4-dark .bp4-progress-bar .bp4-progress-meter {
  background-color: #8f99a8;
}

.bp4-progress-bar.bp4-intent-primary .bp4-progress-meter {
  background-color: #2d72d2;
}

.bp4-progress-bar.bp4-intent-success .bp4-progress-meter {
  background-color: #238551;
}

.bp4-progress-bar.bp4-intent-warning .bp4-progress-meter {
  background-color: #c87619;
}

.bp4-progress-bar.bp4-intent-danger .bp4-progress-meter {
  background-color: #cd4246;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Skeletons

Markup:
<div class="bp4-card">
  <h5 class="bp4-heading"><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="bp4-button bp4-icon-add {{.modifier}}" tabindex="-1">Submit</button>
</div>

.bp4-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide skeleton
*/
@keyframes skeleton-glow {
  from {
    background: rgba(211, 216, 222, 0.2);
    border-color: rgba(211, 216, 222, 0.2);
  }
  to {
    background: rgba(95, 107, 124, 0.2);
    border-color: rgba(95, 107, 124, 0.2);
  }
}
/* stylelint-disable declaration-no-important */
.bp4-skeleton {
  animation: 1000ms linear infinite alternate skeleton-glow;
  background: rgba(211, 216, 222, 0.2);
  background-clip: padding-box !important;
  border-color: rgba(211, 216, 222, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none;
}
.bp4-skeleton::before, .bp4-skeleton::after,
.bp4-skeleton * {
  visibility: hidden !important;
}

/* stylelint-enable declaration-no-important */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
.bp4-slider {
  height: 40px;
  min-width: 150px;
  width: 100%;
  cursor: default;
  outline: none;
  position: relative;
  user-select: none;
}
.bp4-slider:hover {
  cursor: pointer;
}
.bp4-slider:active {
  cursor: grabbing;
}
.bp4-slider.bp4-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.bp4-slider.bp4-slider-unlabeled {
  height: 16px;
}

.bp4-slider-track,
.bp4-slider-progress {
  height: 6px;
  left: 0;
  right: 0;
  top: 5px;
  position: absolute;
}

.bp4-slider-track {
  border-radius: 2px;
  overflow: hidden;
}

.bp4-slider-progress {
  background: rgba(95, 107, 124, 0.2);
}
.bp4-dark .bp4-slider-progress {
  background: rgba(17, 20, 24, 0.5);
}
.bp4-slider-progress.bp4-intent-primary {
  background-color: #2d72d2;
}
.bp4-slider-progress.bp4-intent-success {
  background-color: #238551;
}
.bp4-slider-progress.bp4-intent-warning {
  background-color: #c87619;
}
.bp4-slider-progress.bp4-intent-danger {
  background-color: #cd4246;
}

.bp4-slider-handle {
  background-color: #f6f7f9;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.1);
  color: #1c2127;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 1px rgba(17, 20, 24, 0.5);
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}
.bp4-slider-handle:hover {
  background-clip: padding-box;
  background-color: #edeff2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-slider-handle:active, .bp4-slider-handle.bp4-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-slider-handle:disabled, .bp4-slider-handle.bp4-disabled {
  background-color: rgba(211, 216, 222, 0.5);
  box-shadow: none;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  outline: none;
}
.bp4-slider-handle:disabled.bp4-active, .bp4-slider-handle:disabled.bp4-active:hover, .bp4-slider-handle.bp4-disabled.bp4-active, .bp4-slider-handle.bp4-disabled.bp4-active:hover {
  background: rgba(211, 216, 222, 0.7);
}
.bp4-slider-handle:focus {
  z-index: 1;
}
.bp4-slider-handle:hover {
  background-clip: padding-box;
  background-color: #edeff2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 2px rgba(17, 20, 24, 0.6);
  cursor: grab;
  z-index: 2;
}
.bp4-slider-handle.bp4-active {
  background-color: #dce0e5;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 1px 2px rgba(17, 20, 24, 0.2);
  box-shadow: inset 0 1px 1px rgba(17, 20, 24, 0.1), 0 0 0 1px rgba(17, 20, 24, 0.5), 0 1px 2px rgba(17, 20, 24, 0.2);
  cursor: grabbing;
}
.bp4-disabled .bp4-slider-handle {
  background: #c5cbd3;
  box-shadow: none;
  pointer-events: none;
}
.bp4-dark .bp4-slider-handle {
  background-color: #abb3bf;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-slider-handle:hover {
  background-color: #8f99a8;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-slider-handle.bp4-active {
  background-color: #738091;
  box-shadow: inset 0 1px 1px rgba(17, 20, 24, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 2px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-disabled .bp4-slider-handle {
  background: #5f6b7c;
  border-color: #5f6b7c;
  box-shadow: none;
}
.bp4-slider-handle .bp4-slider-label {
  background: #404854;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  color: #f6f7f9;
  margin-left: 8px;
}
.bp4-dark .bp4-slider-handle .bp4-slider-label {
  background: #e5e8eb;
  box-shadow: 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
  color: #404854;
}
.bp4-disabled .bp4-slider-handle .bp4-slider-label {
  box-shadow: none;
}
.bp4-slider-handle.bp4-start, .bp4-slider-handle.bp4-end {
  width: 8px;
}
.bp4-slider-handle.bp4-start {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bp4-slider-handle.bp4-end {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 8px;
}
.bp4-slider-handle.bp4-end .bp4-slider-label {
  margin-left: 0;
}

.bp4-slider-label {
  transform: translate(-50%, 20px);
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 2px 5px;
  position: absolute;
  vertical-align: top;
}

.bp4-slider.bp4-vertical {
  height: 150px;
  min-width: 40px;
  width: 40px;
}
.bp4-slider.bp4-vertical .bp4-slider-track,
.bp4-slider.bp4-vertical .bp4-slider-progress {
  bottom: 0;
  height: auto;
  left: 5px;
  top: 0;
  width: 6px;
}
.bp4-slider.bp4-vertical .bp4-slider-progress {
  top: auto;
}
.bp4-slider.bp4-vertical .bp4-slider-label {
  transform: translate(20px, 50%);
}
.bp4-slider.bp4-vertical .bp4-slider-handle {
  top: auto;
}
.bp4-slider.bp4-vertical .bp4-slider-handle .bp4-slider-label {
  margin-left: 0;
  margin-top: -8px;
}
.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-end, .bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start {
  height: 8px;
  margin-left: 0;
  width: 16px;
}
.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start {
  border-bottom-right-radius: 2px;
  border-top-left-radius: 0;
}
.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start .bp4-slider-label {
  transform: translate(20px);
}
.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-end {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  margin-bottom: 8px;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bp4-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
}
.bp4-spinner svg {
  display: block;
}
.bp4-spinner path {
  fill-opacity: 0;
}
.bp4-spinner .bp4-spinner-head {
  stroke: rgba(95, 107, 124, 0.8);
  stroke-linecap: round;
  transform-origin: center;
  transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-spinner .bp4-spinner-track {
  stroke: rgba(95, 107, 124, 0.2);
}

.bp4-spinner-animation {
  animation: pt-spinner-animation 500ms linear infinite;
}
.bp4-no-spin > .bp4-spinner-animation {
  animation: none;
}

.bp4-dark .bp4-spinner .bp4-spinner-head {
  stroke: #8f99a8;
}
.bp4-dark .bp4-spinner .bp4-spinner-track {
  stroke: rgba(17, 20, 24, 0.5);
}

.bp4-spinner.bp4-intent-primary .bp4-spinner-head {
  stroke: #2d72d2;
}

.bp4-spinner.bp4-intent-success .bp4-spinner-head {
  stroke: #238551;
}

.bp4-spinner.bp4-intent-warning .bp4-spinner-head {
  stroke: #c87619;
}

.bp4-spinner.bp4-intent-danger .bp4-spinner-head {
  stroke: #cd4246;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Tabs

Markup:
<div class="bp4-tabs">
    <ul class="bp4-tab-list {{.modifier}}" role="tablist">
        <li class="bp4-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="bp4-tab" role="tab">Another tab</li>
        <li class="bp4-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="bp4-tab-panel" role="tabpanel">Selected panel</div>
    <div class="bp4-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="bp4-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.bp4-large - Large tabs

Styleguide tabs
*/
.bp4-tabs.bp4-vertical {
  display: flex;
}
.bp4-tabs.bp4-vertical > .bp4-tab-list {
  align-items: flex-start;
  flex-direction: column;
}
.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab {
  border-radius: 2px;
  padding: 0 10px;
  width: 100%;
}
.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab[aria-selected=true] {
  background-color: rgba(45, 114, 210, 0.2);
  box-shadow: none;
}
.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab-indicator-wrapper .bp4-tab-indicator {
  background-color: rgba(45, 114, 210, 0.2);
  border-radius: 2px;
  bottom: 0;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
}
.bp4-tabs.bp4-vertical > .bp4-tab-panel {
  margin-top: 0;
  padding-left: 20px;
}

.bp4-tab-list {
  align-items: flex-end;
  border: none;
  display: flex;
  flex: 0 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  /* stylelint-disable-next-line selector-no-universal */
}
.bp4-tab-list > *:not(:last-child) {
  margin-right: 20px;
}

.bp4-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #1c2127;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
  position: relative;
  vertical-align: top;
}
.bp4-tab a {
  color: inherit;
  display: block;
  text-decoration: none;
}
.bp4-tab-indicator-wrapper ~ .bp4-tab {
  /* stylelint-disable declaration-no-important */
  background-color: transparent !important;
  box-shadow: none !important;
  /* stylelint-enable declaration-no-important */
}
.bp4-tab[aria-disabled=true] {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-tab[aria-selected=true] {
  border-radius: 0;
  box-shadow: inset 0 -3px 0 #215db0;
}
.bp4-tab[aria-selected=true], .bp4-tab:not([aria-disabled=true]):hover {
  color: #215db0;
}
.bp4-tab:focus {
  -moz-outline-radius: 0;
}
.bp4-large > .bp4-tab {
  font-size: 16px;
  line-height: 40px;
}

.bp4-tab-panel {
  margin-top: 20px;
}
.bp4-tab-panel[aria-hidden=true] {
  display: none;
}

.bp4-tab-indicator-wrapper {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-tab-indicator-wrapper .bp4-tab-indicator {
  background-color: #215db0;
  bottom: 0;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
}
.bp4-tab-indicator-wrapper.bp4-no-animation {
  transition: none;
}

.bp4-dark .bp4-tab {
  color: #f6f7f9;
}
.bp4-dark .bp4-tab[aria-disabled=true] {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-tab[aria-selected=true] {
  box-shadow: inset 0 -3px 0 #8abbff;
}
.bp4-dark .bp4-tab[aria-selected=true], .bp4-dark .bp4-tab:not([aria-disabled=true]):hover {
  color: #8abbff;
}
.bp4-dark .bp4-tab-indicator {
  background-color: #8abbff;
}

.bp4-flex-expander {
  flex: 1 1;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Tags

Markup:
<span class="bp4-tag {{.modifier}}">125</span>
<span class="bp4-tag {{.modifier}}">Done</span>
<span class="bp4-tag {{.modifier}}">
  Tracking
  <button class="bp4-tag-remove"></button>
</span>
<span class="bp4-tag {{.modifier}}">
  Crushed
  <button class="bp4-tag-remove"></button>
</span>
<span class="bp4-tag {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="bp4-tag-remove"></button>
</span>

.bp4-large - Large
.bp4-minimal - Minimal appearance
.bp4-round - Rounded corners, ideal for badges
.bp4-interactive - Hover and active effects
.bp4-intent-primary - Primary intent
.bp4-intent-success - Success intent
.bp4-intent-warning - Warning intent
.bp4-intent-danger  - Danger intent

Styleguide tag
*/
.bp4-tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: #5f6b7c;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  max-width: 100%;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 6px;
  position: relative;
}
.bp4-tag > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-tag > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-tag::before,
.bp4-tag > * {
  margin-right: 4px;
}
.bp4-tag:empty::before,
.bp4-tag > :last-child {
  margin-right: 0;
}
.bp4-tag:focus {
  outline: rgba(45, 114, 210, 0.6) solid 2px;
  outline-offset: 0;
  -moz-outline-radius: 6px;
}
.bp4-tag.bp4-interactive {
  cursor: pointer;
}
.bp4-tag.bp4-interactive:hover {
  background: #404854;
}
.bp4-tag.bp4-interactive:active, .bp4-tag.bp4-interactive.bp4-active {
  background: #383e47;
}
.bp4-tag.bp4-round {
  border-radius: 30px;
  padding-left: 8px;
  padding-right: 8px;
}
.bp4-dark .bp4-tag {
  background-color: #c5cbd3;
  color: #1c2127;
}
.bp4-dark .bp4-tag > .bp4-icon, .bp4-dark .bp4-tag .bp4-icon-standard, .bp4-dark .bp4-tag .bp4-icon-large {
  fill: currentcolor;
}
.bp4-dark .bp4-tag.bp4-interactive:hover {
  background: #abb3bf;
}
.bp4-dark .bp4-tag.bp4-interactive:active, .bp4-dark .bp4-tag.bp4-interactive.bp4-active {
  background: #8f99a8;
}
.bp4-tag > .bp4-icon, .bp4-tag .bp4-icon-standard, .bp4-tag .bp4-icon-large {
  fill: #fff;
}
.bp4-tag.bp4-large, .bp4-large .bp4-tag {
  font-size: 14px;
  line-height: 20px;
  min-height: 30px;
  min-width: 30px;
  padding: 5px 10px;
}
.bp4-tag.bp4-large::before,
.bp4-tag.bp4-large > *, .bp4-large .bp4-tag::before,
.bp4-large .bp4-tag > * {
  margin-right: 7px;
}
.bp4-tag.bp4-large:empty::before,
.bp4-tag.bp4-large > :last-child, .bp4-large .bp4-tag:empty::before,
.bp4-large .bp4-tag > :last-child {
  margin-right: 0;
}
.bp4-tag.bp4-large.bp4-round, .bp4-large .bp4-tag.bp4-round {
  padding-left: 12px;
  padding-right: 12px;
}
.bp4-tag.bp4-intent-primary {
  background: #2d72d2;
  color: #fff;
}
.bp4-tag.bp4-intent-primary.bp4-interactive:hover {
  background-color: #215db0;
}
.bp4-tag.bp4-intent-primary.bp4-interactive:active {
  background-color: #184a90;
}
.bp4-tag.bp4-intent-primary .bp4-tag-remove {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-tag.bp4-intent-primary .bp4-tag-remove:hover, .bp4-tag.bp4-intent-primary .bp4-tag-remove:active {
  color: #fff;
}
.bp4-tag.bp4-intent-success {
  background: #238551;
  color: #fff;
}
.bp4-tag.bp4-intent-success.bp4-interactive:hover {
  background-color: #1c6e42;
}
.bp4-tag.bp4-intent-success.bp4-interactive:active {
  background-color: #165a36;
}
.bp4-tag.bp4-intent-success .bp4-tag-remove {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-tag.bp4-intent-success .bp4-tag-remove:hover, .bp4-tag.bp4-intent-success .bp4-tag-remove:active {
  color: #fff;
}
.bp4-tag.bp4-intent-warning {
  background: #fbb360;
  color: #1c2127;
}
.bp4-tag.bp4-intent-warning.bp4-interactive:hover {
  background-color: #ec9a3c;
}
.bp4-tag.bp4-intent-warning.bp4-interactive:active {
  background-color: #c87619;
}
.bp4-tag.bp4-intent-warning .bp4-tag-remove {
  color: rgba(28, 33, 39, 0.7);
}
.bp4-tag.bp4-intent-warning .bp4-tag-remove:hover, .bp4-tag.bp4-intent-warning .bp4-tag-remove:active {
  color: #1c2127;
}
.bp4-tag.bp4-intent-danger {
  background: #cd4246;
  color: #fff;
}
.bp4-tag.bp4-intent-danger.bp4-interactive:hover {
  background-color: #ac2f33;
}
.bp4-tag.bp4-intent-danger.bp4-interactive:active {
  background-color: #8e292c;
}
.bp4-tag.bp4-intent-danger .bp4-tag-remove {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-tag.bp4-intent-danger .bp4-tag-remove:hover, .bp4-tag.bp4-intent-danger .bp4-tag-remove:active {
  color: #fff;
}
.bp4-tag.bp4-fill {
  display: flex;
  width: 100%;
}
.bp4-tag.bp4-minimal > .bp4-icon, .bp4-tag.bp4-minimal .bp4-icon-standard, .bp4-tag.bp4-minimal .bp4-icon-large {
  fill: #5f6b7c;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]) {
  background-color: rgba(143, 153, 168, 0.15);
  color: #1c2127;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive {
  cursor: pointer;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive:hover {
  background-color: rgba(143, 153, 168, 0.3);
  color: #111418;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive.bp4-active, .bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive:active {
  background-color: rgba(143, 153, 168, 0.35);
  color: #111418;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove {
  color: #5f6b7c;
}
.bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove:hover, .bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove:active {
  color: #404854;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]) {
  background-color: rgba(143, 153, 168, 0.15);
  color: #f6f7f9;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive {
  cursor: pointer;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive:hover {
  background-color: rgba(143, 153, 168, 0.3);
  color: #fff;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]).bp4-interactive:active {
  background-color: rgba(143, 153, 168, 0.35);
  color: #fff;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove {
  color: #abb3bf;
}
.bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove:hover, .bp4-dark .bp4-tag.bp4-minimal:not([class*=bp4-intent-]) .bp4-tag-remove:active {
  color: #d3d8de;
}
.bp4-tag.bp4-minimal.bp4-intent-primary {
  background-color: rgba(45, 114, 210, 0.1);
  color: #215db0;
}
.bp4-tag.bp4-minimal.bp4-intent-primary > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-icon-large {
  fill: #215db0;
}
.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  background-color: rgba(45, 114, 210, 0.2);
  color: #184a90;
}
.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  background-color: rgba(45, 114, 210, 0.3);
  color: #184a90;
}
.bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove {
  color: #215db0;
}
.bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove:hover, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove:active {
  color: #184a90;
}
.bp4-tag.bp4-minimal.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.1);
  color: #1c6e42;
}
.bp4-tag.bp4-minimal.bp4-intent-success > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-icon-large {
  fill: #1c6e42;
}
.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  background-color: rgba(35, 133, 81, 0.2);
  color: #165a36;
}
.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  background-color: rgba(35, 133, 81, 0.3);
  color: #165a36;
}
.bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove {
  color: #1c6e42;
}
.bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove:hover, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove:active {
  color: #165a36;
}
.bp4-tag.bp4-minimal.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.1);
  color: #935610;
}
.bp4-tag.bp4-minimal.bp4-intent-warning > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-icon-large {
  fill: #935610;
}
.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  background-color: rgba(200, 118, 25, 0.2);
  color: #77450d;
}
.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  background-color: rgba(200, 118, 25, 0.3);
  color: #77450d;
}
.bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove {
  color: #935610;
}
.bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove:hover, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove:active {
  color: #77450d;
}
.bp4-tag.bp4-minimal.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.1);
  color: #ac2f33;
}
.bp4-tag.bp4-minimal.bp4-intent-danger > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-icon-large {
  fill: #ac2f33;
}
.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  background-color: rgba(205, 66, 70, 0.2);
  color: #8e292c;
}
.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  background-color: rgba(205, 66, 70, 0.3);
  color: #8e292c;
}
.bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove {
  color: #ac2f33;
}
.bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove:hover, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove:active {
  color: #8e292c;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary {
  background-color: rgba(45, 114, 210, 0.2);
  color: #8abbff;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  background-color: rgba(45, 114, 210, 0.3);
  color: #99c4ff;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  background-color: rgba(45, 114, 210, 0.35);
  color: #99c4ff;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove {
  color: #8abbff;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove:active {
  color: #99c4ff;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success {
  background-color: rgba(35, 133, 81, 0.2);
  color: #72ca9b;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  background-color: rgba(35, 133, 81, 0.3);
  color: #7cd7a2;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  background-color: rgba(35, 133, 81, 0.35);
  color: #7cd7a2;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove {
  color: #72ca9b;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove:active {
  color: #7cd7a2;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning {
  background-color: rgba(200, 118, 25, 0.2);
  color: #fbb360;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  background-color: rgba(200, 118, 25, 0.3);
  color: #f5c186;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  background-color: rgba(200, 118, 25, 0.35);
  color: #f5c186;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove {
  color: #fbb360;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove:active {
  color: #f5c186;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger {
  background-color: rgba(205, 66, 70, 0.2);
  color: #fa999c;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  background-color: rgba(205, 66, 70, 0.3);
  color: #ffa1a4;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  background-color: rgba(205, 66, 70, 0.35);
  color: #ffa1a4;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove {
  color: #fa999c;
}
.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove:active {
  color: #ffa1a4;
}

.bp4-tag-remove {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  display: flex;
  margin-bottom: -2px;
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: -6px !important;
  margin-top: -2px;
  padding: 2px;
  padding-left: 0;
}
.bp4-tag-remove:hover {
  background: none;
  color: inherit;
  text-decoration: none;
}
.bp4-tag-remove .bp4-icon:first-child {
  color: inherit;
}
.bp4-tag-remove:hover, .bp4-tag-remove:active {
  color: #fff;
}
.bp4-tag-remove:empty::before {
  font-family: "blueprint-icons-16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "\f2ba";
}
.bp4-large .bp4-tag-remove {
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: -10px !important;
  padding: 0 5px 0 0;
}
.bp4-large .bp4-tag-remove:empty::before {
  font-family: "blueprint-icons-20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
}
.bp4-dark .bp4-tag-remove {
  color: rgba(28, 33, 39, 0.7);
}
.bp4-dark .bp4-tag-remove:hover, .bp4-dark .bp4-tag-remove:active {
  color: #1c2127;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-tag-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: text;
  height: auto;
  line-height: inherit;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 0;
}
.bp4-tag-input > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-tag-input > .bp4-tag-input-values {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-tag-input .bp4-tag-input-icon {
  color: #5f6b7c;
  margin-left: 2px;
  margin-right: 7px;
  margin-top: 7px;
}
.bp4-tag-input .bp4-tag-input-values {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  margin-right: 7px;
  margin-top: 5px;
  min-width: 0;
}
.bp4-tag-input .bp4-tag-input-values > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.bp4-tag-input .bp4-tag-input-values > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}
.bp4-tag-input .bp4-tag-input-values::before,
.bp4-tag-input .bp4-tag-input-values > * {
  margin-right: 5px;
}
.bp4-tag-input .bp4-tag-input-values:empty::before,
.bp4-tag-input .bp4-tag-input-values > :last-child {
  margin-right: 0;
}
.bp4-tag-input .bp4-tag-input-values:first-child .bp4-input-ghost:first-child {
  padding-left: 5px;
}
.bp4-tag-input .bp4-tag-input-values > * {
  margin-bottom: 5px;
}
.bp4-tag-input .bp4-tag {
  overflow-wrap: break-word;
}
.bp4-tag-input .bp4-tag.bp4-active {
  outline: rgba(45, 114, 210, 0.6) solid 2px;
  outline-offset: 0;
  -moz-outline-radius: 6px;
}
.bp4-tag-input .bp4-input-ghost {
  flex: 1 1 auto;
  line-height: 20px;
  width: 80px;
}
.bp4-tag-input .bp4-input-ghost:disabled, .bp4-tag-input .bp4-input-ghost.bp4-disabled {
  cursor: not-allowed;
}
.bp4-tag-input .bp4-button,
.bp4-tag-input .bp4-spinner {
  margin: 3px;
  margin-left: 0;
}
.bp4-tag-input .bp4-button {
  min-height: 24px;
  min-width: 24px;
  padding: 0 7px;
}
.bp4-tag-input.bp4-large {
  height: auto;
  min-height: 40px;
}
.bp4-tag-input.bp4-large::before,
.bp4-tag-input.bp4-large > * {
  margin-right: 10px;
}
.bp4-tag-input.bp4-large:empty::before,
.bp4-tag-input.bp4-large > :last-child {
  margin-right: 0;
}
.bp4-tag-input.bp4-large .bp4-tag-input-icon {
  margin-left: 5px;
  margin-top: 10px;
}
.bp4-tag-input.bp4-large .bp4-input-ghost {
  line-height: 30px;
}
.bp4-tag-input.bp4-large .bp4-button {
  min-height: 30px;
  min-width: 30px;
  padding: 5px 10px;
  margin: 5px;
  margin-left: 0;
}
.bp4-tag-input.bp4-large .bp4-spinner {
  margin: 8px;
  margin-left: 0;
}
.bp4-tag-input.bp4-active {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-tag-input.bp4-active.bp4-intent-primary {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-tag-input.bp4-active.bp4-intent-success {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-tag-input.bp4-active.bp4-intent-warning {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-tag-input.bp4-active.bp4-intent-danger {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-tag-input .bp4-tag-input-icon, .bp4-tag-input.bp4-dark .bp4-tag-input-icon {
  color: #abb3bf;
}
.bp4-dark .bp4-tag-input .bp4-input-ghost, .bp4-tag-input.bp4-dark .bp4-input-ghost {
  color: #f6f7f9;
}
.bp4-dark .bp4-tag-input .bp4-input-ghost::placeholder, .bp4-tag-input.bp4-dark .bp4-input-ghost::placeholder {
  color: #abb3bf;
}
.bp4-dark .bp4-tag-input.bp4-active, .bp4-tag-input.bp4-dark.bp4-active {
  background-color: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-primary, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-primary {
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-success, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-success {
  box-shadow: inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-warning, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-warning {
  box-shadow: inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-danger, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-danger {
  box-shadow: inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}

.bp4-input-ghost {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}
.bp4-input-ghost::placeholder {
  color: #5f6b7c;
  opacity: 1;
}
.bp4-input-ghost:focus {
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-toast {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  display: flex;
  margin: 20px 0 0;
  max-width: 500px;
  min-width: 300px;
  pointer-events: all;
  /* stylelint-disable-next-line declaration-no-important */
  position: relative !important;
}
.bp4-toast.bp4-toast-enter, .bp4-toast.bp4-toast-appear {
  transform: translateY(-40px);
}
.bp4-toast.bp4-toast-enter-active, .bp4-toast.bp4-toast-appear-active {
  transform: translateY(0);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-toast.bp4-toast-enter ~ .bp4-toast, .bp4-toast.bp4-toast-appear ~ .bp4-toast {
  transform: translateY(-40px);
}
.bp4-toast.bp4-toast-enter-active ~ .bp4-toast, .bp4-toast.bp4-toast-appear-active ~ .bp4-toast {
  transform: translateY(0);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-toast.bp4-toast-exit {
  opacity: 1;
  filter: blur(0);
}
.bp4-toast.bp4-toast-exit-active {
  opacity: 0;
  filter: blur(10px);
  transition-delay: 0;
  transition-duration: 300ms;
  transition-property: opacity, filter;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-toast.bp4-toast-exit ~ .bp4-toast {
  transform: translateY(0);
}
.bp4-toast.bp4-toast-exit-active ~ .bp4-toast {
  transform: translateY(-40px);
  transition-delay: 50ms;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-toast .bp4-button-group {
  flex: 0 0 auto;
  padding: 5px;
  padding-left: 0;
}
.bp4-toast > .bp4-icon {
  color: #5f6b7c;
  margin: 12px;
  margin-right: 0;
}
.bp4-toast.bp4-dark, .bp4-dark .bp4-toast {
  background-color: #404854;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-toast.bp4-dark > .bp4-icon, .bp4-dark .bp4-toast > .bp4-icon {
  color: #abb3bf;
}
.bp4-toast.bp4-dark .bp4-button .bp4-icon, .bp4-dark .bp4-toast .bp4-button .bp4-icon {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-toast[class*=bp4-intent-] a {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-toast[class*=bp4-intent-] a:hover {
  color: #fff;
}
.bp4-toast[class*=bp4-intent-] > .bp4-icon {
  color: #fff;
}
.bp4-toast.bp4-intent-primary {
  background-color: #2d72d2;
  color: #fff;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-toast.bp4-intent-primary .bp4-icon:first-child {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-toast.bp4-intent-primary .bp4-button {
  background-color: #2d72d2 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-primary .bp4-button:hover {
  background-color: #215db0 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-primary .bp4-button:active {
  background-color: #184a90 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-primary .bp4-button:focus {
  outline-color: rgba(255, 255, 255, 0.5);
}
.bp4-toast.bp4-intent-primary .bp4-button:last-child > .bp4-icon-cross {
  color: rgba(255, 255, 255, 0.7) !important;
}
.bp4-toast.bp4-intent-success {
  background-color: #238551;
  color: #fff;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-toast.bp4-intent-success .bp4-icon:first-child {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-toast.bp4-intent-success .bp4-button {
  background-color: #238551 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-success .bp4-button:hover {
  background-color: #1c6e42 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-success .bp4-button:active {
  background-color: #165a36 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-success .bp4-button:focus {
  outline-color: rgba(255, 255, 255, 0.5);
}
.bp4-toast.bp4-intent-success .bp4-button:last-child > .bp4-icon-cross {
  color: rgba(255, 255, 255, 0.7) !important;
}
.bp4-toast.bp4-intent-warning {
  background-color: #fbb360;
  color: #1c2127;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-toast.bp4-intent-warning .bp4-icon:first-child {
  color: rgba(28, 33, 39, 0.7);
}
.bp4-toast.bp4-intent-warning .bp4-button {
  background-color: #fbb360 !important;
  color: #1c2127 !important;
}
.bp4-toast.bp4-intent-warning .bp4-button:hover {
  background-color: #ec9a3c !important;
  color: #1c2127 !important;
}
.bp4-toast.bp4-intent-warning .bp4-button:active {
  background-color: #c87619 !important;
  color: #1c2127 !important;
}
.bp4-toast.bp4-intent-warning .bp4-button:focus {
  outline-color: rgba(255, 255, 255, 0.5);
}
.bp4-toast.bp4-intent-warning .bp4-button:last-child > .bp4-icon-cross {
  color: rgba(28, 33, 39, 0.7) !important;
}
.bp4-toast.bp4-intent-danger {
  background-color: #cd4246;
  color: #fff;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */
}
.bp4-toast.bp4-intent-danger .bp4-icon:first-child {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-toast.bp4-intent-danger .bp4-button {
  background-color: #cd4246 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-danger .bp4-button:hover {
  background-color: #ac2f33 !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-danger .bp4-button:active {
  background-color: #8e292c !important;
  color: #fff !important;
}
.bp4-toast.bp4-intent-danger .bp4-button:focus {
  outline-color: rgba(255, 255, 255, 0.5);
}
.bp4-toast.bp4-intent-danger .bp4-button:last-child > .bp4-icon-cross {
  color: rgba(255, 255, 255, 0.7) !important;
}

.bp4-toast-message {
  flex: 1 1 auto;
  padding: 11px;
  word-break: break-word;
}

.bp4-toast-container {
  align-items: center;
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 0 20px 20px;
  pointer-events: none;
  right: 0;
  z-index: 40;
}
.bp4-toast-container.bp4-toast-container-in-portal {
  position: fixed;
}
.bp4-toast-container.bp4-toast-container-inline {
  position: absolute;
}
.bp4-toast-container.bp4-toast-container-top {
  top: 0;
}
.bp4-toast-container.bp4-toast-container-bottom {
  bottom: 0;
  flex-direction: column-reverse;
  top: auto;
}
.bp4-toast-container.bp4-toast-container-left {
  align-items: flex-start;
}
.bp4-toast-container.bp4-toast-container-right {
  align-items: flex-end;
}

.bp4-toast-container-bottom .bp4-toast.bp4-toast-enter:not(.bp4-toast-enter-active), .bp4-toast-container-bottom .bp4-toast.bp4-toast-enter:not(.bp4-toast-enter-active) ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-appear:not(.bp4-toast-appear-active), .bp4-toast-container-bottom .bp4-toast.bp4-toast-appear:not(.bp4-toast-appear-active) ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-exit-active ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-leave-active ~ .bp4-toast {
  transform: translateY(60px);
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp4-tooltip {
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  transform: scale(1);
  color: #f6f7f9;
}
.bp4-tooltip .bp4-popover-arrow {
  height: 22px;
  position: absolute;
  width: 22px;
}
.bp4-tooltip .bp4-popover-arrow::before {
  height: 14px;
  margin: 4px;
  width: 14px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip {
  margin-bottom: 11px;
  margin-top: -11px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow {
  bottom: -8px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(-90deg);
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip {
  margin-left: 11px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow {
  left: -8px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(0);
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip {
  margin-top: 11px;
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow {
  top: -8px;
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(90deg);
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip {
  margin-left: -11px;
  margin-right: 11px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow {
  right: -8px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(180deg);
}
.bp4-tether-element-attached-middle > .bp4-tooltip > .bp4-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.bp4-tether-element-attached-center > .bp4-tooltip > .bp4-popover-arrow {
  right: 50%;
  transform: translateX(50%);
}
.bp4-tether-element-attached-top.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow {
  top: -0.2218253939px;
}
.bp4-tether-element-attached-right.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow {
  right: -0.2218253939px;
}
.bp4-tether-element-attached-left.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow {
  left: -0.2218253939px;
}
.bp4-tether-element-attached-bottom.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow {
  bottom: -0.2218253939px;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: top left;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: top center;
}
.bp4-tether-element-attached-top.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: top right;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: center left;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: center center;
}
.bp4-tether-element-attached-middle.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: center right;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: bottom left;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: bottom center;
}
.bp4-tether-element-attached-bottom.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: bottom right;
}
.bp4-tooltip .bp4-popover-content {
  background: #404854;
}
.bp4-tooltip .bp4-popover-content,
.bp4-tooltip .bp4-heading {
  color: #f6f7f9;
}
.bp4-tooltip .bp4-popover-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-tooltip .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: 0.1;
}
.bp4-tooltip .bp4-popover-arrow-fill {
  fill: #404854;
}
.bp4-popover-enter > .bp4-tooltip, .bp4-popover-appear > .bp4-tooltip {
  transform: scale(0.8);
}
.bp4-popover-enter-active > .bp4-tooltip, .bp4-popover-appear-active > .bp4-tooltip {
  transform: scale(1);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover-exit > .bp4-tooltip {
  transform: scale(1);
}
.bp4-popover-exit-active > .bp4-tooltip {
  transform: scale(0.8);
  transition-delay: 0;
  transition-duration: 100ms;
  transition-property: transform;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-tooltip .bp4-text-muted {
  color: #abb3bf;
}
.bp4-tooltip .bp4-text-disabled {
  color: rgba(171, 179, 191, 0.6);
}
.bp4-tooltip .bp4-running-text hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.bp4-tooltip a {
  color: #8abbff;
}
.bp4-tooltip a:hover {
  color: #8abbff;
}
.bp4-tooltip a .bp4-icon, .bp4-tooltip a .bp4-icon-standard, .bp4-tooltip a .bp4-icon-large {
  color: inherit;
}
.bp4-tooltip a code {
  color: inherit;
}
.bp4-tooltip .bp4-code,
.bp4-tooltip .bp4-running-text code {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
a > .bp4-tooltip .bp4-code,
a > .bp4-tooltip .bp4-running-text code {
  color: inherit;
}
.bp4-tooltip .bp4-code-block,
.bp4-tooltip .bp4-running-text pre {
  background: rgba(17, 20, 24, 0.3);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color: #f6f7f9;
}
.bp4-tooltip .bp4-code-block > code,
.bp4-tooltip .bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}
.bp4-tooltip .bp4-key,
.bp4-tooltip .bp4-running-text kbd {
  background: #383e47;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 1px 1px 0 rgba(17, 20, 24, 0.4);
  color: #abb3bf;
}
.bp4-tooltip .bp4-icon.bp4-intent-primary, .bp4-tooltip .bp4-icon-standard.bp4-intent-primary, .bp4-tooltip .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}
.bp4-tooltip .bp4-icon.bp4-intent-success, .bp4-tooltip .bp4-icon-standard.bp4-intent-success, .bp4-tooltip .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}
.bp4-tooltip .bp4-icon.bp4-intent-warning, .bp4-tooltip .bp4-icon-standard.bp4-intent-warning, .bp4-tooltip .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}
.bp4-tooltip .bp4-icon.bp4-intent-danger, .bp4-tooltip .bp4-icon-standard.bp4-intent-danger, .bp4-tooltip .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}
.bp4-tooltip .bp4-popover-content {
  padding: 10px 12px;
}
.bp4-tooltip.bp4-dark, .bp4-dark .bp4-tooltip {
  box-shadow: 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip.bp4-dark .bp4-popover-content, .bp4-dark .bp4-tooltip .bp4-popover-content {
  background: #e5e8eb;
}
.bp4-tooltip.bp4-dark .bp4-popover-content,
.bp4-tooltip.bp4-dark .bp4-heading, .bp4-dark .bp4-tooltip .bp4-popover-content,
.bp4-dark .bp4-tooltip .bp4-heading {
  color: #404854;
}
.bp4-tooltip.bp4-dark .bp4-popover-arrow::before, .bp4-dark .bp4-tooltip .bp4-popover-arrow::before {
  box-shadow: 1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip.bp4-dark .bp4-popover-arrow-border, .bp4-dark .bp4-tooltip .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: 0.2;
}
.bp4-tooltip.bp4-dark .bp4-popover-arrow-fill, .bp4-dark .bp4-tooltip .bp4-popover-arrow-fill {
  fill: #e5e8eb;
}
.bp4-tooltip.bp4-dark .bp4-text-muted, .bp4-dark .bp4-tooltip .bp4-text-muted {
  color: #5f6b7c;
}
.bp4-tooltip.bp4-dark .bp4-text-disabled, .bp4-dark .bp4-tooltip .bp4-text-disabled {
  color: rgba(95, 107, 124, 0.6);
}
.bp4-tooltip.bp4-dark .bp4-running-text hr, .bp4-dark .bp4-tooltip .bp4-running-text hr {
  border-color: rgba(17, 20, 24, 0.15);
}
.bp4-tooltip.bp4-dark a, .bp4-dark .bp4-tooltip a {
  color: #215db0;
}
.bp4-tooltip.bp4-dark a:hover, .bp4-dark .bp4-tooltip a:hover {
  color: #215db0;
}
.bp4-tooltip.bp4-dark a .bp4-icon, .bp4-tooltip.bp4-dark a .bp4-icon-standard, .bp4-tooltip.bp4-dark a .bp4-icon-large, .bp4-dark .bp4-tooltip a .bp4-icon, .bp4-dark .bp4-tooltip a .bp4-icon-standard, .bp4-dark .bp4-tooltip a .bp4-icon-large {
  color: inherit;
}
.bp4-tooltip.bp4-dark a code, .bp4-dark .bp4-tooltip a code {
  color: inherit;
}
.bp4-tooltip.bp4-dark .bp4-code,
.bp4-tooltip.bp4-dark .bp4-running-text code, .bp4-dark .bp4-tooltip .bp4-code,
.bp4-dark .bp4-tooltip .bp4-running-text code {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}
a > .bp4-tooltip.bp4-dark .bp4-code,
a > .bp4-tooltip.bp4-dark .bp4-running-text code, a > .bp4-dark .bp4-tooltip .bp4-code,
a > .bp4-dark .bp4-tooltip .bp4-running-text code {
  color: #2d72d2;
}
.bp4-tooltip.bp4-dark .bp4-code-block,
.bp4-tooltip.bp4-dark .bp4-running-text pre, .bp4-dark .bp4-tooltip .bp4-code-block,
.bp4-dark .bp4-tooltip .bp4-running-text pre {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.15);
  color: #1c2127;
}
.bp4-tooltip.bp4-dark .bp4-code-block > code,
.bp4-tooltip.bp4-dark .bp4-running-text pre > code, .bp4-dark .bp4-tooltip .bp4-code-block > code,
.bp4-dark .bp4-tooltip .bp4-running-text pre > code {
  background: none;
  box-shadow: none;
  color: inherit;
}
.bp4-tooltip.bp4-dark .bp4-key,
.bp4-tooltip.bp4-dark .bp4-running-text kbd, .bp4-dark .bp4-tooltip .bp4-key,
.bp4-dark .bp4-tooltip .bp4-running-text kbd {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  color: #5f6b7c;
}
.bp4-tooltip.bp4-dark .bp4-icon.bp4-intent-primary, .bp4-tooltip.bp4-dark .bp4-icon-standard.bp4-intent-primary, .bp4-tooltip.bp4-dark .bp4-icon-large.bp4-intent-primary, .bp4-dark .bp4-tooltip .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-tooltip .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-tooltip .bp4-icon-large.bp4-intent-primary {
  color: #215db0;
}
.bp4-tooltip.bp4-dark .bp4-icon.bp4-intent-success, .bp4-tooltip.bp4-dark .bp4-icon-standard.bp4-intent-success, .bp4-tooltip.bp4-dark .bp4-icon-large.bp4-intent-success, .bp4-dark .bp4-tooltip .bp4-icon.bp4-intent-success, .bp4-dark .bp4-tooltip .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-tooltip .bp4-icon-large.bp4-intent-success {
  color: #1c6e42;
}
.bp4-tooltip.bp4-dark .bp4-icon.bp4-intent-warning, .bp4-tooltip.bp4-dark .bp4-icon-standard.bp4-intent-warning, .bp4-tooltip.bp4-dark .bp4-icon-large.bp4-intent-warning, .bp4-dark .bp4-tooltip .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-tooltip .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-tooltip .bp4-icon-large.bp4-intent-warning {
  color: #935610;
}
.bp4-tooltip.bp4-dark .bp4-icon.bp4-intent-danger, .bp4-tooltip.bp4-dark .bp4-icon-standard.bp4-intent-danger, .bp4-tooltip.bp4-dark .bp4-icon-large.bp4-intent-danger, .bp4-dark .bp4-tooltip .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-tooltip .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-tooltip .bp4-icon-large.bp4-intent-danger {
  color: #ac2f33;
}
.bp4-tooltip.bp4-intent-primary .bp4-popover-content {
  background: #2d72d2;
  color: #fff;
}
.bp4-tooltip.bp4-intent-primary .bp4-popover-arrow-fill {
  fill: #2d72d2;
}
.bp4-tooltip.bp4-intent-success .bp4-popover-content {
  background: #238551;
  color: #fff;
}
.bp4-tooltip.bp4-intent-success .bp4-popover-arrow-fill {
  fill: #238551;
}
.bp4-tooltip.bp4-intent-warning .bp4-popover-content {
  background: #c87619;
  color: #fff;
}
.bp4-tooltip.bp4-intent-warning .bp4-popover-arrow-fill {
  fill: #c87619;
}
.bp4-tooltip.bp4-intent-danger .bp4-popover-content {
  background: #cd4246;
  color: #fff;
}
.bp4-tooltip.bp4-intent-danger .bp4-popover-arrow-fill {
  fill: #cd4246;
}

.bp4-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help;
}

/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
/*
Trees

Markup:
<div class="bp4-tree bp4-elevation-0">
  <ul class="bp4-tree-node-list bp4-tree-root">
    <li class="bp4-tree-node bp4-tree-node-expanded">
      <div class="bp4-tree-node-content">
        <span class="bp4-tree-node-caret bp4-tree-node-caret-open bp4-icon-standard"></span>
        <span class="bp4-tree-node-icon bp4-icon-standard bp4-icon-folder-close"></span>
        <span class="bp4-tree-node-label">Label</span>
        <span class="bp4-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="bp4-tree-node-list">
        <li class="bp4-tree-node">
          <div class="bp4-tree-node-content">
            <span class="bp4-tree-node-caret-none bp4-icon-standard"></span>
            <span class="bp4-tree-node-icon bp4-icon-standard bp4-icon-document"></span>
          <span class="bp4-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="bp4-tree-node">
          <div class="bp4-tree-node-content">
            <span class="bp4-tree-node-caret-none bp4-icon-standard"></span>
            <span class="bp4-tree-node-icon bp4-icon-standard bp4-icon-document"></span>
            <span class="bp4-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide tree
*/
.bp4-tree .bp4-icon, .bp4-tree .bp4-icon-standard, .bp4-tree .bp4-icon-large {
  color: #5f6b7c;
}
.bp4-tree .bp4-icon.bp4-intent-primary, .bp4-tree .bp4-icon-standard.bp4-intent-primary, .bp4-tree .bp4-icon-large.bp4-intent-primary {
  color: #2d72d2;
}
.bp4-tree .bp4-icon.bp4-intent-success, .bp4-tree .bp4-icon-standard.bp4-intent-success, .bp4-tree .bp4-icon-large.bp4-intent-success {
  color: #238551;
}
.bp4-tree .bp4-icon.bp4-intent-warning, .bp4-tree .bp4-icon-standard.bp4-intent-warning, .bp4-tree .bp4-icon-large.bp4-intent-warning {
  color: #c87619;
}
.bp4-tree .bp4-icon.bp4-intent-danger, .bp4-tree .bp4-icon-standard.bp4-intent-danger, .bp4-tree .bp4-icon-large.bp4-intent-danger {
  color: #cd4246;
}

.bp4-tree-node-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.bp4-tree-root {
  background-color: transparent;
  cursor: default;
  padding-left: 0;
  position: relative;
}

.bp4-tree-node-content-0 {
  padding-left: 0px;
}

.bp4-tree-node-content-1 {
  padding-left: 23px;
}

.bp4-tree-node-content-2 {
  padding-left: 46px;
}

.bp4-tree-node-content-3 {
  padding-left: 69px;
}

.bp4-tree-node-content-4 {
  padding-left: 92px;
}

.bp4-tree-node-content-5 {
  padding-left: 115px;
}

.bp4-tree-node-content-6 {
  padding-left: 138px;
}

.bp4-tree-node-content-7 {
  padding-left: 161px;
}

.bp4-tree-node-content-8 {
  padding-left: 184px;
}

.bp4-tree-node-content-9 {
  padding-left: 207px;
}

.bp4-tree-node-content-10 {
  padding-left: 230px;
}

.bp4-tree-node-content-11 {
  padding-left: 253px;
}

.bp4-tree-node-content-12 {
  padding-left: 276px;
}

.bp4-tree-node-content-13 {
  padding-left: 299px;
}

.bp4-tree-node-content-14 {
  padding-left: 322px;
}

.bp4-tree-node-content-15 {
  padding-left: 345px;
}

.bp4-tree-node-content-16 {
  padding-left: 368px;
}

.bp4-tree-node-content-17 {
  padding-left: 391px;
}

.bp4-tree-node-content-18 {
  padding-left: 414px;
}

.bp4-tree-node-content-19 {
  padding-left: 437px;
}

.bp4-tree-node-content-20 {
  padding-left: 460px;
}

.bp4-tree-node-content {
  align-items: center;
  background: none;
  display: flex;
  height: 30px;
  padding-right: 5px;
  width: 100%;
}
.bp4-tree-node-content:hover {
  background-color: rgba(143, 153, 168, 0.15);
}
.bp4-tree-node-content:active {
  background-color: rgba(143, 153, 168, 0.3);
}

.bp4-tree-node-caret,
.bp4-tree-node-caret-none {
  min-width: 30px;
}

.bp4-tree-node-caret {
  color: #5f6b7c;
  cursor: pointer;
  padding: 7px;
  transform: rotate(0deg);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-tree-node-caret:hover {
  color: #1c2127;
}
.bp4-dark .bp4-tree-node-caret {
  color: #abb3bf;
}
.bp4-dark .bp4-tree-node-caret:hover {
  color: #f6f7f9;
}
.bp4-tree-node-caret.bp4-tree-node-caret-open {
  transform: rotate(90deg);
}
.bp4-tree-node-caret.bp4-icon-standard::before {
  content: "\f14d";
}

.bp4-tree-node-icon {
  margin-right: 7px;
  position: relative;
}

.bp4-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  user-select: none;
}
.bp4-tree-node-label span {
  display: inline;
}

.bp4-tree-node-secondary-label {
  padding: 0 5px;
  user-select: none;
}
.bp4-tree-node-secondary-label .bp4-popover-wrapper,
.bp4-tree-node-secondary-label .bp4-popover-target {
  align-items: center;
  display: flex;
}

.bp4-tree-node.bp4-disabled .bp4-tree-node-content {
  background-color: inherit;
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}
.bp4-tree-node.bp4-disabled .bp4-tree-node-caret,
.bp4-tree-node.bp4-disabled .bp4-tree-node-icon {
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content {
  background-color: #2d72d2;
}
.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content,
.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-standard, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-large {
  color: #fff;
}
.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-tree-node-caret::before {
  color: rgba(255, 255, 255, 0.7);
}
.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-tree-node-caret:hover::before {
  color: #fff;
}

.bp4-dark .bp4-tree-node-content:hover {
  background-color: rgba(95, 107, 124, 0.3);
}
.bp4-dark .bp4-tree .bp4-icon, .bp4-dark .bp4-tree .bp4-icon-standard, .bp4-dark .bp4-tree .bp4-icon-large {
  color: #abb3bf;
}
.bp4-dark .bp4-tree .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}
.bp4-dark .bp4-tree .bp4-icon.bp4-intent-success, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}
.bp4-dark .bp4-tree .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}
.bp4-dark .bp4-tree .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}
.bp4-dark .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content {
  background-color: #2d72d2;
}
.bp4-dark .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon, .bp4-dark .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-standard, .bp4-dark .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-large {
  color: #fff;
}

/*# sourceMappingURL=blueprint.css.map */