// Copyright 2021 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";
@import "~@blueprintjs/core/src/components/button/common";

.#{$ns}-button-group {
  // support wrapping buttons in a tooltip, which adds a wrapper element
  &:not(.#{$ns}-minimal) {
    > .#{$ns}-popover2-target:not(:first-child) .#{$ns}-button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    > .#{$ns}-popover2-target:not(:last-child) .#{$ns}-button {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-right: -$button-border-width;
    }
  }

  .#{$ns}-popover2-target {
    display: flex;
    flex: 1 1 auto;
  }

  &.#{$ns}-vertical {
    &:not(.#{$ns}-minimal) {
      > .#{$ns}-popover2-target:first-child .#{$ns}-button {
        border-radius: $pt-border-radius $pt-border-radius 0 0;
      }

      > .#{$ns}-popover2-target:last-child .#{$ns}-button {
        border-radius: 0 0 $pt-border-radius $pt-border-radius;
      }

      > .#{$ns}-popover2-target:not(:last-child) .#{$ns}-button {
        margin-bottom: -$button-border-width;
      }
    }
  }
}
